import { createSlice } from "@reduxjs/toolkit";

export const dbSlice = createSlice({
    name: "dbItem",
    initialState: {
        check: false,
        ListEquip: [],
        ListItem: [],
        ListWeapon: [],
        ListItemSeed: [],
        ListItemFarm: [],
        ListItemTool: [],
        ListItemPiece: [],
        ListItemFood: [],
        ListPet: [],
        ListMonster: [],
        ListDameSkin: [],
        ListChatFrame: [],
        ListTrial: [],
    },
    reducers: {
        update: (state, action) => {
            state.check = true;
            state.ListEquip = action?.payload?.ItemEquipment;
            state.ListItem = action?.payload?.Item;
            state.ListWeapon = action?.payload?.Weapon;
            state.ListItemSeed = action?.payload?.ItemSeed;
            state.ListItemFarm = action?.payload?.ItemFarm;
            state.ListItemTool = action?.payload?.ItemTool;
            state.ListItemPiece = action?.payload?.Piece;
            state.ListItemFood = action?.payload?.Food;
            state.ListPet = action?.payload?.Pet;
            state.ListMonster = action?.payload?.Monster;
            state.ListDameSkin = action?.payload?.DameSkin;
            state.ListChatFrame = action?.payload?.ChatFrame;
            state.ListTrial = action?.payload?.Trial;
        }
    }
});

export const { update } = dbSlice.actions;

export default dbSlice.reducer;