import { useState } from "react";
import "./Stats.scss";
import { useEffect } from "react";
import { ToastNoSuccess } from "../../../utils/Utils";
import { getRR, getRRUdid } from "../../../services/apiCmsService";
import { formatDate, getDateBefore } from "../../../utils/DateUtil";
import { Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";


const RR = (props) => {
    const [rr, setRr] = useState([]);
    const [df, setDf] = useState();
    const [dt, setDt] = useState();
    const [udid, setUdid] = useState([]);
    const [uDf, setUDf] = useState();
    const [uDt, setUDt] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        let date = formatDate(getDateBefore(7));
        setDf(date);
        let curD = formatDate(new Date());
        setDt(curD);
        getListRR(date, curD);
        // nru udid
        setUDf(date);
        setUDt(curD);
        getListRRUdid(date, curD);
    }, [])

    const getListRR = async (df, dt) => {
        let data = await getRR(df, dt);
        if (data && data.EC === 0) {
            setRr(data.DT);
        } else setRr([]);
        ToastNoSuccess(data);
    }

    const getListRRUdid = async (df, dt) => {
        let data = await getRRUdid(df, dt);
        if (data && data.EC === 0) {
            setUdid(data.DT);
        } else setRr([]);
        ToastNoSuccess(data);
    }

    return (<div className="rr-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Retention Rate</div>
        <div className="body-panel">
            <div className="cell-panel">
                <div className="input-content">
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={df}
                        onChange={(event) => setDf(event.target.value)}
                    />
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={dt}
                        onChange={(event) => setDt(event.target.value)}
                    />
                    <button className="btn btn-success" onClick={() => getListRR(df, dt)}>Check</button>
                </div>
                <div className="title-panel">Retention rate theo server</div>
                <Table striped bordered hover border={"1px"}>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>Server</th>
                            <th style={{ width: '15%' }}>Date</th>
                            <th style={{ width: '8%' }}>NRU</th>
                            <th style={{ width: '8%' }}>R1</th>
                            <th style={{ width: '8%' }}>R2</th>
                            <th style={{ width: '8%' }}>R3</th>
                            <th style={{ width: '8%' }}>R4</th>
                            <th style={{ width: '8%' }}>R5</th>
                            <th style={{ width: '8%' }}>R6</th>
                            <th style={{ width: '8%' }}>R7</th>
                            <th style={{ width: '8%' }}>R14</th>
                            <th style={{ width: '8%' }}>R30</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rr && rr.length > 0 &&
                            rr.map((r, index) => {
                                return (
                                    <tr key={`rr-${index}`}>
                                        <td>{+r.server_id < 0 ? "Test " + Math.abs(r.server_id) : r.server_id}</td>
                                        <td>{new Date(r.date_created).toLocaleDateString()}</td>
                                        <td>{r.nru}</td>
                                        <td>{r.rr_1}</td>
                                        <td>{r.rr_2}</td>
                                        <td>{r.rr_3}</td>
                                        <td>{r.rr_4}</td>
                                        <td>{r.rr_5}</td>
                                        <td>{r.rr_6}</td>
                                        <td>{r.rr_7}</td>
                                        <td>{r.rr_14}</td>
                                        <td>{r.rr_30}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </Table>
            </div>
            <div className="cell-panel">
                <div className="input-content">
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={uDf}
                        onChange={(event) => setUDf(event.target.value)}
                    />
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={uDt}
                        onChange={(event) => setUDt(event.target.value)}
                    />
                    <button className="btn btn-success" onClick={() => getListRRUdid(df, dt)}>Check</button>
                </div>
                <div className="title-panel">Retention rate UDID</div>
                <Table striped bordered hover border={"1px"}>
                    <thead>
                        <tr>
                            <th style={{ width: '20%' }}>Date</th>
                            <th style={{ width: '8%' }}>NRU</th>
                            <th style={{ width: '8%' }}>R1</th>
                            <th style={{ width: '8%' }}>R2</th>
                            <th style={{ width: '8%' }}>R3</th>
                            <th style={{ width: '8%' }}>R4</th>
                            <th style={{ width: '8%' }}>R5</th>
                            <th style={{ width: '8%' }}>R6</th>
                            <th style={{ width: '8%' }}>R7</th>
                            <th style={{ width: '8%' }}>R14</th>
                            <th style={{ width: '8%' }}>R30</th>
                        </tr>
                    </thead>
                    <tbody>
                        {udid && udid.length > 0 &&
                            udid.map((r, index) => {
                                return (
                                    <tr key={`rr-udid-${index}`}>
                                        <td>{new Date(r.date_created).toLocaleDateString()}</td>
                                        <td>{r.nru}</td>
                                        <td>{r.rr_1}</td>
                                        <td>{r.rr_2}</td>
                                        <td>{r.rr_3}</td>
                                        <td>{r.rr_4}</td>
                                        <td>{r.rr_5}</td>
                                        <td>{r.rr_6}</td>
                                        <td>{r.rr_7}</td>
                                        <td>{r.rr_14}</td>
                                        <td>{r.rr_30}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>

                </Table>
            </div>
        </div>

    </div>)
}

export default RR;