import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Profile from "./ModalProfile";
import "./Show.scss";
import Switch from "react-switch";
import { changeNightMode, logout } from "../../services/apiService";
import { USER } from "../../constant";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { changeMode } from "../../redux/slices/nightModeSlice";


const Setting = () => {
  const dispatch = useDispatch();
  const nightMode = useSelector((state) => state?.night?.mode);
  const [isShowModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem(USER));


  const handleLogOut = async () => {
    if (!user) {
      toast.error("Dữ liệu không hợp lệ");
      return;
    }
    let res = await logout(user.username);
    if (res && +res.EC === 0) {
      localStorage.removeItem(USER)
      navigate("/login");
    }
  };

  const handleChangeMode = async (checked) => {
    let data = await changeNightMode(checked ? 1 : 0);
    if (data && +data.EC === 0) {
      user.mode = data.DT.mode;
      localStorage.setItem(USER, JSON.stringify(user));
      dispatch(changeMode(data.DT.mode));
    }
  }


  return (
    <div className="setting-container">
      <NavDropdown
        title="Settings"
        id="nav-dropdown-dark"
        align={"end"}
        menuVariant={+nightMode === 1 ? "dark" : "light"}
      >
        <NavDropdown.Item onClick={() => handleLogOut()} className="nav-item">
          Log out
        </NavDropdown.Item>
        <NavDropdown.Item >
          <div className="d-flex">
            <div>Night mode</div>
            <div style={{ padding: "0 10%" }}>
              <Switch onChange={handleChangeMode} checked={+nightMode === 1} />
            </div>
          </div>
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={() => setShowModal(true)}
          className="nav-item"
        >
          Profile
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Header>Copyright© 2022 AORD</NavDropdown.Header>
      </NavDropdown>
      <Profile show={isShowModal} setShow={setShowModal} />
    </div>
  );
};
export default Setting;
