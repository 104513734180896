import { useState } from "react";
import { toast } from "react-toastify";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "./SlideMessage.scss";
import { Form } from "react-bootstrap";
import { ToastResponse } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { getSlideMessage } from "../../../../services/apiCmsService";


const SlideMessage = () => {
    const [msg, setMsg] = useState();
    const [server, setServer] = useState(0);
    const nightMode = useSelector((state) => state?.night?.mode);

    const handleBtnSend = async () => {
        if (server < -3) {
            toast.error("Server phải lớn hơn -3");
            return;
        }
        let data = await getSlideMessage(server, msg);
        ToastResponse(data);
    };


    return (<div className="slider-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Send Slide Message</div>
        <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
            <FloatingLabel
                className="input-server"
                controlId="floating" label="Server (0 gửi tất cả SV)">
                <Form.Control type="number" value={server}
                    onChange={(event) => setServer(+event.target.value)}
                >

                </Form.Control>
            </FloatingLabel>
            <Form.Group className="input-msg" >
                <Form.Label className="un-select-aord">Nhập tin nhắn</Form.Label>
                <Form.Control as="textarea"
                    rows={10} value={msg} autoFocus
                    onChange={(event) => setMsg(event.target.value)} />
            </Form.Group>
            <button className="btn btn-success btn-content" onClick={handleBtnSend}>
                Send
            </button>
        </div>
    </div>)
}

export default SlideMessage;