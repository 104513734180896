import ReactPaginate from "react-paginate";
import { Role, USER } from "../../../../constant";
import { GetKeyByValue } from "../../../../utils/Utils";



const TableUserPaninate = (props) => {
  const { listUser, numPage, nightMode } = props;
  const user = JSON.parse(localStorage.getItem(USER));

  const handlePageClick = (event) => {
    props.fetchListUserPaginate(+event.selected + 1);
  };

  const GetRoleName = (roles) => {
    let name = "";
    let aRole = JSON.parse(roles)
    for (let i = 0; i < aRole.length; i++) {
      const id = aRole[i];
      name += GetKeyByValue(Role, +id) + ", ";
    }
    return name.slice(0, name.length - 2);
  }

  const isSuperAdmin = () => {
    let aRole = JSON.parse(user.role);
    for (let i = 0; i < aRole.length; i++) {
      const e = aRole[i];
      if (e === Role.SUPER_ADMIN) return true;
    }
    return false;
  }

  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" className="un-select-aord">ID</th>
            <th scope="col" className="un-select-aord">Tên</th>
            <th scope="col" className="un-select-aord">Email</th>
            <th scope="col" className="un-select-aord">Quyền</th>
            {isSuperAdmin() ? <th scope="col" className="action un-select-aord">Hành Động</th> : <></>}
            <th scope="col" className="un-select-aord">Active</th>
          </tr>
        </thead>
        <tbody>
          {listUser &&
            listUser.length > 0 &&
            listUser.map((item, index) => {
              return (
                <tr key={`table-users-${index}`}>
                  <td className={+nightMode === 1 ? "un-select-aord text-dark-aord" : "un-select-aord text-light-aord"}>{item.id}</td>
                  <td className={+nightMode === 1 ? "un-select-aord text-dark-aord" : "un-select-aord text-light-aord"}>{item.username}</td>
                  <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.email}</td>
                  <td className={+nightMode === 1 ? "un-select-aord text-dark-aord" : "un-select-aord text-light-aord"}>{GetRoleName(item.role)}</td>
                  <td className="action">
                    {isSuperAdmin() ? (
                      <button
                        className="btn btn-warning mx-3"
                        onClick={() => {
                          props.handleClickBtnUpdate(item);
                        }}
                      >
                        Update
                      </button>
                    ) : (
                      <></>
                    )}
                    {isSuperAdmin() ? (
                      <button
                        className="btn btn-danger "
                        onClick={() => {
                          props.handleClickBtnDelete(item);
                        }}
                      >
                        Delete
                      </button>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className={+nightMode === 1 ? "un-select-aord text-dark-aord" : "un-select-aord text-light-aord"}>{item.active === 1 ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          {listUser && listUser.length === 0 && (
            <tr>
              <td colSpan={"4"}>Không có dữ liệu</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="user-pagination" >
        <ReactPaginate
          nextLabel="Sau >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={numPage}
          previousLabel="< Trước"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default TableUserPaninate;
