import { useState } from "react";
import { Form, Modal } from "react-bootstrap";


const ModalCreateMap = (props) => {
    const { show, setShow, funcCreate } = props;
    const [enable, setEnable] = useState(1);
    const [name, setName] = useState("");
    const [map, setMap] = useState("");
    const [camera, setCamera] = useState("[1,10]");

    const handleClose = () => {
        setName("");
        setMap("");
        setCamera("[1,10]");
        setShow(false);
    }

    const handleCreate = () => {
        funcCreate(enable, name, map, camera);
        handleClose();
    }

    return (<div className="d-flex justify-content-center">
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >
                <Modal.Title >Tạo mới MAP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3 d-flex flex-column pt-3">
                        <Form.Group className="d-flex align-items-center">
                            <Form.Label style={{ width: "150px" }}>Trạng thái</Form.Label>
                            <Form.Select className="select-enable"
                                value={enable}
                                onChange={(event) => setEnable(+event.target.value)}
                            >
                                <option value={1}>Mở</option>
                                <option value={0}>Đóng</option>
                            </Form.Select>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-1">
                        <Form.Label style={{ width: "150px" }}>Name</Form.Label>
                        <Form.Control
                            className="input-name"
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Map</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={map}
                            onChange={(event) => setMap(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Camera</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={camera}
                            onChange={(event) => setCamera(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={handleCreate} style={{ width: "80px" }}>
                    Tạo
                </button>
                <button className="btn btn-danger ms-3" onClick={handleClose} style={{ width: "80px" }}>
                    Hủy
                </button>
            </Modal.Footer>
        </Modal >
    </div >)
}

export default ModalCreateMap;