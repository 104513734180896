import "./Show.scss"
import { useNavigate } from "react-router-dom";
import { SidebarHeader } from "react-pro-sidebar";


const Logo = (props) => {
    let { collapsed, link, title } = props;
    const navigate = useNavigate();

    return (
        <SidebarHeader className="logo-container">
            <img
                alt=""
                src="/logo192.png"
                style={{ width: "70px", height: "70px" }}
                onClick={() => navigate(link ? link : "/")}
            />
            {collapsed === false ? <div className="title" onClick={() => navigate(link ? link : "/")}>{title ? title : "AORD"}</div>
                : <></>}
        </SidebarHeader>)
}

export default Logo;
