import { createSlice } from "@reduxjs/toolkit";

export const nightModeSlice = createSlice({
    name: "night",
    initialState: { mode: 0 },
    reducers: {
        changeMode: (state, action) => {
            state.mode = action?.payload;
        }
    }
});

export const { changeMode } = nightModeSlice.actions;
export default nightModeSlice.reducer;