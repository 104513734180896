import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import { FaCloudUploadAlt, FaMedrt } from "react-icons/fa";
import { TfiAndroid } from "react-icons/tfi";
import "../SideBar.scss";
import { VscVersions } from "react-icons/vsc";
import { RiImageAddFill } from "react-icons/ri";
import Footer from "../Header/Footer";


const PackSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;
    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "UPLOAD" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaCloudUploadAlt />}                        >
                            Upload<Link to="/pack/upload" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<TfiAndroid />}                        >
                            Up APK<Link to="/pack/up-apk" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaMedrt />}                        >
                            List Pack<Link to="/pack/list-pack" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<VscVersions />}                        >
                            Version<Link to="/pack/version" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<RiImageAddFill />}                        >
                            Upload Image<Link to="/pack/up-image" />
                        </MenuItem>
                    </Menu>

                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
}

export default PackSideBar;