import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { getUserData } from "../../../../services/userService";
import "./UserData.scss";
import { useSelector } from "react-redux";

const UserData = () => {
    const [data, setData] = useState({});
    let userId = localStorage.getItem('UserIdInfo');
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getInfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getInfo = async () => {
        let data = await getUserData(userId);
        if (data && data.DT && +data.EC === 0) {
            setData(data.DT[0]);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    }

    return (<div className="user-data-container">
        <div className="info-content">
            <div className="item"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>Cấp gacha </b></div>
                <div className="value">  <label > {data.level_gacha_weapon}</label></div>
            </div>
            <div className="item"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>Cấp Combo Weapon </b></div>
                <div className="value">  <label > {data.lv_combo_weapon}</label></div>
            </div>
            <div className="item"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>Cấp ải </b></div>
                <div className="value">  <label > {data.level_training}</label></div>
            </div>
            <div className="item"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>Slot túi </b></div>
                <div className="value">  <label > {data.num_slot}</label></div>
            </div>
            <div className="item"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>Trang bị đang sử dụng </b></div>
                <div className="value">  <label > {data.item_equipment}</label></div>
            </div>
            <div className="item"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"} >Máu hiện tại </b></div>
                <div className="value">  <label > {data.cur_hp}</label></div>
            </div>
            <div className="item-bot"  >
                <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>Mana hiện tại </b></div>
                <div className="value">  <label > {data.cur_mp}</label></div>
            </div>
        </div>
    </div >)
}
export default UserData;