import axios from "../utils/axiosCustomize";

const getTopUser = async (sv, key, num) => {
    return await axios.get(`api/top-user?sv=${sv}&key=${key}&num=${num}`);
}

const getTopClan = async (sv, key, num) => {
    return await axios.get(`api/top-clan?sv=${sv}&key=${key}&num=${num}`);
}

const getTopItem = async (id, sv, num) => {
    return await axios.get(`api/top-item?id=${id}&sv=${sv}&num=${num}`);
}

const getTopItemEquip = async (id, sv, num) => {
    return await axios.get(`api/top-item-equip?id=${id}&sv=${sv}&num=${num}`);
}

const getTopWeapon = async (id, sv, num) => {
    return await axios.get(`api/top-weapon?id=${id}&sv=${sv}&num=${num}`);
}


export {
    getTopUser,
    getTopClan,
    getTopItem,
    getTopItemEquip,
    getTopWeapon,
}