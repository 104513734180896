import axios from "../utils/axiosCustomize";

const postCreateNewUser = async (email, username, password, role) => {
  const form = new FormData();
  form.append("email", email);
  form.append("username", username);
  form.append("password", password);
  form.append("passwordConfirm", password);
  form.append("role", role);
  return await axios.post("auth/register", form);
};

const postLogin = async (email, password) => {
  return await axios.post(`auth/login`, { email, password });
};

const postRegister = async (username, email, password, passwordConfirm) => {
  return await axios.post(`auth/register`, {
    username,
    password,
    passwordConfirm,
    email,
  });
};

const logout = async (username) => {
  return await axios.post(`api/logout`, {
    username,
  });
};



const changeNightMode = async (mode) => {
  return await axios.get(`api/change-mode?mode=${mode}`)
}

export {
  postCreateNewUser,
  postLogin,
  postRegister,
  logout,
  changeNightMode,
};
