import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { deleteUser } from "../../../../services/adminService";

const ModalDeleteUser = (props) => {
  const { show, setShow, dataUser } = props;

  const handleClose = () => {
    setShow(false);
    props.resetUpdateData();
  };

  const handleDelete = async () => {
    let data = await deleteUser(dataUser.id);
    if (data.EC === 0) {
      toast.success(data.EM);
      await props.fetchListUserPaginateData();
    } else {
      toast.error(data.EM);
    }
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Thông báo!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Xóa người dùng</h4>
        <p>
          Bạn có muốn xóa người dùng có email là <b>{dataUser.email}</b> không!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={handleDelete}>
          Đồng ý
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteUser;
