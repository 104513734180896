import { useRef, useState, useReducer } from "react";
import { deleteFolderPack, postPackFile } from "../../../../services/apiCmsService";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { ToastResponse } from "../../../../utils/Utils";
import ModalSave from "../Modal/ModalSave";
import "./Upload.scss";
import _ from "lodash";
import { useSelector } from "react-redux";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Upload = () => {
    const [files, setFiles] = useState([]);
    const inputRef = useRef(null);
    const [os, setOs] = useState("android");
    const [cp, setCp] = useState("test");
    const [isSelected, setSelected] = useState(false);
    const [show, setShow] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);

    const fileSelectHandler = (event) => {
        event.preventDefault();
        setSelected(event.target.files.length > 0);
        let fls = _.cloneDeep(files);;
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            if (!fls.includes(file))
                fls.push(file);
        }
        setFiles(fls);
    }
    const testLink = "http://cdn.aordgame.com:8083/assets_test_0.9.9-1.9.9/" + os + "/data.txt";
    const realLink = "http://cdn.aordgame.com:8083/assets_0.9.9-1.9.9/" + os + "/data.txt";


    const handleUpload = async () => {
        if (!isSelected) {
            toast.error("Cần chọn file trước");
            return
        }
        let data = await postPackFile(String(cp), String(os), files);
        ToastResponse(data);
    }
    const clearInput = () => {
        inputRef.current.value = "";
        setFiles([]);
        setSelected(false);
    }

    const buttonClearPack = async () => {
        let data = await deleteFolderPack(cp, os);
        ToastResponse(data);
    }


    return (<div className="upload-pack-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Upload Pack</div>
        <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
            <div className="item-select">
                <div className="title-os">OS</div>
                <Form.Select
                    className={+nightMode === 1 ? "form-input-dark select-os" : "select-os form-input-light"}
                    defaultValue={os}
                    onChange={(event) => {
                        setOs(event.target.value);
                        clearInput();
                    }}
                >
                    <option value="android">ANDROID</option>
                    <option value="ios">IOS</option>
                    <option value="pc">PC</option>
                    <option value="osx">OSX</option>
                </Form.Select>
            </div>
            <div className="item-select">
                <div className="title-cp">CP</div>
                <Form.Select
                    className={+nightMode === 1 ? " select-cp form-input-dark" : "select-cp form-input-light"}
                    defaultValue={cp}
                    onChange={(event) => {
                        setCp(event.target.value);
                        clearInput();
                    }}
                >
                    <option value="test">TEST</option>
                    <option value="live">LIVE</option>
                </Form.Select>
            </div>
            <div className="log-pack">
                <a href="http://cdn.aordgame.com:8083/log.txt" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">Lịch sử</a>
                <a href={cp === "test" ? testLink : realLink} target="_blank" rel="noopener noreferrer" className="btn btn-outline-success">Data</a>
                <a target="_blank" rel="noopener noreferrer" className="btn btn-outline-danger" onClick={buttonClearPack}>Clear</a>
            </div>
            <div className="pack-content">
                <Form.Control
                    ref={inputRef} type="file"
                    onChange={fileSelectHandler}
                    multiple size="lg" />
                {isSelected &&
                    <div className="list-select container">
                        <Row>
                            <Col md={7} className="title-col">Name</Col>
                            <Col className="title-col">Size</Col>
                        </Row>
                        {files && files.length > 0 && Array.from(files).map((item, index) => {
                            return (
                                <Row key={`item-pack-select-${index}`}>
                                    <Col md={7}>{item.name}</Col>
                                    <Col >{Math.round(item.size / 10) / 100} Kb</Col>
                                </Row>
                            );
                        })}
                    </div>
                }

            </div>
            <div className="group-button">
                {isSelected ? <div><button className="btn btn-danger" onClick={() => setShow(true)}  >Deploy</button> </div> : <div></div>}
                {isSelected ? <div><button className="btn btn-warning" onClick={clearInput}>Clear</button></div> : <div></div>}
            </div>


        </div>
        <ModalSave
            show={show}
            setShow={setShow}
            send={handleUpload}
            title={`PACK ----- [${os.toUpperCase()}]  ---> [${cp.toUpperCase()}]`}
            desc="Bạn có chắc chắn muốn pack không!"
        />
    </div >)
}

export default Upload;