import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { BiSearchAlt } from "react-icons/bi";
import "./FindUser.scss";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { ToastNoSuccess, formatNumberDot } from "../../../../utils/Utils";
import { getDefaultUser, getFindUser } from "../../../../services/apiCmsService";
import { useSelector } from "react-redux";


const FindUser = () => {
  const nightMode = useSelector((state) => state?.night?.mode);
  const [key, setKey] = useState("");
  const [listUser, setListUser] = useState([]);
  const [cache, setCache] = useState([]);

  useEffect(() => {
    defaultUser();
  }, []);

  const defaultUser = async () => {
    let data = await getDefaultUser();
    ToastNoSuccess(data)
    if (data && +data.EC === 0) {
      setListUser(data.DT);
      setCache(data.DT);
    } else setListUser([])
  }

  const handleFindClick = async () => {
    if (key === "") {
      setListUser(cache);
      return
    }
    let data = await getFindUser(key);
    ToastNoSuccess(data);
    if (data && +data.EC === 0) {
      setListUser(data.DT);
    } else setListUser([]);
  }

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      handleFindClick();
    }
  }

  const handleUserLinkClick = (userId) => {
    localStorage.setItem('UserIdInfo', +userId);
    // navigate("/user");
  }

  return (
    <div className="find-user-container" responsive="xl">
      <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Find User</div>
      <div className={+nightMode === 0 ? "input-search" : "input-search input-search-night"}>
        <Form.Control
          className="input-key"
          type="text"
          value={key}
          autoFocus
          onChange={(event) => {
            setKey(event.target.value);
          }}
          onKeyDown={(event) => handleKeyDown(event)}
        />
        <BiSearchAlt className="search-icon" onClick={() => handleFindClick()} />
      </div>
      <div className="table">
        <Table bordered >
          <thead>
            <tr>
              <th>User Id</th>
              <th>Server</th>
              <th>Name</th>
              <th>Gold</th>
              <th>Gem</th>
              <th>Power</th>
              <th>Clan Name</th>
              <th>Rank</th>
              <th>Vip</th>
              <th>Block Login</th>
              <th>Block Chat</th>
              <th>Created</th>
              <th>Number Login</th>
            </tr>
          </thead>
          <tbody>
            {listUser && listUser.length > 0 &&
              listUser.filter((item) => {
                return key.toLowerCase() === "" ? item : item.id.toString().toLowerCase().includes(key) || item.name.toLowerCase().includes(key);
              }).map((item, index) => {
                return (
                  <tr tr key={`table-find-user-${index}`}>
                    <td>
                      <Link to="/user" className="link" onClick={() => handleUserLinkClick(item.id)}> {item.id}</Link>
                    </td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{+item.server > 0 ? "Real " + item.server : "Test " + Math.abs(item.server)}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.name}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{formatNumberDot(item.gold)}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{formatNumberDot(item.gem)}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{formatNumberDot(item.power)}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.clan_name ? item.clan_name : "Không"}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.rank}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.vip}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.block_type === 1 ? "Đang khóa" : "-"}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.lock_chat ? new Date(item.lock_chat).toLocaleDateString() : "-"}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(item.date_created).toLocaleDateString()}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{formatNumberDot(item.login_time)}</td>
                  </tr>
                );
              })}
            {listUser && listUser.length === 0 && (
              <tr>
                <td colSpan={"15"}><div className="title-nodata">Không có dữ liệu</div></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div >
  );
};

export default FindUser;
