import "react-pro-sidebar/dist/css/styles.css";

import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { RiMailVolumeFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { MdMail } from "react-icons/md";
import Logo from "../Header/Logo";
import { FaGuilded, FaUserCircle } from "react-icons/fa";
import "../SideBar.scss"
import Footer from "../Header/Footer";

const UserInfoSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;
    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "USER" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaUserCircle />}                        >
                            Users<Link to="/user-info/users" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaGuilded />} className="item-content">
                            Clans<Link to="/user-info/clans" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<MdMail />}                        >
                            Send Mail
                            <Link to="/user-info/send-mail" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<RiMailVolumeFill />} className="item-content">
                            System Mail
                            <Link to="/user-info/system-mail" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
}

export default UserInfoSideBar;