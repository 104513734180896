import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from 'react-i18next';
import { getLanguage } from "../../utils/getLanguage";
import ReactCountryFlag from "react-country-flag"
import { useSelector } from "react-redux";

const Language = () => {
    const nightMode = useSelector((state) => state?.night?.mode);
    const { i18n } = useTranslation();
    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }
    return (
        <NavDropdown title={getLanguage(i18n.language) === "Tiếng Việt" ?
            <ReactCountryFlag countryCode="VN" svg /> :
            <ReactCountryFlag countryCode="GB" svg />
        }
            id="basic-nav-dropdown"
            align={"end"}
            menuVariant={+nightMode === 1 ? "dark" : "light"}
        >
            <NavDropdown.Item onClick={() => handleChangeLanguage('en')}>English
                <ReactCountryFlag countryCode="GB" svg className="ms-2" />
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleChangeLanguage('vi')}>Tiếng Việt
                <ReactCountryFlag countryCode="VN" svg className="ms-2" />
            </NavDropdown.Item>
        </NavDropdown>
    )
}

export default Language;