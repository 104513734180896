import "react-pro-sidebar/dist/css/styles.css";
import { FaUserCircle, FaClipboardList } from "react-icons/fa";
import '../SideBar.scss'

import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";

import { MdDashboard } from "react-icons/md";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import Footer from "../Header/Footer";

const ToolSideBar = (props) => {
    const { collapsed, toggled, handleToggleSidebar, sidebarBg } = props;
    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggled}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "TOOLS" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<MdDashboard />}
                        >
                            Tính sát thương gây ra
                            <Link to="/tools/cal-dame" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FaUserCircle />}>
                            Tính sát thương nhận vào
                            <Link to="/tools/cal-dame-ex" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FaClipboardList />} suffix={<span className="badge red">New</span>}>
                            Tính exp theo level
                            <Link to="/tools/cal-exp" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
};

export default ToolSideBar;
