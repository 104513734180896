import "./DashBoard.scss";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { useEffect, useState, useReducer } from "react";
import { getDashBoard } from "../../../../services/adminService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const DashBoard = () => {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState([0, 0, 0]);
  const nightMode = useSelector((state) => state?.night?.mode);



  useEffect(() => {
    getDashboardInfo();
  }, []);

  const getDashboardInfo = async () => {
    let res = await getDashBoard();
    if (res && +res.EC !== 0) {
      toast.error(res.EM);
    }
    if (res.EC === 0) {
      setInfo([res.DT.numberUser, res.DT.norUser, res.DT.admin]);
      setData([
        {
          name: "Total User",
          U: res.DT.numberUser,
        },
        {
          name: "Normal User",
          N: res.DT.norUser,
        },
        {
          name: "Admin User",
          A: res.DT.admin,
        },
      ]);
    } else {
    }
  };

  return (
    <div className="dashboard-container">
      <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >User CMS</div>
      <div className={+nightMode === 0 ? "day-body" : "night-body"}>
        <div className="c-left">
          <div className="child">
            <span className="text-1">Tổng tài khoản</span>
            <span className="text-2">{info[0]}</span>
          </div>
          <div className="child">
            <span className="text-1">User</span>
            <span className="text-2">{info[1]}</span>
          </div>
          <div className="child">
            <span className="text-1">Admin</span>
            <span className="text-2">{info[2]}</span>
          </div>
        </div>
        <div className="c-right">
          <ResponsiveContainer width="95%" height="100%">
            <BarChart data={data}>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="name" />
              {/* <YAxis /> */}
              <Tooltip />
              <Legend />
              <Bar dataKey="U" fill="red" />
              <Bar dataKey="N" fill="green" />
              <Bar dataKey="A" fill="blue" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
