import _ from "lodash";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { ItemName, ItemType } from "../../../../constant";
import { getItemName, getUserItem } from "../../../../services/userService";
import "./UserItem.scss";
import { useSelector } from "react-redux";

const UserItem = () => {
    const [items, setItem] = useState([]);
    let userId = localStorage.getItem('UserIdInfo');
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getInfo();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getInfo = async () => {
        if (_.isEmpty(ItemName)) {
            let itemName = await getItemName();
            if (itemName && itemName.EC === 0) {
                for (var i = 0; i < itemName.DT.length; i++) {
                    ItemName[itemName.DT[i].id] = itemName.DT[i].name;
                }
            }
        }

        let data = await getUserItem(userId);
        if (data && +data.EC === 0) {
            setItem(data.DT);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    }

    return (<div className="user-item-container">
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>ID</th>
                        <th>Tên</th>
                        <th>Loại</th>
                        <th>Số lượng</th>
                        <th>HSD</th>
                        <th>Ngày nhận</th>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length > 0 &&
                        items.map((item, index) => {
                            return (<tr key={`item-${index}`}>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}><img width={60} height={60} src={`/DB/item/${item.item_id}.png`} alt={item.id}></img></td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.item_id}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{ItemName[item.item_id]}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{ItemType[item.item_id]}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.number}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.expire ? item.expire === -1 ? "Vĩnh viễn" : new Date(+item.expire).toLocaleString() : "-"}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(item.date_created).toLocaleDateString()}</td>
                            </tr>)
                        })}
                </tbody>
            </Table>
            {(!items || items.length === 0) &&
                <div className="no-data">Không có dữ liệu</div>
            }
        </div>
    </div >)
}
export default UserItem;