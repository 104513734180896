import "react-pro-sidebar/dist/css/styles.css";
import { RiSave3Line } from "react-icons/ri";
import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { FaMapMarkedAlt, FaPaperPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";

import "../SideBar.scss"
import Footer from "../Header/Footer";

const ResourcesSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;
    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}>
                {Logo({ collapsed: collapsed, title: "RESOURCES" })}
                <SidebarContent>

                    <Menu iconShape="circle">
                        <MenuItem icon={<RiSave3Line />} className="item-content">
                            DB Text
                            <Link to="/resources/db-text" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaMapMarkedAlt />} className="item-content">
                            Map
                            <Link to="/resources/map" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaPaperPlane />} className="item-content">
                            Teleport
                            <Link to="/resources/teleport" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
}

export default ResourcesSideBar;
