import { useState, useReducer } from "react";
import { toast } from "react-toastify";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import "./ToastMessage.scss";
import { Form } from "react-bootstrap";
import { ToastResponse } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { getToastMessage } from "../../../../services/apiCmsService";


const ToastMessage = () => {
    const [msg, setMsg] = useState();
    const [type, setType] = useState(0);
    const [server, setServer] = useState(0);
    const nightMode = useSelector((state) => state?.night?.mode);


    const handleBtnSend = async () => {
        if (server < -3) {
            toast.error("Server phải lớn hơn -3");
            return;
        }
        let data = await getToastMessage(server, type, msg);
        setMsg("");
        ToastResponse(data);
    };

    return (
        <div className="toast-message-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Send Toast Message</div>
            <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
                <FloatingLabel className="input-server"
                    controlId="floating" label="Server (0:gửi tất cả SV)">
                    <Form.Control type="number" value={server}
                        onChange={(event) => setServer(+event.target.value)}
                    />
                </FloatingLabel>
                <Form.Select
                    className="select-content"
                    value={type}
                    onChange={(event) => {
                        setType(event.target.value);
                    }}
                >
                    <option value={0}>Normal (black)</option>
                    <option value={1}>Success  (red)</option>
                    <option value={2}>Fail  (brown)</option>
                </Form.Select>


                <Form.Group className="input-msg" >
                    <Form.Label className="un-select-aord">Nhập tin nhắn</Form.Label>
                    <Form.Control as="textarea" rows={10} value={msg} autoFocus
                        onChange={(event) => setMsg(event.target.value)} />
                </Form.Group>
                <button className="btn btn-danger btn-content" onClick={handleBtnSend}>
                    Send
                </button>
            </div>
        </div >)
}

export default ToastMessage;