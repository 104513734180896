import { AVATAR, Bonus, GEM, GOLD, GetImgBgr, GetImgBorder, HERO, ITEM, ITEM_EQUIP, ITEM_FARM, ITEM_POINT, PET, PIECE, SKIN, USER_EXP, VIP_EXP, WEAPON, RUBY } from "../../enum/BonusType";
import { FormatNumber } from "../../utils/Utils";
import "./Custom.scss"

const BonusImage = (strBonus, scale, nightMode, ListEquip, ListItem, ListWeapon, ListItemSeed, ListItemFarm, ListItemTool, ListItemPiece, ListItemFood) => {
    let arrBonus = [];
    let obj;

    if (strBonus !== null) {
        let aBonus = JSON.parse(strBonus);
        for (let i = 0; i < aBonus.length;) {
            let bonus = Bonus(aBonus[i]);
            switch (bonus.id) {
                case GOLD:
                case GEM:
                case USER_EXP:
                case VIP_EXP:
                case RUBY:
                    obj = ListItem.find((e) => e.id === bonus.idItem);
                    arrBonus.push({ src: bonus.src, number: aBonus[bonus.size - 1], alt: bonus.name, rank: obj?.rank })
                    break;
                case ITEM_EQUIP:
                    obj = ListEquip.find((e) => e.id === aBonus[i + 1]);
                    arrBonus.push({ src: bonus.src.replace("id", aBonus[i + 1]), alt: bonus.name + bonus[i + 1], time: aBonus[i + 2], lock: aBonus[i + 3], rank: obj?.rank })
                    break;
                case ITEM:
                    obj = ListItem.find((e) => e.id === aBonus[i + 1]);
                    arrBonus.push({ src: bonus.src.replace("id", aBonus[i + 1]), alt: bonus.name + aBonus[i + 1], number: aBonus[bonus.size - 1], rank: obj?.rank })
                    break;
                // case ITEM_POINT:
                //     obj = ListItemPoint.find((e) => e.id === aBonus[i + 1]);
                //     arrBonus.push({ src: bonus.src.replace("id", aBonus[i + 1]), alt: bonus.name + aBonus[i + 1], number: aBonus[bonus.size - 1], rank: obj?.rank })
                //     break;
                case PIECE:
                    obj = ListItemPiece.find((e) => e.id === aBonus[i + 2]);
                    arrBonus.push({ src: bonus.src.replace("id", aBonus[i + 1]), alt: bonus.name + aBonus[i + 1], number: aBonus[bonus.size - 1], rank: obj?.rank })
                    break;
                case AVATAR:
                case PET:
                case SKIN:
                case HERO:
                    arrBonus.push({ src: bonus.src.replace("id", aBonus[i + 1]), alt: bonus.name + aBonus[i + 1], number: aBonus[bonus.size - 1], rank: 3 })
                    break;
                case WEAPON:
                    obj = ListWeapon.find((e) => e.id === aBonus[i + 1]);
                    arrBonus.push({ src: bonus.src.replace("id", aBonus[i + 1]), alt: bonus.name + aBonus[i + 1], rank: obj?.rank })
                    break;
                case ITEM_FARM:
                    let type = aBonus[i + 1];
                    let id = aBonus[i + 2];
                    if (type === 1) {
                        obj = ListItemSeed.find((e) => e.id === id);
                    } else if (type === 2) {
                        obj = ListItemFarm.find((e) => e.id === id);
                    } else if (type === 3) {
                        obj = ListItemTool.find((e) => e.id === id);
                    } else if (type === 4) {
                        obj = ListItemFood.find((e) => e.id === id);
                    }
                    let srcFarm = bonus.src.replace("type", type)
                    srcFarm = srcFarm.replace("id", id)
                    arrBonus.push({ src: srcFarm, alt: "Item Farm " + aBonus[i + 2], number: aBonus[bonus.size - 1], rank: obj?.rank })
                    break;
            }
            i += bonus.size;

        }
    }
    return (<div className="bonus-image-container" >
        {arrBonus && arrBonus.length > 0 && arrBonus.map((item, index) => {
            return (
                <div key={`item-bonus-${index}`}>
                    <div className="item">
                        < img width={80 * scale}
                            height={80 * scale}
                            src={item.src} />
                        <div className="number-item-content">
                            {item.number !== undefined ? <b>{FormatNumber(+item.number)}</b> : <></>}
                        </div>
                    </div>
                    <div className="bgr-item" >
                        < img width={100 * scale}
                            height={100 * scale}
                            src={GetImgBgr(item.rank)} />
                    </div>
                    <div className="border-item" >
                        < img width={100 * scale}
                            height={100 * scale}
                            src={GetImgBorder(item.rank)} />

                    </div>

                </div>)
        })}
    </div >)
}

export default BonusImage;