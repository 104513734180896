import { Form, Table } from "react-bootstrap";
import "./ChatInvalid.scss"
import { useState } from "react";
import { useEffect } from "react";
import { getAllChatInvalid, getDelKeyInvalid, getFindKeyChatInvalid, getReloadChatInvalid, postEditChatInvalid } from "../../../../services/apiCmsService";
import { ToastNoSuccess, ToastResponse } from "../../../../utils/Utils";
import { BiSearchAlt } from "react-icons/bi";
import ModalCreateInvalid from "../../Modal/ModalCreateInvalid";
import _ from "lodash";
import { useSelector } from "react-redux";

const ChatInvalid = (props) => {
    const [search, setSearch] = useState("");
    const [keys, setKeys] = useState([]);
    const [cache, setCache] = useState([]);
    const [show, setShow] = useState(false);
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getListKey();
    }, [])

    const getListKey = async () => {
        let data = await getAllChatInvalid();
        ToastNoSuccess(data);
        if (data && data.EC === 0) {
            setKeys(data.DT);
            setCache(data.DT);
        }
    }

    const handleButtonReloadConfig = async () => {
        let data = await getReloadChatInvalid();
        ToastResponse(data);
    }

    const handleButtonCreate = async () => {
        setShow(true);
    }

    const handleDelClick = async (id) => {
        let data = await getDelKeyInvalid(id);
        ToastResponse(data);
        getListKey();
    }

    const handleEditKey = (event, index) => {
        let clone = _.cloneDeep(keys);
        clone[index].k = event.target.value;
        setKeys(clone);
    }

    const handleSaveClick = async (key) => {
        let data = await postEditChatInvalid(key.id, key.k);
        ToastResponse(data);
        if (data && data.EC === 0) {
            getListKey();
        }
    }

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            handleFindClick();
        }
    }

    const handleBtnResetClick = (index) => {
        let clone = _.cloneDeep(keys);
        clone[index].k = cache[index].k ? cache[index].k : "";
        setKeys(clone);
    }

    const handleFindClick = async () => {
        if (search === "") {
            setKeys(cache);
            return
        }
        let data = await getFindKeyChatInvalid(search);
        ToastNoSuccess(data);
        if (data && +data.EC === 0) {
            setKeys(data.DT);
        } else setKeys([]);
    }

    return (<div className="chat-invalid-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Config Chat Invalid</div>
        <div className="list-btn">
            <button className="btn-item btn btn-success"
                onClick={handleButtonCreate}
            >Created</button>
            <button className="btn-item btn btn-primary"
                onClick={handleButtonReloadConfig}
            >Reload</button>
        </div>
        <div className={+nightMode === 0 ? "input-search" : "input-search input-search-night"}>
            <Form.Control
                className="input-key"
                type="text"
                value={search}
                onChange={(event) => {
                    setSearch(event.target.value);
                }}
                onKeyDown={(event) => handleKeyDown(event)}
            />
            <BiSearchAlt className="search-icon" onClick={() => handleFindClick()} />
        </div>
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Id</th>
                        <th style={{ width: '40%' }}>Key</th>
                        <th style={{ width: '40%' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {keys && keys.length > 0 && (
                        keys.map((key, index) => {
                            return (
                                <tr key={`key-chat-keys1-${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{key.id}</td>
                                    <td>
                                        <Form.Control
                                            className={+nightMode === 1 ? "form-input-dark input-key" : "input-key form-input-light"}
                                            type="text"
                                            value={key.k}
                                            onChange={(event) => handleEditKey(event, index)}
                                        />
                                    </td>
                                    <td>
                                        {key.k !== cache[index].k ?
                                            <button className="btn-action btn btn-success"
                                                onClick={() => handleSaveClick(key)}
                                            >Lưu</button>
                                            : <></>
                                        }
                                        {key.k !== cache[index].k ?
                                            <button className="btn-action btn btn-warning"
                                                onClick={() => handleBtnResetClick(index)}
                                            >Hủy</button>
                                            : <></>
                                        }
                                        <button className="btn-action btn btn-danger"
                                            onClick={() => handleDelClick(key.id)}
                                        >
                                            Xóa
                                        </button>

                                    </td>
                                </tr>)
                        })
                    )}


                </tbody>
            </Table>
        </div>
        <ModalCreateInvalid
            show={show}
            setShow={setShow}
            getListKey={getListKey}
        />
    </div>)
}
export default ChatInvalid;