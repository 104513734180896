import { useState } from "react";
import "./Auth.scss";
import { useNavigate } from "react-router-dom";
import Bgr from "../../assets/bgr/raid.png";
import { postLogin, postRegister } from "../../services/apiService";
import { toast } from "react-toastify";
import { ValidateEmail, ValidatePassword } from "../../utils/Validate";
import { ImSpinner10 } from "react-icons/im";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTranslation } from "react-i18next";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import Header from "../Header/Header";
import { USER } from "../../constant";
import { changeMode } from "../../redux/slices/nightModeSlice";



const Login = (props) => {
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmail, setValidEmail] = useState(false);
  const [isValidPass, setValidPass] = useState(false);
  const { t } = useTranslation();
  const [isShowPassword, setShowPassword] = useState(false);
  const [isShowRePassword, setShowRePassword] = useState(false);
  const [isValidRePass, setValidRePass] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isValidName, setValidName] = useState(false);
  const [curTab, setCurTab] = useState("login");

  const handleLogin = async () => {
    const inValidEmail = ValidateEmail(email);
    if (!inValidEmail) {
      toast.error(t("Auth.err_email"));
      setValidEmail(true);
      return
    }
    if (!password) {
      toast.error(t("Auth.err_password"));
      setValidPass(true);
      return
    }
    // submit api
    setIsLoading(true);

    let data = await postLogin(email, password);
    if (data && +data.EC === 0) {
      dispatch(changeMode(data.DT.mode));
      toast.success(data.EM);
      localStorage.setItem(USER, JSON.stringify(data.DT));

      navigate("/");
    }
    if (data && +data.EC !== 0) {
      toast.error(data.EM);
      setValidPass(true);
    }
    setIsLoading(false);
  };

  const handleKeyDown = (event) => {
    setValidPass(false);
    setValidEmail(false);
    if (event && event.key === "Enter") {
      handleLogin();
    }
  };
  const handlerRegister = async () => {
    const isValidEmail = ValidateEmail(email);
    let fail = false;
    if (!isValidEmail) {
      toast.error(t("Auth.err_email"));
      setValidEmail(true);
      fail = true;
    }
    const isValidPass = ValidatePassword(password);
    if (!isValidPass) {
      toast.error(t("Auth.err_password"));
      setValidPass(true);
      fail = true;
    }
    if (!username) {
      toast.error(t("Auth.err_name"));
      setValidName(true);
      fail = true;
    }
    if (password !== passwordConfirm) {
      toast.error(t("Auth.err_repass"));
      setValidRePass(true);
      fail = true;
    }
    if (fail) return;
    if (!username || !email || !password || !passwordConfirm) {
      toast.error(t("Auth.err_param"));
      return;
    }
    let data = await postRegister(username, email, password, passwordConfirm);
    if (data && +data.EC === 0) {
      toast.success(data.EM);
      setCurTab("login");
    }
    if (data && +data.EC !== 0) {
      toast.error(data.EM);
    }
  };


  return (
    <div className="login-container">
      <div>
        {Header({ showSetting: false, nightMode: 0 })}
      </div>
      <img src={Bgr} className="bgr-main" alt="IMG BG HOME" />

      <div className="content-form container">
        <Tabs activeKey={curTab} className="tab-panel" onSelect={(k) => setCurTab(k)}>
          <Tab eventKey="login" title={t("Auth.sign_in")} >
            <div className="login-panel">
              <div>
                <label className="title">Email</label>
                <Form.Control
                  type="email"
                  value={email}
                  isInvalid={isValidEmail}
                  autoFocus
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setValidEmail(false);
                  }}
                  onClick={() => setValidEmail(false)}
                />
              </div>
              <div className="form-group pass-group">
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-${"right"}`}>
                      <p>{t("Auth.pass_1")}</p>
                      <p>{t("Auth.pass_2")}</p>
                      <p>{t("Auth.pass_3")}</p>
                    </Tooltip>
                  }
                >
                  <label className="title">{t(`Auth.password`)} *</label>
                </OverlayTrigger>

                <Form.Control
                  type={isShowPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setValidPass(false);
                  }}
                  onKeyDown={(event) => handleKeyDown(event)}
                  isInvalid={isValidPass}
                  onClick={() => setValidPass(false)}
                />
                {!isValidPass &&
                  (isShowPassword ? (
                    <span
                      className="icons-eye"
                      onClick={() => setShowPassword(false)}
                    >
                      <VscEye />
                    </span>
                  ) : (
                    <span className="icons-eye" onClick={() => setShowPassword(true)}>
                      <VscEyeClosed />
                    </span>
                  ))}
              </div>

              <div>
                <button
                  className="btn-submit"
                  onClick={() => handleLogin()}
                  disabled={isLoading}
                >
                  {isLoading && <ImSpinner10 className="loader-icon" />}
                  <span>{t("Auth.sign_in")}</span>
                </button>
              </div>
            </div>
          </Tab>
          <Tab eventKey="register" title={t("Auth.sign_up")} >
            <div className="register-panel">
              <div className="form-group">
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-${"right"}`}>
                      {t("Auth.email_format")}
                    </Tooltip>
                  }
                >
                  <label className="title">
                    Email <b>*</b>
                  </label>
                </OverlayTrigger>
                <Form.Control
                  type="email"
                  value={email}
                  isInvalid={isValidEmail}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setValidEmail(false);
                  }}
                  onClick={() => setValidEmail(false)}
                />
              </div>
              <div className="form-group">
                <label className="title">{t("Auth.user_name")}</label>
                <Form.Control
                  type={"text"}
                  value={username}
                  isInvalid={isValidName}
                  onChange={(event) => {
                    setUserName(event.target.value);
                    setValidName(false);
                  }}
                  onClick={() => setValidName(false)}
                />
              </div>

              <div className="form-group pass-group">
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-${"right"}`}>
                      <p>{t("Auth.pass_1")}</p>
                      <p>{t("Auth.pass_2")}</p>
                      <p>{t("Auth.pass_3")}</p>
                    </Tooltip>
                  }
                >
                  <label className="title">
                    {t("Auth.password")} <b>*</b>
                  </label>
                </OverlayTrigger>
                <Form.Control
                  type={isShowPassword ? "text" : "password"}
                  value={password}
                  isInvalid={isValidPass}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setValidPass(false);
                  }}
                  onClick={() => setValidPass(false)}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
                {!isValidPass &&
                  (isShowPassword ? (
                    <span
                      className="icons-eye"
                      onClick={() => setShowPassword(false)}
                    >
                      <VscEye />
                    </span>
                  ) : (
                    <span className="icons-eye" onClick={() => setShowPassword(true)}>
                      <VscEyeClosed />
                    </span>
                  ))}
              </div>
              <div className="form-group pass-group">
                <label className="title">{t("Auth.confirm_password")}</label>
                <Form.Control
                  type={isShowRePassword ? "text" : "password"}
                  value={passwordConfirm}
                  isInvalid={isValidRePass}
                  onChange={(event) => {
                    setPasswordConfirm(event.target.value);
                    setValidRePass(false);
                  }}
                  onClick={() => setValidRePass(false)}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
                {!isValidRePass &&
                  (isShowRePassword ? (
                    <span
                      className="icons-eye"
                      onClick={() => setShowRePassword(false)}
                    >
                      <VscEye />
                    </span>
                  ) : (
                    <span
                      className="icons-eye"
                      onClick={() => setShowRePassword(true)}
                    >
                      <VscEyeClosed />
                    </span>
                  ))}
              </div>
              <div>
                <button
                  className="btn-submit title"
                  onClick={() => handlerRegister()}
                >
                  {t("Auth.create_account")}
                </button>
              </div>

            </div>
          </Tab>
        </Tabs>

      </div>
    </div >

  )
}

export default Login;
