import { Routes, Route } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import ManageUser from "./components/Admin/Content/ManageUser/ManageUser";
import DashBoard from "./components/Admin/Content/DashBoard/DashBoard";
import Login from "./components/Auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./routes/PrivateRoute";
import { Suspense } from "react";
import UserInfo from "./components/User/Content/Info/UserInfo";
import UserHero from "./components/User/Content/Hero/UserHero";
import UserItem from "./components/User/Content/Item/UserItem";
import UserItemEquip from "./components/User/Content/ItemEquipment/UserItemEquip";
import UserWeapon from "./components/User/Content/Weapon/UserWeapon";
import UserMail from "./components/User/Content/Mail/UserMail";
import UserData from "./components/User/Content/Data/UserData";
import UserCmsLog from "./components/Admin/Content/UserCmsLog/UserCmsLog";
import ChatInvalid from "./components/Config/Content/ChatInvalid/ChatInvalid";
import SlideMessage from "./components/Monitor/Comportnent/Slider/SlideMessage";
import ServerStatus from "./components/Server/Content/ServerStatus";
import useCheckMobileScreen from "./utils/useCheckMobileScreen ";
import useMobile from "./utils/useMobile";
import ToastMessage from "./components/Monitor/Comportnent/Toast/ToastMessage";
import CommonSideBar from "./components/CommonSideBar";
import MonitorSideBar from "./components/Monitor/MonitorSideBar";
import CalDame from "./components/Tools/Compornent/CalDame";
import ToolSideBar from "./components/Tools/ToolSideBar";
import CalDameEx from "./components/Tools/Compornent/CalDameEx";
import CalExp from "./components/Tools/Compornent/CalExp";
import AdminSideBar from "./components/Admin/AdminSideBar";
import ServerSideBar from "./components/Server/ServerSideBar";
import UserInfoSideBar from "./components/UserInfo/UserInfoSideBar";
import DBText from "./components/DataGame/Content/DBText/DBText";
import Map from "./components/DataGame/Content/Map/Map";
import Teleport from "./components/DataGame/Content/Teleport/Teleport";
import ResourcesSideBar from "./components/DataGame/ResourcesSideBar";
import Config from "./components/Config/Content/Config";
import ConfigHelp from "./components/Config/Content/ConfigHelp";
import ConfigLang from "./components/Config/Content/ConfigLang";
import ConfigDev from "./components/Config/Content/ConfigDev";
import ConfigSideBar from "./components/Config/ConfigSideBar";
import PackSideBar from "./components/Pack/PackSideBar";
import ListPack from "./components/Pack/Content/ListPack/ListPack";
import Upload from "./components/Pack/Content/Upload/Upload";
import UpApk from "./components/Pack/Content/UpApk/UpApk";
import Version from "./components/Pack/Content/Version/Version";
import UpLoadImage from "./components/Pack/Content/UpLoadImage/UpLoadImage";
import TopSideBar from "./components/Top/TopSideBar";
import TopUser from "./components/Top/Content/TopUser";
import TopWeapon from "./components/Top/Content/TopWeapon";
import TopItemEquipment from "./components/Top/Content/TopItemEquipment";
import TopItem from "./components/Top/Content/TopItem";
import TopClan from "./components/Top/Content/TopClan";
import FindUser from "./components/UserInfo/Content/FindUser/FindUser"
import Clan from "./components/UserInfo/Content/Clan/Clan"
import UserSendMail from "./components/UserInfo/Content/SendUserMail/UserSendMail"
import SystemMail from "./components/UserInfo/Content/SystemMail/SystemMail"
import CCU from "./components/Stat/Content/CCU";
import DAU from "./components/Stat/Content/DAU";
import MAU from "./components/Stat/Content/MAU";
import NRU from "./components/Stat/Content/NRU";
import RR from "./components/Stat/Content/RR";
import Quit7 from "./components/Stat/Content/Quit7";
import Quit30 from "./components/Stat/Content/Quit30";
import StatSideBar from "./components/Stat/StatSideBar";
import UserSideBar from "./components/User/UserSideBar";
import RoleRoute from "./routes/RoleRoute";
import { Role } from "./constant";

const NotFound = () => {
  return (
    <div className="alert mt-3 alert-danger container">404 Not Found!</div>
  );
};

export const Layout = () => {
  return (
    <Suspense fallback="...is loading">
      {useCheckMobileScreen() === true ? useMobile :
        <div>
          <Routes>
            <Route index element={<HomePage />} />
            {/* <Route index element={<PrivateRoute><HomePage /></PrivateRoute>} /> */}
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} /> */}
            {/* Tools */}
            <Route path="/tools" element={RoleRoute({ child: CommonSideBar({ SideBar: ToolSideBar, defauCollapsed: false }), role: Role.GD })}>
              <Route index element={<CalDame />} />
              <Route path="cal-dame" element={<CalDame />} />
              <Route path="cal-dame-ex" element={<CalDameEx />} />
              <Route path="cal-exp" element={<CalExp />} />
            </Route>
            {/* admins */}
            <Route path="/admins" element={RoleRoute({ child: CommonSideBar({ SideBar: AdminSideBar, defauCollapsed: false }), role: Role.ADMIN })} >
              <Route index element={<DashBoard />} />
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="manage-users" element={(<ManageUser />)} />
              <Route path="cms-log" element={(<UserCmsLog />)} />
            </Route>

            {/* Monitor */}
            <Route path="/monitor" element={RoleRoute({ child: CommonSideBar({ SideBar: MonitorSideBar, defauCollapsed: false }), role: Role.BASIC })}>
              <Route index element={<SlideMessage />} />
              <Route path="slider-message" element={<SlideMessage />} />
              <Route path="toast-message" element={<ToastMessage />} />
            </Route>

            {/* Server Status */}
            <Route path="/server" element={RoleRoute({ child: CommonSideBar({ SideBar: ServerSideBar, defauCollapsed: false }), role: Role.GD })}>
              <Route index element={<ServerStatus />} />
              <Route path="status" element={<ServerStatus />} />
            </Route>

            {/* User Info */}
            <Route path="/user-info" element={RoleRoute({ child: CommonSideBar({ SideBar: UserInfoSideBar, defauCollapsed: false }), role: Role.BASIC })}>
              <Route index element={<FindUser />} />
              <Route path="users" element={<FindUser />} />
              <Route path="clans" element={<Clan />} />
              <Route path="send-mail" element={< UserSendMail />} />
              <Route path="system-mail" element={<SystemMail />} />
            </Route>

            {/* Data Game */}
            <Route path="/resources" element={RoleRoute({ child: CommonSideBar({ SideBar: ResourcesSideBar, defauCollapsed: false }), role: Role.GD })}>
              <Route index element={<DBText />} />
              <Route path="db-text" element={< DBText />} />
              <Route path="map" element={<Map />} />
              <Route path="teleport" element={< Teleport />} />
            </Route>

            {/* Config */}
            <Route path="/config" element={RoleRoute({ child: CommonSideBar({ SideBar: ConfigSideBar, defauCollapsed: false }), role: Role.GD })}>
              <Route index element={<Config />} />
              <Route path="prop" element={< Config />} />
              <Route path="dev" element={< ConfigDev />} />
              <Route path="help" element={< ConfigHelp />} />
              <Route path="lang" element={< ConfigLang />} />
              <Route path="chat" element={< ChatInvalid />} />
            </Route>

            {/* Upload */}
            <Route path="/pack" element={RoleRoute({ child: CommonSideBar({ SideBar: PackSideBar, defauCollapsed: false }), role: Role.UPLOAD })}>
              <Route index element={<Upload />} />
              <Route path="upload" element={< Upload />} />
              <Route path="up-apk" element={< UpApk />} />
              <Route path="list-pack" element={< ListPack />} />
              <Route path="version" element={< Version />} />
              <Route path="up-image" element={< UpLoadImage />} />
            </Route>

            {/* Top */}
            <Route path="/top" element={RoleRoute({ child: CommonSideBar({ SideBar: TopSideBar, defauCollapsed: false }), role: Role.BASIC })}>
              <Route index element={<TopUser />} />
              <Route path="user" element={< TopUser />} />
              <Route path="clan" element={< TopClan />} />
              <Route path="item" element={< TopItem />} />
              <Route path="equip" element={< TopItemEquipment />} />
              <Route path="weapon" element={< TopWeapon />} />
            </Route>

            {/* Stats */}
            <Route path="/stat" element={RoleRoute({ child: CommonSideBar({ SideBar: StatSideBar, defauCollapsed: false }), role: Role.BASIC })}>
              <Route index element={<CCU />} />
              <Route path="ccu" element={< CCU />} />
              <Route path="dau" element={< DAU />} />
              <Route path="mau" element={< MAU />} />
              <Route path="nru" element={< NRU />} />
              <Route path="rr" element={< RR />} />
              <Route path="quit7" element={< Quit7 />} />
              <Route path="quit30" element={< Quit30 />} />
            </Route>
            {/* user */}
            <Route path="/user" element={RoleRoute({ child: CommonSideBar({ SideBar: UserSideBar, defauCollapsed: false }), role: Role.BASIC })}>
              <Route index element={<UserInfo />} />
              <Route path="user-info" element={<UserInfo />} />
              <Route path="user-data" element={<UserData />} />
              <Route path="user-hero" element={<UserHero />} />
              <Route path="user-item" element={<UserItem />} />
              <Route path="user-item-equip" element={<UserItemEquip />} />
              <Route path="user-mail" element={<UserMail />} />
              <Route path="user-weapon" element={<UserWeapon />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            limit={5}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div >
      }


    </Suspense >
  );
};
