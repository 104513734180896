import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { FcReading, FcConferenceCall, FcPaid, FcBiomass, FcInvite, FcFlashOn, FcKindle } from "react-icons/fc";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import "../SideBar.scss";
import Footer from "../Header/Footer";

const UserSideBar = (props) => {
    const { collapsed, toggled, handleToggleSidebar, sidebarBg } = props;
    const userId = localStorage.getItem('UserIdInfo');


    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggled}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "ID " + userId, link: "/user-info/users" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcReading />}>
                            Thông tin
                            <Link to="/user/user-info" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcKindle />}>
                            Dữ liệu
                            <Link to="/user/user-data" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcConferenceCall />}>
                            Hero
                            <Link to="/user/user-hero" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcPaid />}>
                            Vật phẩm
                            <Link to="/user/user-item" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcBiomass />}>
                            Trang bị
                            <Link to="/user/user-item-equip" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcInvite />}>
                            Thư
                            <Link to="/user/user-mail" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FcFlashOn />}>
                            Vũ khí
                            <Link to="/user/user-weapon" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
};

export default UserSideBar;
