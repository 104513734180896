import { useState } from "react";
import { postCreateChatInvalid } from "../../../services/apiCmsService";
import { ToastResponse } from "../../../utils/Utils";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

const ModalCreateInvalid = (props) => {
    const { show, setShow, getListKey } = props;
    const [key, setKey] = useState("");

    const handleClose = () => {
        setKey("");
        setShow(false);
    }

    const handleCreateKey = async () => {
        let data = await postCreateChatInvalid(key);
        ToastResponse(data);
        if (data && data.EC === 0) {
            await getListKey();
        }
        handleClose();
    }

    return (<>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            className="modal-create-chat-invalid"
        >
            <Modal.Header>
                <Modal.Title>Tạo mới từ chat cấm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="ControlInput1">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id={`tooltip-${"right"}`}>
                                    <p>KEY cấm luốn phải viết thường, không được viết hoa</p>
                                    <p>Nên kiểm tra đã có key chưa, có rồi thì thôi vì sẽ tốn hiệu năng game khi thêm thừa</p>
                                </Tooltip>
                            }
                        >
                            <Form.Label>Từ cấm *</Form.Label>
                        </OverlayTrigger>
                        <Form.Control
                            type="text"
                            value={key}
                            autoFocus
                            onChange={(event) => setKey(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={handleCreateKey}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModalCreateInvalid;