import { toast } from "react-toastify";

const openUrl = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
}

const ToastResponse = (ret) => {
    if (ret && +ret.EC === 0) {
        toast.success(ret.EM);
        return;
    }
    if (ret && +ret.EC === 400) {
        toast.error(ret.EM);
        return;
    }
    if (ret && +ret.EC === 401) {
        toast.warning(ret.EM);
        return;
    }
    if (!ret) toast.error("Không nhận được dữ liệu!");
}
const GetKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}



const ToastNoSuccess = (ret) => {
    if (ret && +ret.EC === 400) {
        toast.error(ret.EM);
    }
    if (ret && +ret.EC === 401) {
        toast.warning(ret.EM);
    }
    if (!ret) toast.error("Không nhận được dữ liệu!");
}

const formatNumberDot = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const stringToArr = (str) => {
    let arr = str.split(",");
    if (arr && arr.length > 0) {
        let ret = [];
        for (let i = 0; i < arr.length; i++) {
            ret.push(+arr[i]);
        }
    }
    return null;
}

const coppyText = async (data) => {
    await navigator.clipboard.writeText(data);
}

export const FormatNumber = value => {
    if (value < 1000000) {
        return FormatK(value);
    }
    else {

        let tmp = value * 1 / 1000000;
        //    //
        let isInteger = (tmp === Math.floor(tmp));
        if (isInteger) {
            return tmp + "M";
        }
        else {
            let strM = tmp + "0.00";
            if (strM.endsWith(".00") && strM.length > 3) {
                strM = strM.substring(0, strM.length - 3);
            }
            return strM + "M";
        }
    }
}

const FormatK = (value) => {
    if (value > 10000) {
        let tmp = value * 1 / 1000;
        let isInteger = (tmp === Math.floor(tmp));
        if (isInteger) {
            return tmp + "K";
        }
        else {
            let strK = tmp + "0.00";
            if (strK.endsWith(".00") && strK.length > 3) {
                strK = strK.substring(0, strK.length - 3);
            }
            return strK + "K";
        }
    } else return value;

}

export {
    openUrl,
    ToastResponse,
    ToastNoSuccess,
    formatNumberDot,
    stringToArr,
    coppyText,
    GetKeyByValue,
}