import { useState } from "react";
import { ToastResponse } from "../../../utils/Utils";
import { postCreateConfigLang } from "../../../services/apiCmsService";
import { Badge, Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";




const ModalCreateConfigLang = (props) => {
    const { show, setShow } = props;
    const [key, setKey] = useState("");
    const [vi, setVi] = useState("");

    const handleClose = () => {
        setKey("");
        setVi("");
        setShow(false);
    }

    const handleCreateConfig = async () => {
        let data = await postCreateConfigLang(key, vi);
        ToastResponse(data);
        if (data && data.EC === 0) {
            await props.requestConfig();
        }

        handleClose();
    }

    return (<>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            className="modal-create-config-lang"
        >
            <Modal.Header>
                <Modal.Title>Thêm key config language</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="ControlInput">
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip id={`tooltip-${"right"}`}>
                                    <p>Key nên viết thường và cách nhau bằng dấu gạch chân</p>
                                    <p>exp: max_level</p>
                                </Tooltip>
                            }
                        >
                            <Form.Label ><b>Key</b>  <Badge bg="danger">Lưu ý</Badge></Form.Label>
                        </OverlayTrigger>
                        <Form.Control
                            type="text"
                            value={key}
                            autoFocus
                            onChange={(event) => setKey(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label><b>Vi</b></Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={vi}
                            onChange={(event) => setVi(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={handleCreateConfig}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModalCreateConfigLang;