import { useRef, useState, useReducer } from "react";
import { deleteFolderPack, postApk, postPackFile } from "../../../../services/apiCmsService";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { ToastResponse } from "../../../../utils/Utils";
import "./UpApk.scss";
import _ from "lodash";
import { useSelector } from "react-redux";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

const UpApk = () => {
    const [file, setFile] = useState();
    const [isSelected, setSelected] = useState(false);
    const [isShow, setShow] = useState(false);
    const nightMode = useSelector((state) => state?.night?.mode);

    const fileSelectHandler = (event) => {
        event.preventDefault();
        setSelected(event.target.files.length > 0);
        setFile(event.target.files[0]);
    }


    const handleUpload = async () => {
        if (!isSelected) {
            toast.error("Cần chọn file trước");
            return
        }
        setShow(true);
        let data = await postApk(file);
        setShow(false);
        ToastResponse(data);
    }


    return (<div className="upload-pack-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Upload APK</div>
        <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
            <div className="pack-content">
                <Form.Control
                    type="file"
                    onChange={fileSelectHandler}
                    size="lg" />
                {isSelected &&
                    <div className="list-select container">
                        <Row>
                            <Col md={7} className="title-col">Name</Col>
                            <Col className="title-col">Size</Col>
                        </Row>
                        {file && (
                            <Row>
                                <Col md={7}>{file.name}</Col>
                                <Col >{Math.round(file.size / 100000) / 10} Mb</Col>
                            </Row>)}
                        <Row>
                            <Col md={7}>Thời gian dự kiến:</Col>
                            <Col >{Math.round(file.size / 1000000) / 5} giây </Col>
                        </Row>
                    </div>

                }

            </div>
            <div className="group-button">
                {isShow ? <Spinner animation="border" variant="primary" /> : <div></div>}
                {isSelected && isShow == false ? <div><button className="btn btn-success" onClick={handleUpload}  >Upload</button> </div> : <div></div>}

            </div>
        </div>
    </div >)
}

export default UpApk;