// var d = new Date();
// d.toLocaleString();       // -> "2/1/2013 7:37:08 AM"
// d.toLocaleDateString();   // -> "2/1/2013"
// d.toLocaleTimeString();  // -> "7:38:05 AM"

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

function formatDate(date = new Date()) {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-');
}
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function getDateBefore(numBerfore) {
    let date = new Date();
    date.setDate(new Date().getDate() - numBerfore);
    return date;
}

function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
    return formatedDate;
}


function getDateAfter(numBerfore) {
    let date = new Date();
    date.setDate(new Date().getDate() + numBerfore);
    return date;
}

export {
    timeout,
    formatDate,
    getDateBefore,
    getDateAfter,
    stringToDate,
}