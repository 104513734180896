import { Navigate } from "react-router-dom";
import { Role, USER } from "../constant";
import { toast } from "react-toastify";

const RoleRoute = (props) => {
    let { role, child } = props;
    const user = JSON.parse(localStorage.getItem(USER));
    if (user === null || user.role === null) {
        return <Navigate to={"/login"}></Navigate>;
    }
    let myRole = JSON.parse(user.role);
    let check = myRole.includes(Role.SUPER_ADMIN);
    if (!check) {
        check = myRole.find(e => +e === +role) !== undefined;
    }
    if (check) {
        return <>{child}</>;
    } else {
        return <Navigate to={"/"}></Navigate>;
    }
};

export default RoleRoute;
