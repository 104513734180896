import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import "./Show.scss"


const Profile = (props) => {
    const { show, setShow } = props;

    const handleClose = () => {
        setShow(false);
    };
    return (<div className="profile-container">
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            backdrop="static"
            className="modal-profile"
        >
            <Modal.Header closeButton>
                <Modal.Title>Update user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3">
                    <div className="col-md-6">
                        <label className="form-label">Email</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Password</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Username</label>

                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Role</label>

                    </div>
                    <div className="col-md-12">
                        <label className="form-label label-upload" htmlFor="LabelUpload">
                            Upload File Image
                        </label>

                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)
}
export default Profile;