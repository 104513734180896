import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { putUpdateUser } from "../../../../services/adminService";
import _ from "lodash";
import { convertBase64 } from "../../../../utils/convertBase64";

const ModalUpdateUser = (props) => {
  const { show, setShow, dataUser } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("[]");
  // const [image, setImage] = useState("");
  // const [previewImage, setPreviewImage] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(dataUser)) {
      setEmail(dataUser.email);
      setUsername(dataUser.username);
      setRole(dataUser.role);
      setActive(dataUser.active);
      // setImage("");
      // if (!_.isEmpty(dataUser.avatar)) setPreviewImage(dataUser.avatar);
    }
  }, [dataUser]);

  // const handleUploadImage = (event) => {
  //   if (event.target && event.target.files && event.target.files[0]) {
  //     // setPreviewImage(URL.createObjectURL(event.target.files[0]));
  //     // setImage(event.target.files[0]);
  //   } else setPreviewImage("");
  // };

  const handleClose = () => {
    setShow(false);
    setEmail("");
    setPassword("");
    setUsername("");
    setRole("[]");
    // setImage("");
    // setPreviewImage("");
    props.resetUpdateData();
  };

  const handleSubmitUpdateUser = async () => {
    // let avatar = image ? await convertBase64(image) : "";
    let data = await putUpdateUser(username, dataUser.id, role, active);
    if (data.EC === 0) {
      toast.success(data.EM);
      handleClose();
      await props.fetchListUserPaginateData();
    } else {
      toast.error(data.EM);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        className="modal-add-user"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-md-6">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                value={email}
                disabled
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                value={password}
                disabled
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Username</label>
              <input
                type="text"
                className="form-control"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Role</label>
              <input
                type="text"
                className="form-control"
                value={role}
                onChange={(event) => setRole(event.target.value)}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Active</label>
              <select
                className="form-select"
                onChange={(event) => setActive(event.target.value)}
                value={active}
              >
                <option value="1">YES</option>
                <option value="0">NO</option>
              </select>
            </div>
            {/* <div className="col-md-12">
              <label className="form-label label-upload" htmlFor="LabelUpload">
                <FcPlus /> Upload File Image
              </label>
              <input
                type="file"
                id="LabelUpload"
                hidden
                onChange={(event) => handleUploadImage(event)}
              />
            </div> */}
            {/* <div className="col-md-12 img-preview">
              {previewImage ? (
                <img src={previewImage} alt="" />
              ) : (
                <span>Preview Image</span>
              )}
            </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSubmitUpdateUser()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUpdateUser;
