import "./MobileScene.scss";

const useMobile = (
    <div className="mobile-scsene-container">
        <div className="title"> Không hỗ trợ mobile :)</div>
        <img
            className="logo-content"
            alt=""
            src="/logo512.png"
            width="80%"
            height="auto"
        />
    </div >
);

export default useMobile;