import { useState, useEffect } from "react";
import "./Stats.scss";
import { ToastNoSuccess } from "../../../utils/Utils";
import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { C_ANDROID, C_IOS, C_OSX, C_PC, C_TOTAL, C_UNITY } from "../../../constant";
import { getQuit30 } from "../../../services/apiCmsService";
import { useSelector } from "react-redux";

const Quit30 = () => {
    const [quit, setQuit] = useState([]);
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getListQuit7();
    }, []);

    const getListQuit7 = async () => {
        let data = await getQuit30();
        if (data && data.EC === 0) {
            setQuit(data.DT);
        }
        ToastNoSuccess(data);
    }

    return (<div className="stats-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Quit 30 Day</div>
        <div className="input-content" ></div>
        {
            (quit && quit.length > 0) ? <div className="chart-content">
                <LineChart width={1400} height={550} data={quit} >
                    <XAxis dataKey="date" padding={{ left: 15, right: 15 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="TOTAL" stroke={C_TOTAL} strokeWidth={2} />
                    <Line type="monotone" dataKey="ANDROID" stroke={C_ANDROID} />
                    <Line type="monotone" dataKey="IOS" stroke={C_IOS} />
                    <Line type="monotone" dataKey="UNITY" stroke={C_UNITY} activeDot={{ r: 3 }} />
                    <Line type="monotone" dataKey="PC" stroke={C_PC} />
                    <Line type="monotone" dataKey="OSX" stroke={C_OSX} />
                </LineChart>
            </div> : <div className="no-data">Không có dữ liệu</div>
        }
    </div>)
}
export default Quit30;