import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    access_token: "",
    username: "",
    image: "",
    role: "",
    isAuthenticated: false,
  },
  reducers: {
    update: (state, action) => {
      state.access_token = action?.payload?.access_token;
      state.username = action?.payload?.username;
      state.image = action?.payload?.image;
      state.role = action?.payload?.role;
      state.isAuthenticated = action?.payload?.isAuthenticated;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = userSlice.actions;


export default userSlice.reducer;
