import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { BiSearchAlt } from "react-icons/bi";
import "./UserCmsLog.scss";
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import { getFindUserId, getFindUserLog, getLogUser } from "../../../../services/adminService";
import { useSelector } from "react-redux";

const UserCmsLog = () => {
    const [key, setKey] = useState("");
    const [listLog, setListLog] = useState([]);
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getLogs();
    }, []);

    const getLogs = async () => {
        let data = await getLogUser();
        if (data && +data.EC === 0) {
            setListLog(data.DT);
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
            setListLog([])
        }
    }

    const handleFindClick = async () => {
        if (key === "") {
            getLogs();
            return;
        }
        let data = await getFindUserLog(key);
        if (data && +data.EC === 0) {
            setListLog(data.DT);
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
            setListLog([])
        }
        setKey("");

    }


    const handleIdClick = async (userId) => {
        if (userId === "") {
            getLogs();
            return;
        }
        let data = await getFindUserId(userId);
        if (data && +data.EC === 0) {
            setListLog(data.DT);
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
            setListLog([])
        }
        setKey("");

    }

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            handleFindClick();
        }
    }

    return (
        <div className="user-cms-log-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Log Activity</div>
            <div className={+nightMode === 0 ? "input-search" : "input-search input-search-night"}>
                <Form.Control
                    className="input-key"
                    type="text"
                    value={key}
                    autoFocus
                    onChange={(event) => {
                        setKey(event.target.value);
                    }}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
                <BiSearchAlt className="search-icon" onClick={() => handleFindClick()} />
            </div>
            <div className="table">
                <Table bordered>
                    <thead>
                        <tr>
                            <th>User Id</th>
                            <th>Tên</th>
                            <th>Quyền</th>
                            <th>Hành động</th>
                            <th>Thông tin</th>
                            <th>Ngày tạo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listLog && listLog.length > 0 &&
                            listLog.map((item, index) => {
                                return (
                                    <tr tr key={`table-cms-log-user-${index}`}>
                                        <td>
                                            <button className="btn btn-link" onClick={() => handleIdClick(item.user_id)}> {item.user_id}</button>
                                        </td>
                                        <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.username}</td>
                                        <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.role === 1 ? "Admin" : "User"}</td>
                                        <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.action}</td>
                                        <td>
                                            <textarea
                                                className={+nightMode === 0 ? "day-textarea-mode" : "night-textarea-mode"}
                                                readOnly
                                                rows="2" cols="60"
                                                defaultValue={item.message}
                                            />
                                        </td>
                                        <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{new Date(item.date_created).toLocaleString()}</td>
                                    </tr>
                                );
                            })}
                        {listLog && listLog.length === 0 && (
                            <tr>
                                <td colSpan={"15"}><div className="title-nodata">Không có dữ liệu</div></td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default UserCmsLog;