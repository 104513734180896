import "./SystemMail.scss";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import RenderBonusType from "../../../UserInfo/Content/SendUserMail/RenderBonusType";
import { VscArrowRight } from "react-icons/vsc";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getFormatBonus, postSystemMail } from "../../../../services/apiCmsService";
import { BonusType } from "../../../../enum/BonusType";
import { IsJsonString } from "../../../../utils/Validate";
import { useSelector } from "react-redux";

const SystemMail = (props) => {
    const [serverId, setServerId] = useState(0);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [bonus, setBonus] = useState("");
    const [type, setType] = useState("Gold");
    const [number, setNumber] = useState(1);
    const [expire, setExpire] = useState(-1);
    const [key, setKey] = useState(1);
    const [fromVip, setFromVip] = useState(0);
    const [toVip, setToVip] = useState(10);
    const [mailType, setMailType] = useState(1);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const nightMode = useSelector((state) => state?.night?.mode);
    const [itemId, setItemId] = useState(1);
    const [lock, setLock] = useState(1);
    const [listEquip, setListEquip] = useState([]);
    const [listItem, setListItem] = useState([]);
    const [listWeapon, setListWeapon] = useState([]);
    const [listItemSeed, setItemSeed] = useState([]);
    const [listItemFarm, setItemFarm] = useState([]);
    const [listItemTool, setItemTool] = useState([]);
    const [listPiece, setListPiece] = useState([]);
    // image




    useEffect(() => {
        defaultBonus();
    }, []);

    const defaultBonus = async () => {
        let data = await getFormatBonus();
        if (data && +data.EC === 0) {
            setListEquip(data.DT.ItemEquipment);
            setListItem(data.DT.Item);
            setListWeapon(data.DT.Weapon);
            return;
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
            setListEquip([]);
            setListItem([]);
            setListWeapon([]);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    };

    const handleBtnClear = () => {
        setBonus("");
        setNumber(1);
    };

    const handleBtnAddClick = () => {
        if (number <= 0 || number === undefined) {
            toast.warning("Số lượng phải lớn hơn 0");
            setNumber(1);
            return;
        }
        if (expire < -1 || expire === 0) {
            toast.warning("Expire phải lớn hơn 0 hoặc bằng -1");
            setExpire(-1);
            return;
        }
        let id = BonusType(type);
        if (id === 1 || id === 2 || id === 9 || id === 4) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${+number}`);
            setNumber(1);
        }
        if (id === 3) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key},${+expire},${+lock}`);
            setNumber(1);
            setExpire(-1);
        }
        if (id === 6) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key},${+number}`);
            setNumber(1);
            setExpire(-1);
        }
        if (id === 7) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key}`);
        }
        if (id === 5) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${+number}`);
            setNumber(1);
        }
        if (id === 8 || id === 10 || id === 11) {
            toast.error("Bonus này đang phát triển!");
            setNumber(0);
        }
        if (id === 12 || id === 14) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key},${itemId},${+number}`);
            setNumber(1);
        }
        if (id === 13) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key},${+number}`);
            setNumber(1);
            setExpire(-1);
        }
    };

    const handleBtnSendClick = async () => {
        if (toVip <= 0) {
            toast.warning("Đến vip phải lớn hơn 0");
            return;
        }
        if (fromVip < 0) {
            toast.warning("Từ vip phải lớn hơn 0");
            return;
        }
        if (+mailType !== 1 && +mailType !== 2) {
            toast.warning("Loại thư không hợp lệ");
            return;
        }
        if (+fromVip > +toVip) {
            toast.warning("VIP không hợp lệ!");
            return;
        }
        if (title === "") {
            toast.warning("Cần nhập Title");
            return;
        }
        if (!IsJsonString(`[${bonus}]`)) {
            toast.error("Bonus không hợp lệ!");
            return;
        }
        let data = await postSystemMail(serverId, title, content, bonus, fromVip, toVip, mailType, new Date(fromDate).getTime(), new Date(toDate).getTime());
        if (data && +data.EC === 0) {
            toast.success(data.EM);
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
        }
        setTitle("");
        setBonus("");
        setContent("");
        setNumber(1);
    };

    return (
        <div className="system-mail-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Gửi thư hệ thống</div>
            <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
                <div className="row1">
                    <div className="input-userid">

                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                    Bằng 0 sẽ gửi cho tất cả server !
                                </Tooltip>
                            }
                        >
                            <FloatingLabel controlId="floatingUserId" label="Server Id (*)">
                                <Form.Control className="form-dark"
                                    type="number"
                                    value={serverId}
                                    onChange={(event) => setServerId(event.target.value)}
                                />
                            </FloatingLabel>
                        </OverlayTrigger>
                    </div>
                    <div className="input-vip">
                        <FloatingLabel controlId="floatingUserId" label="VIP">
                            <Form.Control className="form-dark"
                                type="number"
                                value={fromVip}
                                onChange={(event) => setFromVip(event.target.value)}
                            />
                        </FloatingLabel>
                    </div>
                    <div className="icon-next">
                        <VscArrowRight size={35} />
                    </div>
                    <div className="input-vip">
                        <FloatingLabel controlId="floatingUserId" label="VIP">
                            <Form.Control className="form-dark"
                                type="number"
                                value={toVip}
                                onChange={(event) => setToVip(event.target.value)}
                            />
                        </FloatingLabel>
                    </div>
                </div>

                <div className="input-title">
                    <FloatingLabel controlId="floatingTitle" label="Title">
                        <Form.Control className="form-dark"
                            type="text"
                            autoFocus
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </FloatingLabel>
                </div>
                <div className="input-content">
                    <FloatingLabel controlId="floatingContent" label="Content">
                        <Form.Control className="form-dark"
                            type="text"
                            as="textarea"
                            style={{ height: '100px' }}
                            value={content}
                            onChange={(event) => setContent(event.target.value)}
                        />
                    </FloatingLabel>
                </div>
                <div className="select-type">
                    <div className="form-content">
                        <Form.Select
                            className="select-content form-dark"
                            defaultValue={mailType}
                            onChange={(event) => {
                                setMailType(+event.target.value);
                            }}
                        >
                            <option value="1">Cho tất cả người chơi</option>
                            <option value="2">Theo khoảng thời gian</option>
                        </Form.Select>
                    </div>
                    {mailType === 2 &&
                        <div className="input-from-time">
                            <FloatingLabel controlId="floatingContent" label="Từ ngày">
                                <Form.Control className="form-dark"
                                    type="datetime-local"
                                    value={fromDate}
                                    onChange={(event) => setFromDate(event.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                    }
                    {mailType === 2 && <div className="input-from-time">
                        <FloatingLabel controlId="floatingContent" label="Đến ngày">
                            <Form.Control className="form-dark"
                                type="datetime-local"
                                value={toDate}
                                onChange={(event) => setToDate(event.target.value)}
                            />
                        </FloatingLabel>
                    </div>
                    }
                </div>
                <div className="bonus-content">
                    <FloatingLabel
                        controlId="floatingBonus"
                        label="Bonus"
                        className="bonus-input"
                    >
                        <Form.Control className="form-dark"
                            type="text"
                            value={`[${bonus}]`}
                            onChange={(event) => setBonus(event.target.value)}
                        />
                    </FloatingLabel>
                    <button
                        className="btn btn-danger btn-action "
                        onClick={handleBtnClear}
                    >
                        Clear
                    </button>
                </div>
                <div className="form-content">
                    <Form.Select
                        className="select-content form-dark"
                        defaultValue={type}
                        onChange={(event) => {
                            setType(event.target.value);
                            setNumber(1);
                        }}
                    >
                        <option value="Gold">Gold</option>
                        <option value="Gem">Gem</option>
                        <option value="ItemEquipment">Item Equipment</option>
                        <option value="UserExp">User Exp</option>
                        <option value="Hero">Hero</option>
                        <option value="Item">Item</option>
                        <option value="Weapon">Weapon</option>
                        <option value="Avatar">Avatar</option>
                        <option value="VipExp">Vip Exp</option>
                        <option value="Pet">Pet</option>
                        <option value="Skin">Skin</option>
                        <option value="Ruby">Ruby</option>
                    </Form.Select>
                    {RenderBonusType(
                        type,
                        number,
                        setNumber, expire, setExpire,
                        handleBtnAddClick,
                        key, setKey,
                        lock, setLock,
                        itemId,
                        setItemId,
                        nightMode
                    )}
                </div>
                <div className="bot-content">
                    <button
                        className={+nightMode === 0 ? "btn btn-info btn-send" :
                            "btn btn-info btn-send"}
                        onClick={handleBtnSendClick}
                    >
                        Gửi
                    </button>
                </div>
            </div>
        </div >
    );
};

export default SystemMail;
