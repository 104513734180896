import { useEffect, useState } from "react";
import { deletePack, getPacks, postEditPack, postPackCreate } from "../../../../services/apiCmsService";
import { ToastNoSuccess, ToastResponse } from "../../../../utils/Utils";
import { Form, Table } from "react-bootstrap";
import _ from 'lodash';
import ModalSave from "../Modal/ModalSave";
import ModalNewPack from "../Modal/ModalNewPack";
import { BsPlusSquare } from "react-icons/bs";
import { useSelector } from "react-redux";

import "./ListPack.scss";

const ListPack = () => {
    const [packs, setPacks] = useState([]);
    const [cache, setCahe] = useState([]);
    const [show, setShow] = useState();
    const [showModalCreate, setShowModalCreate] = useState();
    const [cur, setCur] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);


    useEffect(() => {
        getListPack();
    }, [])

    const getListPack = async () => {
        let data = await getPacks();
        if (data && +data.EC === 0) {
            setPacks(data.DT);
            setCahe(data.DT);
        }
        ToastNoSuccess(data);
    }

    const handleEditCell = (event, index, type) => {
        let clone = _.cloneDeep(packs);
        if (type === 1) {
            clone[index].name = event.target.value;
        } else {
            clone[index].file = event.target.value;
        }
        setPacks(clone);
    }

    const handleBtnSave = (pack) => {
        setCur(pack)
        setShow(true);
    }

    const handleBtnNew = () => {
        setShowModalCreate(true);
    }

    const handleCreatePack = async (id, name, file) => {
        let data = await postPackCreate(id, name, file);
        if (data && +data.EC === 0) {
            getListPack();
        }
        ToastResponse(data);
    }

    const sendSaveData = async () => {
        let ret = await postEditPack(cur.id, cur.name, cur.file);
        ToastResponse(ret);
        if (ret && ret.EC === 0) getListPack();
    }

    const handleBtnCancelClick = (index) => {
        let clone = _.cloneDeep(packs);
        clone[index].file = cache[index].file ? cache[index].file : "";
        clone[index].name = cache[index].name ? cache[index].name : "";
        setPacks(clone);
    }
    const handleDeletePack = async (id) => {
        let data = await deletePack(id);
        ToastResponse(data);

        if (data && +data.EC === 0) {
            getListPack();
        }
    }

    return (<div className="list-pack-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Pack List</div>
        <div className="btn-create" >
            <button className="btn btn-success" onClick={handleBtnNew}> {<BsPlusSquare />} NEW</button>
        </div>
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Id</th>
                        <th >Name</th>
                        <th>File</th>
                        <th style={{ width: '20%' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {packs && packs.length > 0 &&
                        packs.map((pack, index) => {
                            return (<tr key={`list-pack-${index}`}>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{pack.id}</td>
                                <td>
                                    <Form.Control
                                        className={+nightMode === 1 ? "form-input-dark" : ""}
                                        type="text"
                                        value={pack.name}
                                        onChange={(event) => handleEditCell(event, index, 1)}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        className={+nightMode === 1 ? "form-input-dark" : ""}
                                        type="text"
                                        value={pack.file}
                                        onChange={(event) => handleEditCell(event, index, 2)}
                                    />
                                </td>
                                <td>
                                    <div className="group-button">
                                        {(packs[index].name !== cache[index].name ||
                                            packs[index].file !== cache[index].file)
                                            && < button className="btn btn-success"
                                                onClick={() => handleBtnSave(pack)}
                                            >Lưu</button>}
                                        {(packs[index].name !== cache[index].name ||
                                            packs[index].file !== cache[index].file)
                                            && <button button className="btn btn-primary"
                                                onClick={() => handleBtnCancelClick(index)}
                                            >Hủy</button>}
                                        <button button className="btn btn-danger"
                                            onClick={() => handleDeletePack(packs[index].id)}
                                        >Xóa</button>
                                    </div>
                                </td>
                            </tr>)
                        })
                    }
                </tbody>
            </Table>
            <ModalSave
                show={show}
                setShow={setShow}
                send={sendSaveData}
                title="Cảnh báo"
                desc="Bạn có chắc chắn muốn thay đổi dữ liệu không!"
            />
            <ModalNewPack
                show={showModalCreate}
                setShow={setShowModalCreate}
                funcCreate={handleCreatePack}
            />
        </div>
    </div >)
}

export default ListPack;