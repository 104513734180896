import "./Config.scss"
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useState, useEffect } from "react";
import { getAllConfigLang, postSaveConfigLang }
    from "../../../services/apiCmsService"
import Form from 'react-bootstrap/Form';
import { BiSearchAlt } from "react-icons/bi";
import _ from 'lodash';
import { ToastNoSuccess, ToastResponse, coppyText } from "../../../utils/Utils";
import ModalCreateConfigLang from "../Modal/ModalCreateConfigLang";
import { useSelector } from "react-redux";

const ConfigLang = () => {
    const [search, setSearch] = useState("");
    const [config, setConfig] = useState([]);
    const [disabledEdit, setDisableEdit] = useState(true);
    const [showSave, setShowSave] = useState(false);
    const [curIndex, setCurIndex] = useState(0);
    const [cacheConfig, setCacheConfig] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        requestConfig();
    }, []);

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            handleFindClick();
        }
    }
    const handleFindClick = async () => {
        if (search === "") {
            setConfig(cacheConfig);
            return
        }
        setCurIndex(0);
    }
    const handleEditConfig = (event, index) => {
        let oldData = config[index].vi;
        if (event.target.value !== oldData) {
            setShowSave(true)
        }
        let cfgClone = _.cloneDeep(config);
        cfgClone[index].vi = event.target.value;
        setConfig(cfgClone);
    }

    const handleBtnSaveConfig = async () => {
        let data = await postSaveConfigLang(config[curIndex].k, config[curIndex].vi);
        ToastResponse(data);
        requestConfig();
    }

    const handleSelectSave = async (index) => {
        setCurIndex(index);
        await coppyText(cacheConfig[index].k);
    }

    const handleClickBtnCreate = () => {
        setShowModal(true);
    }

    const handleBtnReset = () => {
        setDisableEdit(true);
        setShowSave(false);
        setConfig(cacheConfig);
    }

    const requestConfig = async () => {
        let data = await getAllConfigLang();
        if (data && data.EC === 0) {
            setConfig(data.DT);
            setCacheConfig(data.DT);
            setDisableEdit(true);
            setShowSave(false);
            return;
        }
        ToastNoSuccess(data);
    }

    return (
        <div className="config-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Config Language</div>
            <div className={+nightMode === 0 ? "input-search" : "input-search input-search-night"}>
                <Form.Control
                    className="input-key"
                    type="text"
                    value={search}
                    autoFocus
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
                <BiSearchAlt className="search-icon" onClick={() => handleFindClick()} />
            </div>

            <div className={+nightMode === 0 ? "body-content" : "body-content-night"}>
                <Tab.Container defaultActiveKey={`cfg-lang-${curIndex}`}
                    onSelect={() => {
                        setDisableEdit(true);
                        setShowSave(false);
                        setConfig(cacheConfig);
                    }}
                >
                    <div className="left-content">
                        <Nav className="flex-column">
                            {config && config.length > 0 && config.filter((item) => {
                                return search.toLowerCase() === "" ? item : item.k.toString().toLowerCase().includes(search);
                            }).map((cfg, index) => {
                                return (
                                    <Nav.Item >
                                        <Nav.Link eventKey={`cfg-lang-${index}`} onClick={() => handleSelectSave(index)}>{cfg.k}</Nav.Link>
                                    </Nav.Item>)
                            })
                            }
                        </Nav>
                    </div>
                    <div className="right-content">
                        <Tab.Content>
                            {config && config.length > 0 && config.filter((item) => {
                                return search.toLowerCase() === "" ? item : item.k.toString().toLowerCase().includes(search);
                            }).map((cfg, index) => {
                                return (
                                    <Tab.Pane eventKey={`cfg-lang-${index}`}>
                                        <Form.Control
                                            className="input-config"
                                            as="textarea"
                                            rows={18}
                                            value={cfg.vi}
                                            onChange={(event) => handleEditConfig(event, index)}
                                            disabled={disabledEdit}
                                        />
                                        {disabledEdit ? <div className="disable-edit">
                                        </div> : <></>}
                                    </Tab.Pane>
                                )
                            })
                            }

                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>

            {config.length > 0 &&
                <div className="bot-content">
                    <button className="btn btn-success btn-action" onClick={() => handleClickBtnCreate()}>New</button>
                    <button className="btn btn-warning btn-action" onClick={() => setDisableEdit(false)}>Edit</button>
                    {showSave &&
                        <button className="btn btn-success btn-action" onClick={handleBtnSaveConfig}>Save</button>
                    }
                    {showSave &&
                        <button className="btn btn-primary btn-action" onClick={handleBtnReset}>Reset</button>
                    }
                </div>}
            <ModalCreateConfigLang
                show={showModal}
                setShow={setShowModal}
                requestConfig={requestConfig}
            />
        </div>
    )
}

export default ConfigLang;