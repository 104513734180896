import axios from "../utils/axiosCustomize";

const getUserInfo = async (userId) => {
    return await axios.get(`api/user-info/?userId=${userId}`);
}

const getUserData = async (userId) => {
    return await axios.get(`api/user-data/?userId=${userId}`);
}
const getUserLogs = async (userId, time) => {
    return await axios.get(`api/user-logs/?userId=${userId}&time=${time}`);
}
const getUserHero = async (userId) => {
    return await axios.get(`api/user-hero/?userId=${userId}`);
}
const getUserItem = async (userId) => {
    return await axios.get(`api/user-item/?userId=${userId}`);
}
const getUserItemEquip = async (userId) => {
    return await axios.get(`api/user-item-equip/?userId=${userId}`);
}
const getUserMail = async (userId) => {
    return await axios.get(`api/user-mail/?userId=${userId}`);
}
const getUserWeapon = async (userId) => {
    return await axios.get(`api/user-weapon/?userId=${userId}`);
}
const getResWeaponName = async () => {
    return await axios.get(`api/res-weapon-name`);
}
const getItemEquipName = async () => {
    return await axios.get(`api/res-item-equip-name`);
}
const getItemName = async () => {
    return await axios.get(`api/res-item-name`);
}
const getHeroName = async () => {
    return await axios.get(`api/res-hero-name`);
}


export {
    getUserInfo,
    getUserData,
    getUserLogs,
    getUserHero,
    getUserItem,
    getUserItemEquip,
    getUserWeapon,
    getUserMail,
    getResWeaponName,
    getItemEquipName,
    getItemName,
    getHeroName,
}