import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import Language from "./Language";
import Setting from "./Setting";
import "../Auth/Auth.scss";

const Header = (props) => { // main
  let { showSetting } = props; //nightMode 0 - 1
  //  let { showSetting, user, nightMode, setNightMode } = props; //nightMode 0 - 1
  return (
    <div className="header-container">
      <Navbar expand="lg" className='nav-header-container'>
        <Container>
          <NavLink to={"/"} className="navbar-brand">
            <img
              alt="aord"
              src="/logo192.png"
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
            <span className="aord-title">AORD</span>
          </NavLink>
          <Navbar.Brand className="header-content d-flex flex-row">
            <Language />
            {/* {showSetting ? Setting({ user, nightMode, setNightMode }) : <></>} */}
            {showSetting ? <Setting /> : <></>}

          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
