import "react-pro-sidebar/dist/css/styles.css";

import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import "../SideBar.scss";
import { AiFillAlipaySquare, AiFillTool, AiTwotoneStar, AiTwotoneSetting } from "react-icons/ai";
import { RiChatOffFill } from "react-icons/ri";
import Footer from "../Header/Footer";



const ConfigSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;
    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "CONFIGS" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem icon={<AiTwotoneStar />}                        >
                            Config<Link to="/config/prop" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<AiTwotoneSetting />}                        >
                            Config Dev<Link to="/config/dev" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<AiFillTool />}                        >
                            Config Help<Link to="/config/help" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<AiFillAlipaySquare />}                        >
                            Config Language<Link to="/config/lang" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<RiChatOffFill />}                        >
                            Chat Invalid<Link to="/config/chat" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
}

export default ConfigSideBar;