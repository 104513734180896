import { useState } from "react";
import { Form, Modal } from "react-bootstrap";


const ModalCreateTeleport = (props) => {
    const { show, setShow, funcCreate } = props;
    const [name, setName] = useState("");
    const [type, setType] = useState(1);
    const [map, setMap] = useState(0);
    const [mapId, setMapId] = useState(0);
    const [next, setNext] = useState(0);
    const [playerPos, setPlayerPos] = useState("[]");
    const [pos, setPos] = useState("[]");
    const [enable, setEnable] = useState(1);

    const handleClose = () => {
        setName("");
        setType(1);
        setMap(0);
        setMapId(0);
        setNext(0);
        setEnable(1);
        setPlayerPos("[]");
        setPos("[]");
        setShow(false);
    }
    const handleCreate = () => {
        funcCreate(name, type, map, mapId, next, playerPos, pos, enable);
        handleClose();
    }

    return (<div className="modal-create-teleport">
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >
                <Modal.Title >Tạo mới TELEPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3 d-flex flex-column pt-3">
                        <Form.Group className="d-flex align-items-center">
                            <Form.Label style={{ width: "150px" }}>Trạng thái</Form.Label>
                            <Form.Select className="select-enable"
                                value={enable}
                                onChange={(event) => setEnable(+event.target.value)}
                            >
                                <option value={1}>Mở</option>
                                <option value={0}>Đóng</option>
                            </Form.Select>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-1">
                        <Form.Label style={{ width: "150px" }}>Name</Form.Label>
                        <Form.Control
                            className="input-name"
                            type="text"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex flex-column pt-3">
                        <Form.Group className="d-flex align-items-center">
                            <Form.Label style={{ width: "150px" }}>Màu</Form.Label>
                            <Form.Select className="select-enable"
                                value={type}
                                onChange={(event) => setType(+event.target.value)}
                            >
                                <option value={0}>Green</option>
                                <option value={1}>Blue</option>
                                <option value={2}>Red</option>
                            </Form.Select>
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Map</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={map}
                            onChange={(event) => setMap(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Map ID</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={mapId}
                            onChange={(event) => setMapId(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Next Id</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={next}
                            onChange={(event) => setNext(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Vị trí player</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={playerPos}
                            onChange={(event) => setPlayerPos(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center pt-3">
                        <Form.Label style={{ width: "150px" }}>Vị trí teleport</Form.Label>
                        <Form.Control
                            className="input-map"
                            type="text"
                            value={pos}
                            onChange={(event) => setPos(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={handleCreate} style={{ width: "80px" }}>
                    Tạo
                </button>
                <button className="btn btn-danger ms-3" onClick={handleClose} style={{ width: "80px" }}>
                    Hủy
                </button>
            </Modal.Footer>
        </Modal >

    </div>)
}

export default ModalCreateTeleport;