import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useState } from "react";
import { getResetServer } from "../../../services/apiCmsService";
import "./Modal.scss";

const ModalReset = (props) => {
  const { show, setShow, serverName, port } = props;
  const [pass, setPass] = useState("");



  const handleClose = () => {
    setPass("");
    setShow(false);
  };

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      handleReset();
    }
  }

  const handleReset = async () => {
    if (pass.toLowerCase() !== "aord") {
      toast.error("Sai mật khẩu");
      setPass("");
      return;
    }
    let data = await getResetServer(port);
    if (data && +data.EC === 0) {
      toast.success(data.EM);
    }
    if (data && +data.EC !== 0) {
      toast.error(data.EM);
    }
    handleClose();
  };

  return (
    <div className="modal-reset-container">
      <Modal
        show={show}
        //   {...props}
        onHide={handleClose}
        size="m"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Thông báo!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-password">
            <FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control
                type="password"
                placeholder="Password"
                autoFocus
                value={pass}
                onChange={(event) => setPass(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event)}
              />
            </FloatingLabel>
          </div>
          <div className="title">
            Bạn có muốn reset server <b>{serverName}</b> không!
          </div>
        </Modal.Body>
        <div className="modal-footer justify-content-center">
          <button className="btn btn-warning" onClick={handleReset}>
            Đồng ý
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalReset;
