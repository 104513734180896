import { SidebarFooter } from "react-pro-sidebar";



const Footer = (props) => {
    let { collapsed } = props;

    return (<SidebarFooter style={{ textAlign: "center", userSelect: "none" }}>
        <div className="sidebar-footer">{!collapsed ? "Copyright© 2022 AORD" : "AORD"}</div>
    </SidebarFooter>)
}

export default Footer;