import "react-pro-sidebar/dist/css/styles.css";

import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import { FaPenAlt } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import "../SideBar.scss"
import Footer from "../Header/Footer";

const MonitorSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;
    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "MONITOR" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FaPenAlt />}
                        >
                            Slider Message
                            <Link to="/monitor/slider-message" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<RiMessage2Fill />} className="item-content">
                            Toast Message
                            <Link to="/monitor/toast-message" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    );
}

export default MonitorSideBar;