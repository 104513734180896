import _ from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { ItemEquipName } from "../../../../constant";
import { getItemEquipName, getUserItemEquip } from "../../../../services/userService";
import "./UserItemEquip.scss";
import { useSelector } from "react-redux";

const UserItemEquip = () => {
    const [items, setItem] = useState([]);
    const nightMode = useSelector((state) => state?.night?.mode);


    let userId = localStorage.getItem('UserIdInfo');
    useEffect(() => {
        getInfo();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getInfo = async () => {
        if (_.isEmpty(ItemEquipName)) {
            let itemName = await getItemEquipName();
            if (itemName && itemName.EC === 0) {
                for (var i = 0; i < itemName.DT.length; i++) {
                    ItemEquipName[itemName.DT[i].id] = itemName.DT[i].name;
                }
            }
        }

        let data = await getUserItemEquip(userId);
        if (data && +data.EC === 0) {
            setItem(data.DT);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    }
    return (<div className="user-item-equip-container">
        <div className="table">
            <Table bordered  >
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>ID</th>
                        <th>Key</th>
                        <th>Tên</th>
                        <th>Cấp độ</th>
                        <th>Point</th>
                        <th>HSD</th>
                        <th>Ngày nhận</th>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length > 0 &&
                        items.map((item, index) => {
                            return (
                                <tr key={`item-equip-${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}><img width={60} height={60} src={`/DB/item_equip/${item.item_id}.png`} alt={item.id}></img></td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.id}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.item_id}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{ItemEquipName[item.item_id]}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.level}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.point}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{+item.expire === -1 ? "Vĩnh viễn" : new Date(+item.expire).toLocaleString()}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(item.date_created).toLocaleString()}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            {(!items || items.length === 0) &&
                <div className="no-data">Không có dữ liệu</div>
            }
        </div>
    </div>)
}
export default UserItemEquip;