import "react-pro-sidebar/dist/css/styles.css";
import { FaUserCircle, FaClipboardList } from "react-icons/fa";
import '../SideBar.scss'

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent
} from "react-pro-sidebar";

import { MdDashboard } from "react-icons/md";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import Footer from "../Header/Footer";

const AdminSideBar = (props) => {
  const { collapsed, toggled, handleToggleSidebar, sidebarBg } = props;
  return (
    <div className="sidebar-container">
      <ProSidebar
        image={sidebarBg}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        {Logo({ collapsed: collapsed, title: "ADMIN" })}
        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem
              icon={<MdDashboard />}
            // suffix={<span className="badge red">New</span>}
            >
              Dashboard
              <Link to="/admins/dashboard" />
            </MenuItem>
          </Menu>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaUserCircle />}>
              Quản lý Users
              <Link to="/admins/manage-users" />
            </MenuItem>
          </Menu>
          <Menu iconShape="circle">
            <MenuItem
              icon={<FaClipboardList />}>
              User CMS Log
              <Link to="/admins/cms-log" />
            </MenuItem>
          </Menu>
        </SidebarContent>
        {Footer({ collapsed: collapsed })}
      </ProSidebar>
    </div>
  );
};

export default AdminSideBar;
