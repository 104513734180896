import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { getMap, postEditMap, postMapCreate } from "../../../../services/apiCmsService";
import "./Map.scss"
import { Form, Table } from "react-bootstrap";
import _ from "lodash";
import { ToastResponse } from "../../../../utils/Utils";
import { BsPlusSquare } from "react-icons/bs";
import ModalCreateMap from "../ModalCreateMap";
import { IsJsonString } from "../../../../utils/Validate";
import { useSelector } from "react-redux";


const Map = () => {
    const [maps, setMaps] = useState([]);
    const [mapCache, setMapCache] = useState([]);
    const [showModalCreate, setShowModalCreate] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);


    useEffect(() => {
        getMapData();
    }, [])

    const getMapData = async () => {
        let data = await getMap();
        if (data && +data.EC === 0) {
            setMaps(data.DT);
            setMapCache(data.DT);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    }
    const handleCreateMap = async (enable, name, map, camera) => {
        let id = mapCache[mapCache.length - 1].id + 1;
        let data = await postMapCreate(id, enable, name, map, camera);
        if (data && +data.EC === 0) {
            getMapData();
        }
        ToastResponse(data);
    }
    const handleEditMap = (event, index) => {
        let mapClone = _.cloneDeep(maps);
        mapClone[index].map = event.target.value;
        setMaps(mapClone);
    }

    const handleEditName = (event, index) => {
        let mapClone = _.cloneDeep(maps);
        mapClone[index].name = event.target.value;
        setMaps(mapClone);
    }

    const handleEditCamera = (event, index) => {
        let mapClone = _.cloneDeep(maps);
        mapClone[index].camera = event.target.value;
        setMaps(mapClone);
    }
    const handleBtnNew = () => {
        setShowModalCreate(true);
    }

    const handleBtnUpdateClick = async (data) => {
        let map = data.map;
        let checkJson = IsJsonString(map);
        if (!checkJson) {
            toast.error("JSON không hợp lệ!")
            return;
        }
        let ret = await postEditMap(data.id, data.enable, data.name, data.map, data.camera);
        ToastResponse(ret);
        if (ret && ret.EC === 0) {
            return getMapData();
        }
    }
    const handleBtnResetClick = (index) => {
        let mapClone = _.cloneDeep(maps);
        mapClone[index].map = mapCache[index].map ? mapCache[index].map : "";
        mapClone[index].name = mapCache[index].name ? mapCache[index].name : "";
        mapClone[index].camera = mapCache[index].camera ? mapCache[index].camera : "";
        setMaps(mapClone);
    }

    return (
        <div className="map-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >MAP</div>
            <div className="btn-create" >
                <button className="btn btn-success" onClick={handleBtnNew}> {<BsPlusSquare />} NEW</button>
            </div>
            <div className="table">
                <Table bordered >
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>Id</th>
                            <th style={{ width: '10%' }}>Trạng thái</th>
                            <th style={{ width: '20%' }}>Tên</th>
                            <th >Dữ liệu</th>
                            <th style={{ width: '15%' }}>Camera</th>
                            <th style={{ width: '10%' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {maps && maps.length > 0 &&
                            maps.map((map, index) => {
                                return (
                                    <tr key={`map-${index}`}>
                                        <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{map.id}</td>
                                        <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"} >{map.enable === 1 ? "Mở" : "Đóng"}</td>
                                        <td>
                                            <Form.Control
                                                className={+nightMode === 1 ? "form-input-dark" : ""}
                                                type="text"
                                                value={map.name}
                                                onChange={(event) => handleEditName(event, index)}
                                            />
                                        </td>
                                        <td>
                                            <textarea rows="3" cols="20"
                                                value={map.map}
                                                className={+nightMode === 1 ? "form-input-dark text-area-aord" : "form-input-light text-area-aord"}
                                                onChange={(event) => handleEditMap(event, index)}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                className={+nightMode === 1 ? "form-input-dark" : ""}
                                                type="text"
                                                value={map.camera}
                                                onChange={(event) => handleEditCamera(event, index)}
                                            />
                                        </td>
                                        <td>
                                            <div className="group-button">
                                                {(mapCache[index].map !== maps[index].map ||
                                                    mapCache[index].name !== maps[index].name ||
                                                    mapCache[index].camera !== maps[index].camera)
                                                    && <button className="btn btn-action btn-success"
                                                        onClick={() => handleBtnUpdateClick(map)}>
                                                        Cập nhật
                                                    </button>}
                                                {(mapCache[index].map !== maps[index].map ||
                                                    mapCache[index].name !== maps[index].name ||
                                                    mapCache[index].camera !== maps[index].camera)
                                                    && <button className="btn btn-action btn-warning"
                                                        onClick={() => handleBtnResetClick(index)}>
                                                        Hủy
                                                    </button>}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}

                    </tbody>
                </Table>
                <ModalCreateMap
                    show={showModalCreate}
                    setShow={setShowModalCreate}
                    funcCreate={handleCreateMap}
                />
            </div>
        </div>
    )
}

export default Map;  