import { useState } from "react";
import { Form, Modal } from "react-bootstrap";


const ModalNewPack = (props) => {
    const { show, setShow, funcCreate } = props;
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [file, setFile] = useState();

    const handleClose = () => {
        setId();
        setName("");
        setFile("");
        setShow(false);
    }

    const handleCreate = () => {
        funcCreate(id, name, file);
        handleClose();
    }

    return (<div className="modal-new-pack-container">
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tạo file pack mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>ID Pack</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Id pack"
                            value={id}
                            onChange={(event) => setId(+event.target.value)}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tên hiển thị khi tải xuống"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>File Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tên của file pack"
                            value={file}
                            onChange={(event) => setFile(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={handleCreate} style={{ width: "80px" }}>
                    Tạo
                </button>
                <button className="btn btn-danger ms-3" onClick={handleClose} style={{ width: "80px" }}>
                    Hủy
                </button>
            </Modal.Footer>
        </Modal>
    </div>)
}

export default ModalNewPack;