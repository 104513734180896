import { Badge, Form, Table } from "react-bootstrap";
import "./Top.scss";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useState } from "react";
import { ToastNoSuccess, formatNumberDot } from "../../../utils/Utils";
import { getTopUser } from "../../../services/topService";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";


const TopUser = () => {
    const [sv, setSv] = useState(-1);
    const [num, setNum] = useState(10);
    const [type, setType] = useState("gold");
    const [users, setUsers] = useState([]);
    const nightMode = useSelector((state) => state?.night?.mode);


    useEffect(() => {
        handleGetData();
    }, [])

    const handleGetData = async () => {
        if (num < 0 || sv < -3 || (type !== "gold" && type !== "gem" && type !== "power" && type !== "level")) {
            toast.error("Dữ liệu không hợp lệ!");
            return;
        }
        if (num > 200) {
            toast.error("Num không được lớn hơn 200");
            return;
        }
        let data = await getTopUser(sv, type, num);
        ToastNoSuccess(data);
        if (data && data.EC === 0) {
            setUsers(data.DT);
        } else setUsers([]);
    }

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            handleGetData();
        }
    };


    return (<div className="top-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode "} >Top {type} User</div>
        <div className="note-content">
            <Badge bg="danger">Lưu ý: </Badge>
            <div className={+nightMode === 1 ? "text-dark-aord title-note" : "text-light-aord title-note"}>Server ID bé hơn 0 là sv test</div>
        </div>
        <div className="input-content">
            <Form.Select
                className={+nightMode === 1 ? "form-input-dark select-content" : "form-input-light select-content"}
                value={type}
                onChange={(event) => {
                    setType(event.target.value);
                    setUsers([]);
                    handleGetData();
                }}
            >
                <option value="gold">Gold</option>
                <option value="gem">Gem</option>
                <option value="power">Power</option>
                <option value="level">Level</option>
            </Form.Select>

            <FloatingLabel className={+nightMode === 1 ? "text-dark-aord select-content" : "text-light-aord select-content"} label="Server" >
                <Form.Control
                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                    type="number"
                    min={-3}
                    value={sv}
                    onChange={(event) => setSv(+event.target.value)}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
            </FloatingLabel>
            <FloatingLabel className={+nightMode === 1 ? "text-dark-aord select-content" : "text-light-aord select-content"} label="Number">
                <Form.Control
                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                    type="number"
                    max={200}
                    value={num}
                    onChange={(event) => setNum(+event.target.value)}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
            </FloatingLabel>
            <button
                className="btn btn-success"
                onClick={handleGetData}
            >Get</button>
        </div>
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th style={{ width: "5%" }}>TOP</th>
                        <th style={{ width: "10%" }}>ID</th>
                        <th>Name</th>
                        <th>Clan</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Gold</th>
                        <th>Gem</th>
                        <th>Vip</th>
                        <th>Power</th>
                        <th>Last Login</th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.length > 0 && users.map((u, index) => {
                        return (<tr key={`top-user-${index}`}>
                            <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}><b className="notice">{index + 1}</b></td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{u.id}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{u.name}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{u.clan}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{type === "level" ? <b className="notice"> {u.level}</b> : u.level}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{u.rank}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{type === "gold" ? <b className="notice"> {formatNumberDot(u.gold)}</b> : formatNumberDot(u.gold)}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{type === "gem" ? <b className="notice"> {formatNumberDot(u.gem)}</b> : formatNumberDot(u.gem)}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{u.vip}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{type === "power" ? <b className="notice"> {formatNumberDot(u.power)}</b> : formatNumberDot(u.power)}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(u.last_login).toLocaleDateString()}</td>
                        </tr>)

                    })}
                </tbody>
            </Table>
        </div>
    </div >)
}

export default TopUser;