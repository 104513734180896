import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getUserMail } from "../../../../services/userService";
import "./UserMail.scss";
import BonusImage from "../../../Custom/BonusImage";
import { useDispatch, useSelector } from "react-redux";
import { ToastNoSuccess } from "../../../../utils/Utils";
import { getFormatBonus } from "../../../../services/apiCmsService";
import { update } from "../../../../redux/slices/dbSlice";
import { MONSTER_PIECE, PET_PIECE, WEAPON_PIECE } from "../../../../enum/PieceType";


const UserMail = () => {
    const [mails, setMail] = useState([]);
    const userId = localStorage.getItem('UserIdInfo');
    const nightMode = useSelector((state) => state?.night?.mode);
    const dispatch = useDispatch();
    const hasValue = useSelector((state) => state?.dbItem?.check);
    const { ListEquip, ListItem, ListWeapon, ListItemSeed, ListItemFarm, ListItemTool, ListItemPiece, ListItemFood } = useSelector((state) => state?.dbItem);
    const WeaponPiece = [], MonsterPiece = [], PetPiece = [];


    useEffect(() => {
        getInfo();
        defaultBonus();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const defaultBonus = async () => {
        if (!hasValue) {
            let data = await getFormatBonus();
            if (data && +data.EC === 0) {
                dispatch(update(data.DT));
                return;
            }
            ToastNoSuccess(data)
        }
        for (let i = 0; i < ListItemPiece.length; i++) {
            const piece = ListItemPiece[i];
            if (piece.type === WEAPON_PIECE) {
                WeaponPiece.push(piece);
            } else if (piece.type === MONSTER_PIECE) {
                MonsterPiece.push(piece);
            } else if (piece.type === PET_PIECE) {
                PetPiece.push(piece);
            }
        }
    };

    const getInfo = async () => {
        let data = await getUserMail(userId);
        if (data && +data.EC === 0) {
            setMail(data.DT);
            return;
        }
        ToastNoSuccess(data);
    }

    return (<div className="user-mail2-container">
        <div className="table">
            <Table bordered   >
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Id</th>
                        <th style={{ width: '10%' }}>Người gửi</th>
                        <th style={{ width: '10%' }}>Tiêu đề</th>
                        <th style={{ width: '20%' }}>Nội dung</th>
                        <th style={{ width: '20%' }}>Bonus</th>
                        <th style={{ width: '5%' }}>Trạng thái</th>
                        <th style={{ width: '10%' }}>Ngày gửi</th>
                    </tr>
                </thead>
                <tbody>
                    {mails && mails.length > 0 &&
                        mails.map((item, index) => {
                            return (
                                <tr key={`user-mail-${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.id}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.sender_id === 0 ? "Hệ thống" : `${item.sender_id}`}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.title}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.message}</td>
                                    <td className="bonus">{BonusImage(item.bonus, 0.6, nightMode, ListEquip, ListItem, ListWeapon, ListItemSeed, ListItemFarm, ListItemTool, ListItemPiece, ListItemFood)}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.receive === 0 ? "Chưa nhận" : item.receive === 1 ? "Đã nhận" : "Đã xóa"}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(item.date_created).toLocaleString()}</td>
                                </tr>
                            )
                        })}
                    {(!mails || mails.length === 0) && (
                        <tr>
                            <td colSpan={"7"}><div className="no-data">Không có dữ liệu</div></td>
                        </tr>)}
                </tbody>
            </Table>
        </div>
    </div >)
}
export default UserMail;