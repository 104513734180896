import { configureStore } from "@reduxjs/toolkit";
import userReducer from './slices/userSlice';
import dbReducer from './slices/dbSlice';
import nightModeReducer from "./slices/nightModeSlice";

export const store = configureStore({
    reducer: {
        night: nightModeReducer,
        user: userReducer,
        dbItem: dbReducer,
    }
});