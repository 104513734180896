import ModalCreateUser from "./ModalCreateUser";
import "./ManageUser.scss";
import { FcPlus } from "react-icons/fc";
import TableUserPaninate from "./TableUserPaninate";
import { useEffect, useState } from "react";
import { getUserWithPaginate } from "../../../../services/adminService";
import ModalUpdateUser from "./ModalUpdateUser";
import ModalDeleteUser from "./ModalDeleteUser";
import { useSelector } from "react-redux";

const ManageUser = (props) => {
  const LIMIT_USER = 10;
  const [showModalCreateUser, setShowModalCreateUser] = useState(false);
  const [showModalUpdateUser, setShowModalUpdateUser] = useState(false);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
  const [listUser, setListUser] = useState();
  const [numPage, setNumPage] = useState();
  const [dataUser, setDataUser] = useState({});
  const [curPage, setCurPage] = useState({});
  const nightMode = useSelector((state) => state?.night?.mode);

  useEffect(() => {
    fetchListUserPaginate(1);
  }, []);

  const fetchListUserPaginate = async (page) => {
    let res = await getUserWithPaginate(page, LIMIT_USER);
    if (res.EC === 0) {
      setListUser(res.DT.users);
      setNumPage(res.DT.totalPages);
      setCurPage(page);
    }
  };
  const fetchListUserPaginateData = async () => {
    let res = await getUserWithPaginate(curPage, LIMIT_USER);
    if (res.EC === 0) {
      setListUser(res.DT.users);
      setNumPage(res.DT.totalPages);
    }
  };

  const handleClickBtnUpdate = (user) => {
    setShowModalUpdateUser(true);
    setDataUser(user);
  };
  const handleClickBtnDelete = (user) => {
    setShowModalDeleteUser(true);
    setDataUser(user);
  };

  const resetUpdateData = () => {
    setDataUser({});
  };

  return (
    <div className="manage-user-container">
      <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Quản lí user</div>
      {/* <div className="btn-add-new">
        <button
          className="btn btn-success"
          onClick={() => setShowModalCreateUser(true)}
        >
          <FcPlus />
          Tạo mới người dùng
        </button>
      </div> */}

      <div className="table-users-container">
        <TableUserPaninate
          listUser={listUser}
          nightMode={nightMode}
          handleClickBtnUpdate={handleClickBtnUpdate}
          handleClickBtnDelete={handleClickBtnDelete}
          fetchListUserPaginate={fetchListUserPaginate}
          numPage={numPage}
        />
      </div>
      <ModalCreateUser
        show={showModalCreateUser}
        setShow={setShowModalCreateUser}
        resetUpdateData={resetUpdateData}
        fetchListUserPaginateData={fetchListUserPaginateData}
      />
      <ModalUpdateUser
        show={showModalUpdateUser}
        setShow={setShowModalUpdateUser}
        dataUser={dataUser}
        // fetchListUser={fetchListUser}
        resetUpdateData={resetUpdateData}
        fetchListUserPaginateData={fetchListUserPaginateData}
      />
      <ModalDeleteUser
        show={showModalDeleteUser}
        setShow={setShowModalDeleteUser}
        dataUser={dataUser}
        // fetchListUser={fetchListUser}
        fetchListUserPaginateData={fetchListUserPaginateData}
        resetUpdateData={resetUpdateData}
      />

    </div>
  );
};

export default ManageUser;
