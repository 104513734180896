import { useState } from "react"
import { Outlet } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "./SideBar.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import Setting from "./Header/Setting";
import BgrSideBarDay from "../assets/bgr/bgr_1.jpg";
import BgrSideBarDark from "../assets/bgr/bgr_side_dark.jpg";
import BgrNight from "../assets/bgr/bgr_night.png";
import BgrDark from "../assets/bgr/bgr_dark2.jpg";
import { useSelector } from "react-redux";


const CommonSideBar = (props) => {
    let { SideBar, defauCollapsed } = props;
    const [collapsed, setCollapsed] = useState(defauCollapsed !== undefined ? defauCollapsed : true);
    const nightMode = useSelector((state) => state?.night?.mode);

    return (<div className="common-sidebar-container">
        <div className="content">
            {SideBar({ collapsed: collapsed, sidebarBg: +nightMode === 0 ? BgrSideBarDay : BgrSideBarDark })}
        </div>
        <div className="sider-content">
            <div className="header">
                <span onClick={() => { setCollapsed(!collapsed) }}>
                    <FaBars className="leftside" />
                </span>
                <div >
                    <Setting />
                </div>
            </div>
            <img src={+nightMode === 0 ? BgrNight : BgrDark} className="bgr-main" alt="IMG BG HOME" />

            <div className="body-sidebar">
                <PerfectScrollbar>
                    <Outlet />
                </PerfectScrollbar>
            </div>
        </div>
    </div>);
}

export default CommonSideBar;