import { Form } from "react-bootstrap";
import "./Stats.scss";
import { useEffect, useState } from "react";
import { formatDate, getDateBefore } from "../../../utils/DateUtil";
import { ToastNoSuccess } from "../../../utils/Utils";
import { getNru, getNruUdid } from "../../../services/apiCmsService";
import { Tooltip, Legend, Line, LineChart, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { C_ANDROID, C_IOS, C_OSX, C_PC, C_TOTAL, C_UNITY } from "../../../constant";
import { useSelector } from "react-redux";


const NRU = (props) => {
    const [nru, setNru] = useState([]);
    const [df, setDf] = useState();
    const [dt, setDt] = useState();
    const [udid, setUdid] = useState([]);
    const [uDf, setUDf] = useState();
    const [uDt, setUDt] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        let date = formatDate(getDateBefore(7));
        setDf(date);
        let curD = formatDate(new Date());
        setDt(curD);
        getListNru(date, curD);
        // nru udid
        setUDf(date);
        setUDt(curD);
        getListNruUdid(date, curD);
    }, [])

    const getListNru = async (df, dt) => {
        let data = await getNru(df, dt);
        if (data && data.EC === 0) {
            setNru(data.DT);
        }
        ToastNoSuccess(data);
    }

    const getListNruUdid = async (df, dt) => {
        let data = await getNruUdid(df, dt);
        if (data && data.EC === 0) {
            setUdid(data.DT);
        }
        ToastNoSuccess(data);
    }


    return (<div className="nru-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>NRU</div>
        <div className="body-panel">
            <div className="cell-panel">
                <div className="input-content">
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={df}
                        onChange={(event) => setDf(event.target.value)}
                    />
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={dt}
                        onChange={(event) => setDt(event.target.value)}
                    />
                    <button className="btn btn-success" onClick={() => getListNru(df, dt)}>Check</button>
                </div>
                <div className="body-content">
                    {(nru && nru.length > 0) ?
                        <div className="chart-content ">
                            <ResponsiveContainer aspect={1.6}>
                                <LineChart data={nru}>
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="TOTAL" stroke={C_TOTAL} strokeWidth={2} activeDot={{ r: 3 }} />
                                    <Line type="monotone" dataKey="ANDROID" stroke={C_ANDROID} />
                                    <Line type="monotone" dataKey="IOS" stroke={C_IOS} />
                                    <Line type="monotone" dataKey="UNITY" stroke={C_UNITY} />
                                    <Line type="monotone" dataKey="PC" stroke={C_PC} />
                                    <Line type="monotone" dataKey="OSX" stroke={C_OSX} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div> : <div className="no-data">
                            Không có dữ liệu
                        </div>}
                </div>
            </div>
            <div className="cell-panel">
                <div className="input-content">
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={uDf}
                        onChange={(event) => setUDf(event.target.value)}
                    />
                    <Form.Control
                        className="input-date"
                        type="date"
                        value={uDt}
                        onChange={(event) => setUDt(event.target.value)}
                    />
                    <button className="btn btn-success" onClick={() => getListNruUdid(df, dt)}>Check</button>
                </div>
                <div className="body-content">
                    {(udid && udid.length > 0) ?
                        <div className="chart-content ">
                            <ResponsiveContainer aspect={1.6}>
                                <LineChart data={udid}>
                                    <XAxis dataKey="date_created" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="NUMBER" stroke={C_TOTAL} strokeWidth={2} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div> :
                        <div className="no-data">
                            Không có dữ liệu
                        </div>}
                </div>
            </div>
        </div>
    </div>)
}

export default NRU;