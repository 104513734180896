import { useState } from "react";
import "./Stats.scss";
import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts';
import { useEffect } from "react";
import { ToastNoSuccess } from "../../../utils/Utils";
import { getDau } from "../../../services/apiCmsService";
import { Form } from "react-bootstrap";
import { formatDate } from "../../../utils/DateUtil";
import { C_ANDROID, C_IOS, C_OSX, C_PC, C_TOTAL, C_UNITY } from "../../../constant";
import { useSelector } from "react-redux";

const DAU = () => {
    const [daus, setDaus] = useState([]);
    const [df, setDf] = useState();
    const [dt, setDt] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);


    useEffect(() => {
        let date = new Date();
        date.setDate(new Date().getDate() - 7);
        setDf(formatDate(date))
        setDt(formatDate(new Date()));
        getListDau(formatDate(date), formatDate(new Date()));
    }, [])

    const getListDau = async (df, dt) => {
        let data = await getDau(df, dt);
        if (data && data.EC === 0) {
            setDaus(data.DT);
        }
        ToastNoSuccess(data);
    }

    return (
        <div className="stats-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>DAU</div>
            <div className="input-content" >
                <Form.Control
                    className="input-date"
                    type="date"
                    value={df ? df : ""}
                    onChange={(event) => setDf(event.target.value)}
                />
                <Form.Control
                    className="input-date"
                    type="date"
                    value={dt ? dt : ""}
                    onChange={(event) => setDt(event.target.value)}
                />
                <button className="btn btn-success" onClick={() => getListDau(df, dt)} >Kiểm tra</button>
            </div>
            {
                (daus && daus.length > 0) ?
                    <div className="chart-content">
                        <LineChart width={1400} height={550} data={daus}>
                            <XAxis dataKey="date" padding={{ left: 15, right: 15 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="TOTAL" stroke={C_TOTAL} strokeWidth={2} />
                            <Line type="monotone" dataKey="ANDROID" stroke={C_ANDROID} />
                            <Line type="monotone" dataKey="IOS" stroke={C_IOS} />
                            <Line type="monotone" dataKey="UNITY" stroke={C_UNITY} activeDot={{ r: 3 }} />
                            <Line type="monotone" dataKey="PC" stroke={C_PC} />
                            <Line type="monotone" dataKey="OSX" stroke={C_OSX} />
                        </LineChart>
                    </div > : <div className="no-data">
                        Không có dữ liệu
                    </div>
            }
        </div >
    )
}


export default DAU;