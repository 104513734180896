import { Modal } from "react-bootstrap"

const ModalSave = (props) => {
    const { show, setShow, send, title, desc } = props;

    const handleClose = () => {
        setShow(false);
    };

    const handleSave = () => {
        send();
        handleClose();
    }
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{desc}</Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={handleSave}>
                    Đồng Ý
                </button>
                <button className="btn btn-danger" onClick={handleClose}>
                    Hủy
                </button>
            </Modal.Footer>
        </Modal>)
}


export default ModalSave;