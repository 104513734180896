export const USER = "user2";
export const WeaponName = {};
export const ItemName = {};
export const HeroName = {};
export const ItemEquipName = {};

export const ItemType = {
    1: "Material",
    2: "Open",
    3: "Use",
    4: "Quest C",
    5: "Quest B",
    6: "Lotte Mini",
    7: "Lotte View",
}

export const C_UNITY = "#757571"
export const C_ANDROID = "#07ba10"
export const C_IOS = "#f57f40"
export const C_PC = "#ac40f5"
export const C_OSX = "#ff1500"
export const C_TOTAL = "#A78295"
export const BLACK = "#000"

export const Role = {
    BASIC: 0,
    VIEW_MONITOR: 1,
    GD: 2,
    UPLOAD: 3,
    STATS: 4,
    ADMIN: 5,
    SUPER_ADMIN: -1
}


// role : 0 basic, 1 gd, 2 pack, 3 stats, 4 : monitor , 5 admin, -1 super admin
export const FeatureType = [
    { id: 1, name: "TOOL", link: "/tools", role: Role.GD },
    { id: 2, name: "ADMIN", link: "/admins", role: Role.ADMIN },
    { id: 3, name: "MONITOR", link: "/monitor/slider-message", role: Role.VIEW_MONITOR },
    { id: 4, name: "SERVER STATUS", link: "/server/status", role: Role.GD },
    { id: 5, name: "USER", link: "/user-info/users", role: Role.BASIC },
    { id: 6, name: "DATA GAME", link: "/resources/db-text", role: Role.GD },
    { id: 7, name: "CONFIG", link: "/config/prop", role: Role.GD },
    { id: 8, name: "UPLOAD", link: "/pack/upload", role: Role.UPLOAD },
    { id: 9, name: "TOP", link: "/top/user", role: Role.BASIC },
    { id: 10, name: "STATS", link: "/stat/ccu", role: Role.STATS },
]