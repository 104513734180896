import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import { GiSharpShuriken, GiRank3 } from "react-icons/gi";
import { RiTShirt2Fill } from "react-icons/ri";
import "../SideBar.scss";
import { AiFillTrophy } from "react-icons/ai";
import { HiBriefcase } from "react-icons/hi";
import Footer from "../Header/Footer";


const TopSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;

    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}>
                {Logo({ collapsed: collapsed, title: "TOP" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem icon={<GiRank3 />}>
                            Top người dùng<Link to="/top/user" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<AiFillTrophy />}                        >
                            Top bang hội<Link to="/top/clan" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<HiBriefcase />}                        >
                            Top vật phẩm<Link to="/top/item" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<RiTShirt2Fill />}                        >
                            Top trang bị<Link to="/top/equip" />
                        </MenuItem>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<GiSharpShuriken />}                        >
                            Top vũ khí<Link to="/top/weapon" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    )
}

export default TopSideBar;