import axios from "../utils/axiosCustomize";

const getFindUser = async (key) => {
  return await axios.get(`api/find-user/?key=${key}`);
};

const getDefaultUser = async () => {
  return await axios.get("api/default-user");
}

const getClanList = async () => {
  return await axios.get("api/clan-list");
}

const getFindClan = async (key) => {
  return await axios.get(`api/find-clan/?key=${key}`);
};

const getFindKeyChatInvalid = async (key) => {
  return await axios.get(`api/find-key-chat-invalid?key=${key}`);
}

const deletePack = async (id) => {
  return await axios.delete(`api/pack-delete/?id=${id}`);
}

const getResetServer = async (port) => {
  return await axios.get(`api/reset-server?port=${port}`);
}

const getDelKeyInvalid = async (id) => {
  return await axios.get(`api/delete-config-chat-invalid?id=${id}`);
}


const postMail = async (userId, title, content, bonus) => {
  return await axios.post(`api/send-mail`, {
    userId,
    title,
    content,
    bonus,
  });
};


const getBlockLogin = async (id, type) => {
  return await axios.get(`api/user-block-login?id=${id}&type=${type}`);
}

const getBlockChat = async (id, time) => {
  return await axios.get(`api/user-block-chat?id=${id}&time=${time}`);
}

const checkSession = async () => {
  return await axios.get('api/session');
}

const postSystemMail = async (server_id, title, content, bonus, from_vip, to_vip, mail_type, date_start, date_end) => {
  return await axios.post(`api/send-system-mail`, {
    server_id,
    title,
    content,
    bonus,
    from_vip,
    to_vip,
    mail_type,
    date_start,
    date_end
  });
};

const postPackFile = async (cp, os, files) => {
  const form = new FormData();
  form.append("cp", cp);
  form.append("os", os);
  for (let i = 0; i < files.length; i++) {
    form.append("files", files[i])
  }
  return await axios.post("api/pack-upload", form);
};


const postApk = async (file) => {
  const form = new FormData();
  form.append("file", file);
  return await axios.post("api/upload-apk", form);
}

const deleteFolderPack = async (cp, os) => {
  const form = new FormData();
  console.log("cp == " + cp + " --- os == " + os);
  return await axios.post("api/pack-clear", { cp, os });
}

const postUploadImage = async (type, subType, files) => {
  let form = new FormData();
  form.append("type", type);
  form.append("subType", subType);
  for (let i = 0; i < files.length; i++) {
    form.append("files", files[i])
  }
  return await axios.post("api/upload-img-item", form);
}

const getAllConfig = async () => {
  return await axios.get(`api/all-config`);
}

const getAllConfigDev = async () => {
  return await axios.get(`api/all-config-dev`);
}

const getDau = async (df, dt) => {
  return await axios.get(`api/dau-normal?df=${df}&dt=${dt}`);
}

const getRR = async (df, dt) => {
  return await axios.get(`api/rr-normal?df=${df}&dt=${dt}`);
}

const getRRUdid = async (df, dt) => {
  return await axios.get(`api/rr-udid?df=${df}&dt=${dt}`);
}

const getCCU = async () => {
  return await axios.get(`api/ccu-normal`);
}

const getCCUMax = async (df, dt) => {
  return await axios.get(`api/ccu-max?df=${df}&dt=${dt}`);
}

const getCCUSV = async (sv, df, dt) => {
  return await axios.get(`api/ccu-max-sv?sv=${sv}&df=${df}&dt=${dt}`);
}

const getCCUSvDay = async (sv) => {
  return await axios.get(`api/ccu-max-sv-day?sv=${sv}`)
}

const getQuit7 = async () => {
  return await axios.get(`api/quit7-normal`);
}
const getQuit30 = async () => {
  return await axios.get(`api/quit30-normal`);
}

const getMau = async (df, dt) => {
  return await axios.get(`api/mau-normal?df=${df}&dt=${dt}`);
}

const getNru = async (df, dt) => {
  return await axios.get(`api/nru-normal?df=${df}&dt=${dt}`);
}

const getNruUdid = async (df, dt) => {
  return await axios.get(`api/nru-udid?df=${df}&dt=${dt}`);
}

const getAllChatInvalid = async () => {
  return await axios.get(`api/all-config-chat-invalid`)
}

const getAllConfigApi = async () => {
  return await axios.get(`api/all-config-help`);
}

const getAllConfigLang = async () => {
  return await axios.get(`api/all-config-lang`);
}

const postSaveConfig = async (k, v) => {
  return await axios.post("api/edit-config", { k, v });
}
const postSaveConfigDev = async (k, v) => {
  return await axios.post("api/edit-config-dev", { k, v });
}

const postSaveConfigHelp = async (k, vi) => {
  return await axios.post("api/edit-config-help", { k, vi });
}
const postCreateConfigHelp = async (k, vi) => {
  return await axios.post("api/new-config-help", { k, vi });
}
const postCreateConfigLang = async (k, vi) => {
  return await axios.post("api/new-config-lang", { k, vi });
}
const postSaveConfigLang = async (k, vi) => {
  return await axios.post("api/edit-config-lang", { k, vi });
}
const getFindConfig = async (type, key) => {
  return await axios.get(`api/find-config/?type=${type}&key=${key}`);
}
const getFormatBonus = async () => {
  return await axios.get('api/format-bonus');
}
const getDbText = async () => {
  return await axios.get('api/db-text');
}
const getMap = async () => {
  return await axios.get('api/map');
}
const getTeleports = async () => {
  return await axios.get('api/res-teleport');
}
const getPacks = async () => {
  return await axios.get('api/pack-list');
}
const postEditMap = async (id, enable, name, map, camera) => {
  return await axios.post("api/edit-map", { id, enable, name, map, camera });
}
const postEditTeleport = async (id, player_pos, pos) => {
  return await axios.post("api/res-edit-teleport", { id, player_pos, pos });
}

const postPackCreate = async (id, name, file) => {
  return await axios.post("api/pack-create", { id, name, file });
}

const postMapCreate = async (id, enable, name, map, camera) => {
  return await axios.post("api/new-map", { id, enable, name, map, camera });
}
const postTeleportCreate = async (name, type, map, map_id, next_id, player_pos, pos, enable) => {
  return await axios.post("api/new-teleport", { name, type, map, map_id, next_id, player_pos, pos, enable });
}

const postEditChatInvalid = async (id, k) => {
  return await axios.post("api/edit-config-chat-invalid", { id, k })
}
const postCreateChatInvalid = async (k) => {
  return await axios.post("api/new-config-chat-invalid", { k })
}
const getReloadChatInvalid = async () => {
  return await axios.get("api/reload-config-chat-invalid")
}

const postEditPack = async (id, name, file) => {
  return await axios.post("api/pack-edit", { id, name, file });
}

const getVersionClient = async () => {
  return await axios.get('api/version-client');
}

const postEditVersion = async (k, v) => {
  return await axios.post("api/version-edit", { k, v });
}

const getToastMessage = async (server, type, msg) => {
  return await axios.get(`api/send-msg-toast?server=${server}&type=${type}&msg=${msg}`);
}
const getSlideMessage = async (server, msg) => {
  return await axios.get(`api/send-msg-slide?server=${server}&msg=${msg}`);
}


export {
  getFindUser,
  getFindClan,
  postMail,
  getResetServer,
  getAllConfig,
  getAllConfigDev,
  getAllConfigLang,
  getAllConfigApi,
  postSaveConfig,
  postSaveConfigDev,
  postSaveConfigHelp,
  postCreateConfigHelp,
  postSaveConfigLang,
  getDefaultUser,
  getFindConfig,
  getFormatBonus,
  getDbText,
  postSystemMail,
  postPackFile,
  postEditPack,
  getMap,
  postEditMap,
  deletePack,
  getTeleports,
  postEditTeleport,
  getPacks,
  postPackCreate,
  getClanList,
  getVersionClient,
  postEditVersion,
  getAllChatInvalid,
  postEditChatInvalid,
  postCreateChatInvalid,
  getReloadChatInvalid,
  getFindKeyChatInvalid,
  getDelKeyInvalid,
  getBlockLogin,
  getBlockChat,
  postUploadImage,
  getCCU,
  getCCUMax,
  getCCUSV,
  getCCUSvDay,
  getDau,
  getMau,
  getNru,
  getNruUdid,
  getRR,
  getRRUdid,
  getQuit7,
  getQuit30,
  postCreateConfigLang,
  postMapCreate,
  postTeleportCreate,
  getToastMessage,
  getSlideMessage,
  checkSession,
  deleteFolderPack,
  postApk,
};
