import { Form } from "react-bootstrap";
import "./UpLoadImage.scss";
import { useRef, useState } from "react";
import { ToastResponse } from "../../../../utils/Utils";
import { postUploadImage } from "../../../../services/apiCmsService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const UpLoadImage = () => {
    const [files, setFiles] = useState([]);
    const [isSelected, setSelected] = useState(false);
    const inputRef = useRef(null);
    const [type, setType] = useState("item");
    const [showSub, setShowSub] = useState(false);
    const [subType, setSubType] = useState(1);
    const nightMode = useSelector((state) => state?.night?.mode);

    const fileSelectHandler = (event) => {
        event.preventDefault();
        setSelected(event.target.files.length > 0);
        setFiles(event.target.files);
    }
    const clearInput = () => {
        inputRef.current.value = "";
        setSelected(false);
    }

    const handleUploadImage = async () => {
        if (!isSelected) {
            toast.error("Cần chọn file trước");
            return
        }
        let data = await postUploadImage(type, subType, files);
        ToastResponse(data);
    }

    const changeType = (event) => {
        setType(event.target.value);
        clearInput();
        switch (event.target.value) {
            case "item_farm":
                setShowSub(true);
                break;
            default: setShowSub(false);
        }
    }

    return (<div className="upload-image-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Upload Image Item</div>
        <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
            <div className="select-type">
                <Form.Select
                    value={type}
                    className={+nightMode === 1 ? "form-input-dark" : ""}
                    onChange={changeType}
                >
                    <option value="item">Vật phẩm</option>
                    <option value="item_equip">Trang bị</option>
                    <option value="weapon">Vũ khí</option>
                    <option value="hero">Anh hùng</option>
                    <option value="item_farm">Nông sản</option>
                    <option value="item_point">Điểm</option>
                    <option value="piece">Mảnh</option>
                </Form.Select>
                {showSub && type === "item_farm" &&
                    <Form.Select
                        value={subType}
                        className={+nightMode === 1 ? "form-input-dark" : ""}
                        onChange={(event) => {
                            setSubType(+event.target.value);
                            clearInput();
                        }}>
                        <option value="1">Hạt giống</option>
                        <option value="2">Nông sản</option>
                        <option value="3">Công cụ</option>
                        <option value="4">Thức ăn</option>
                    </Form.Select>
                }
            </div>
            <div className="form-body">
                <Form.Control ref={inputRef} type="file"
                    className={+nightMode === 1 ? "form-input-dark" : ""}
                    accept="image/png, image/jpeg"
                    onChange={fileSelectHandler} multiple size="lg" />
            </div>

            <div className="group-button">
                {isSelected ? <div><button className="btn btn-warning" onClick={clearInput} >Clear</button></div> : <div></div>}
                {isSelected ? <div><button className="btn btn-success" onClick={handleUploadImage} >Upload</button> </div> : <div></div>}
            </div>
        </div>
    </div>)
}

export default UpLoadImage;