

import "react-pro-sidebar/dist/css/styles.css";

import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Logo from "../Header/Logo";
import { AiFillThunderbolt } from "react-icons/ai";
import "../SideBar.scss"
import Footer from "../Header/Footer";


const ServerSideBar = (props) => {
    const { collapsed, toggle, handleToggleSidebar, sidebarBg } = props;

    return (
        <div className="sidebar-container">
            <ProSidebar
                image={sidebarBg}
                collapsed={collapsed}
                toggled={toggle}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                {Logo({ collapsed: collapsed, title: "SERVER" })}
                <SidebarContent>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<AiFillThunderbolt />}
                        >
                            Status
                            <Link to="/server/status" />
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                {Footer({ collapsed: collapsed })}
            </ProSidebar>
        </div>
    )
}

export default ServerSideBar;