import "./HomePage.scss";
import { useNavigate } from "react-router-dom";
import imgButtonDown from "../../assets/img/download.png";
import { FeatureType, USER } from "../../constant";
import { Col, Row } from "react-bootstrap";
import BgrNight from "../../assets/bgr/bgr_night.png";
import BgrDark from "../../assets/bgr/bgr_1.jpg";
import "./Global.scss";
import Header from "../Header/Header";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { checkSession } from "../../services/apiCmsService";

const HomePage = () => {
  const user = JSON.parse(localStorage.getItem(USER));
  const navigate = useNavigate();
  const nightMode = useSelector((state) => state?.night?.mode);

  const checkSS = async (item) => {
    await checkSession();
    navigate(item.link);
  }

  const card = (item) => {
    return (
      <Col >
        <div className="card-content lock-card">
          <button className="btn" onClick={() => checkSS(item)}><span></span>
            {item.name}
            <img className="aord-icon" src="/logo192.png" alt="Img Download" />
          </button>
        </div>
      </Col>)
  }

  return (
    <div className="homepage-container">
      <div className={+nightMode === 0 ? "day-mode" : "night-mode"}>
        {(!user || !user.isAuthenticated) && navigate("/login")}
        <img src={+nightMode === 0 ? BgrNight : BgrDark} className="bgr-home" alt="IMG BG HOME" />
        <div >
          {Header({ showSetting: true })}

        </div>
        <div className="body-content container">
          <div className="left-content">
            <img className="img-download" src={imgButtonDown} alt="Img Download" />
            <div className="btn-group">
              <a href="http://cdn.aordgame.com:8083/app.apk" >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Download
              </a>
            </div>
          </div>

          <Row className="right-content">
            {FeatureType && FeatureType.length > 0 && FeatureType.map((item, index) => {
              return (<Fragment key={`key-home-card-${index}`}>{card(item)}</Fragment>)
            })}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default HomePage;
