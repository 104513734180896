import { useEffect, useState } from "react";
import { getTeleports, postEditTeleport, postTeleportCreate } from "../../../../services/apiCmsService";
import { toast } from "react-toastify";
import { Form, Table } from "react-bootstrap";
import _ from "lodash";
import { IsJsonString } from "../../../../utils/Validate";
import "./Teleport.scss";
import { BsPlusSquare } from "react-icons/bs";
import ModalCreateTeleport from "../ModalCreateTeleport";
import { ToastResponse } from "../../../../utils/Utils";
import { useSelector } from "react-redux";

const Teleport = () => {
    const [teleports, setTeleports] = useState([]);
    const [teleCache, setTeleCache] = useState([]);
    const [showModalCreate, setShowModalCreate] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getTeleportList();
    }, [])

    const getTeleportList = async () => {
        let data = await getTeleports();
        if (data && +data.EC === 0) {
            setTeleports(data.DT);
            setTeleCache(data.DT);
            return;
        }
        ToastResponse(data);
    }

    const handleBtnNew = () => {
        setShowModalCreate(true);
    }

    const handleCreateTeleport = async (name, type, map, map_id, next_id, player_pos, pos, enable) => {
        let data = await postTeleportCreate(name, type, map, map_id, next_id, player_pos, pos, enable);
        if (data && +data.EC === 0) {
            getTeleportList();
        }
        ToastResponse(data);
    }

    const handleBtnCancelClick = (index) => {
        let teleClone = _.cloneDeep(teleports);
        teleClone[index].player_pos = teleCache[index].player_pos ? teleCache[index].player_pos : "";
        teleClone[index].pos = teleCache[index].pos ? teleCache[index].pos : "";
        setTeleports(teleClone);
    }

    const handleEditPlayerPos = (event, index, type) => {
        let teleClone = _.cloneDeep(teleports);
        if (type === 1) {
            teleClone[index].player_pos = event.target.value;
        } else {
            teleClone[index].pos = event.target.value;
        }
        setTeleports(teleClone);
    }

    const handleSaveData = async (cell) => {
        if (!IsJsonString(cell.player_pos)) {
            toast.error("Vị trí player phải là json!")
            return;
        }
        if (!IsJsonString(cell.pos)) {
            toast.error("Vị trí teleport phải là json!")
            return;
        }
        let ret = await postEditTeleport(cell.id, cell.player_pos, cell.pos);
        ToastResponse(ret);
        if (ret && ret.EC === 0) getTeleportList();
    }

    return (<div className="teleport-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Teleport</div>
        <div className="btn-create" >
            <button className="btn btn-success" onClick={handleBtnNew}> {<BsPlusSquare />} NEW</button>
        </div>
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>Id</th>
                        <th style={{ width: '5%' }}>Màu</th>
                        <th style={{ width: '25%' }}>Name</th>
                        <th style={{ width: '8%' }}>Map</th>
                        <th style={{ width: '8%' }}>Map ID</th>
                        <th style={{ width: '8%' }}>Next</th>
                        <th>Vị trí player</th>
                        <th>Vị trí teleport</th>
                        <th style={{ width: '5%' }}>Enable</th>
                        <th style={{ width: '15%' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {teleports && teleports.length > 0 &&
                        teleports.map((teleport, index) => {
                            return (
                                <tr key={`teleport-${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{teleport.id}</td>
                                    <td >{teleport.type === 0 ? <button className="btn btn-outline-secondary">Invisible</button>
                                        : teleport.type === 1 ? <button className="btn btn-primary" style={{ width: '100%' }}>Blue </button>
                                            : teleport.type === 2 ? <button className="btn btn-danger" style={{ width: '100%' }}>Red </button>
                                                : <button className="btn btn-success" style={{ width: '100%' }}>Green</button>}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{teleport.name}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{teleport.map}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{teleport.map_id}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{teleport.next_id}</td>
                                    <td><Form.Control
                                        className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                                        type="text"
                                        value={teleport.player_pos}
                                        onChange={(event) => handleEditPlayerPos(event, index, 1)}
                                    /></td>
                                    <td>
                                        <Form.Control
                                            className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                                            type="text"
                                            value={teleport.pos}
                                            onChange={(event) => handleEditPlayerPos(event, index, 2)}
                                        />
                                    </td>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{teleport.enable === 1 ? "Mở" : "Đóng"}</td>
                                    <td>
                                        <div className="group-button">
                                            {(teleCache[index].player_pos !== teleports[index].player_pos ||
                                                teleCache[index].pos !== teleports[index].pos)
                                                && < button className="btn btn-success"
                                                    onClick={() => handleSaveData(teleport)}
                                                >Lưu</button>}
                                            {(teleCache[index].player_pos !== teleports[index].player_pos ||
                                                teleCache[index].pos !== teleports[index].pos)
                                                && <button button className="btn btn-primary"
                                                    onClick={() => handleBtnCancelClick(index)}
                                                >Hủy</button>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <ModalCreateTeleport
                show={showModalCreate}
                setShow={setShowModalCreate}
                funcCreate={handleCreateTeleport}
            />
        </div>
    </div >)
}

export default Teleport;