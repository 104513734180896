import { useEffect, useState } from "react";
import { FloatingLabel, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import "./UserInfo.scss";
import { formatDate, stringToDate } from "../../../../utils/DateUtil";
import RenderBonusType from "../../../UserInfo/Content/SendUserMail/RenderBonusType";
import { getBlockChat, getBlockLogin, getFormatBonus, postMail } from "../../../../services/apiCmsService";
import { IsJsonString } from "../../../../utils/Validate";
import { ToastResponse } from "../../../../utils/Utils";
import { BonusType } from "../../../../enum/BonusType";
import { getUserInfo, getUserLogs } from "../../../../services/userService";
import { useSelector } from "react-redux";
import { BiSearchAlt } from "react-icons/bi";

const UserInfo = () => {
    const [info, setInfo] = useState({});
    const [index, setIndex] = useState(1);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [bonus, setBonus] = useState("");
    const [type, setType] = useState("Gold");
    const [number, setNumber] = useState(1);
    const [expire, setExpire] = useState(-1);
    const [key, setKey] = useState(1);
    const [keySearch, setKeySearch] = useState("");
    const [listEquip, setListEquip] = useState([]);
    const [listItem, setListItem] = useState([]);
    const [listWeapon, setListWeapon] = useState([]);
    const [lockLogin, setLockLogin] = useState();
    const [lockChat, setLockChat] = useState();
    const [dateSelect, setDateSelect] = useState(new Date().toLocaleDateString());
    let userId = localStorage.getItem('UserIdInfo');
    const nightMode = useSelector((state) => state?.night?.mode);
    const [arrDate, setArrDate] = useState([]);
    const [logs, setLogs] = useState([]);


    useEffect(() => {
        getInfo();
    }, []);

    const getInfo = async () => {
        var arrDate = [];
        var date = new Date();
        for (let i = 0; i <= 30; i++) {
            const currentDate = new Date(date.getTime() - i * 24 * 60 * 60 * 1000);
            arrDate.push(currentDate.toLocaleDateString());
        }
        setArrDate(arrDate);

        let data = await getUserInfo(+userId);
        if (data && data.DT && +data.EC === 0) {
            setInfo(data.DT[0]);
            setLockChat(data.DT[0].lock_chat);
            setLockLogin(data.DT[0].block_type);
        }
        if (!data) toast.error("Không nhận được dữ liệu!");

        data = await getFormatBonus();
        if (data && +data.EC === 0) {
            setListEquip(data.DT.ItemEquipment);
            setListItem(data.DT.Item);
            setListWeapon(data.DT.Weapon);
            return;
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
            setListEquip([]);
            setListItem([]);
            setListWeapon([]);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    }

    const handleBtnClear = () => {
        setBonus("");
        setNumber(1);
    };

    const handleBtnAddClick = () => {

        if (number <= 0 || number === undefined) {
            toast.warning("Số lượng phải lớn hơn 0");
            setNumber(1);
            return;
        }
        if (expire < -1 || expire === 0) {
            toast.warning("Expire phải lớn hơn 0 hoặc bằng -1");
            setExpire(-1);
            return;
        }
        let id = BonusType(type);
        if (id === 1 || id === 2 || id === 9 || id === 4 || id === 15) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${+number}`);
            setNumber(1);
        }
        if (id === 3 || id === 6) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key},${+number},${+expire}`);
            setNumber(1);
            setExpire(-1);
        }
        if (id === 7) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${key},${+number}`);
            setNumber(1);
        }
        if (id === 5) {
            let has = bonus === "" ? "" : ",";
            setBonus(`${bonus}${has}${id},${+number}`);
            setNumber(1);
        }
        if (id === 8 || id === 10 || id === 11) {
            toast.error("Bonus này đang phát triển!");
            setNumber(0);
        }
    };

    const handleBlockClick = async () => {
        let data = await getBlockLogin(userId, lockLogin);
        ToastResponse(data);
        if (data && data.EC === 0) getInfo();
        setIndex(3);
    }

    const handleBlockChatClick = async () => {
        let data = await getBlockChat(userId, lockChat);
        ToastResponse(data);
        if (data && data.EC === 0) getInfo();
        setIndex(3);
    }

    const handleBtnSendClick = async () => {
        if (userId === "") {
            toast.warning("Thiếu dữ liệu User Id");
            return;
        }
        if (title === "") {
            toast.warning("Cần nhập Title");
            return;
        }
        if (!IsJsonString(`[${bonus}]`)) {
            toast.error("Bonus không hợp lệ!");
            return;
        }
        let data = await postMail(userId, title, content, bonus);
        if (data && +data.EC === 0) {
            toast.success(data.EM);
        }
        if (data && +data.EC !== 0) {
            toast.error(data.EM);
        }
        setTitle("");
        setBonus("");
        setContent("");
        setNumber(1);
        setIndex(3);
    };

    const handleSetDateSelect = async (date) => {
        setDateSelect(date);
        await getDataLogByDate(date);
    }

    const getDataLogByDate = async (date) => {
        var dt = stringToDate(date, "dd/MM/yyyy", "/");
        let data = await getUserLogs(userId, formatDate(dt));
        if (data && +data.EC === 0) {
            let aLog = [];
            for (let i = 0; i < data.DT.length; i++) {
                aLog.push(JSON.parse(data.DT[i]));
            }
            setLogs(aLog);
            return
        }
        if (!data) {
            toast.error("Không nhận được dữ liệu!");
            setLogs([]);
        }
    }

    const setIndexButton = async (index) => {
        if (index === 3) {
            await getDataLogByDate(dateSelect);
        }
        setIndex(index);
    }

    return (<div className="user-info-container">
        <div className="user-id">
            <button className="btn btn-success btn-action"
                onClick={() => setIndexButton(1)}>
                <b>Info</b>
            </button>
            {/* <button className="btn btn-warning btn-action"
                onClick={() => setIndexButton(2)}>
                <b>Gửi thư</b>
            </button> */}
            <button className="btn btn-secondary btn-action"
                onClick={() => setIndexButton(3)}>
                <b>Logs</b>
            </button>
            <button className="btn btn-danger btn-action"
                onClick={() => setIndexButton(4)}>
                <b>Block</b>
            </button>
        </div>
        {index === 1 &&
            <div className="info-content">
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Tên </b></div>
                    <div className="value">  <label >{info.name}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >UserName </b></div>
                    <div className="value">  <label >{info.username}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Giới thiệu </b></div>
                    <div className="value">  <label >{info.intro}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Server  </b></div>
                    <div className="value">  <label >{info.server}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Level </b></div>
                    <div className="value">  <label >{info.level}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Gold </b></div>
                    <div className="value">  <label >{info.gold}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Gem </b></div>
                    <div className="value">  <label >{info.gem}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Vip</b></div>
                    <div className="value">  <label >{info.vip}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Kinh nghiệm vip </b></div>
                    <div className="value">  <label >{info.vip_exp}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Kinh nghiệm </b></div>
                    <div className="value">  <label >{info.exp}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Lực chiến </b></div>
                    <div className="value">  <label >{info.power}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Xếp hạng </b></div>
                    <div className="value">  <label >{info.rank}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >MainId </b></div>
                    <div className="value">  <label >{info.main_id}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Id bang hội </b></div>
                    <div className="value">  <label >{info.clan}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Tên bang hội </b></div>
                    <div className="value">  <label >{info.clan_name}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Chức danh </b></div>
                    <div className="value">  <label >{info.clan_position}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Channel </b></div>
                    <div className="value">  <label >{info.game_channel}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Avatar </b></div>
                    <div className="value">  <label >{info.avatar}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Vũ khí trang bị </b></div>
                    <div className="value">  <label >{info.weapon}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Version </b></div>
                    <div className="value">  <label >{info.version}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Thời gian login </b></div>
                    <div className="value">  <label >{info.login_time}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Số lượng bạn bè </b></div>
                    <div className="value">  <label >{info.number_friend}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Khóa Login</b></div>
                    <div className="value">  <label style={{ color: "red", fontWeight: 600 }}>{info.block_type === 1 ? "Đang khóa" : "-"}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Khóa chat </b>(đến ngày)</div>
                    <div className="value">  <label style={{ color: "red", fontWeight: 600 }}>{info.lock_chat ? new Date(info.lock_chat).toLocaleDateString() : "-"}</label></div>
                </div>
                <div className="item">
                    <div><b>Thời gian hành động cuối </b></div>
                    <div className="value">  <label >{info.last_action}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Thời gian đăng nhập gần nhất </b></div>
                    <div className="value">  <label >{new Date(info.last_login).toLocaleString()}</label></div>
                </div>
                <div className="item">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Thời gian đăng xuất gần nhất </b></div>
                    <div className="value">  <label >{new Date(info.logout).toLocaleString()}</label></div>
                </div>
                <div className="item-bot">
                    <div><b className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}
                    >Thời gian tạo tài khoản </b></div>
                    <div className="value">  <label >{new Date(info.date_created).toLocaleString()}</label></div>
                </div>
            </div>}
        {index === 2 &&
            <div className="body-content">
                <div className="row1">
                    <div className="input-title">
                        <FloatingLabel controlId="floatingTitle" label="Title">
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={(event) => setTitle(event.target.value)}
                            />
                        </FloatingLabel>
                    </div>
                </div>
                <div className="input-content">
                    <FloatingLabel controlId="floatingContent" label="Content">
                        <Form.Control
                            type="text"
                            as="textarea"
                            style={{ height: '100px' }}
                            value={content}
                            onChange={(event) => setContent(event.target.value)}
                        />
                    </FloatingLabel>
                </div>
                <div className="bonus-content">
                    <FloatingLabel
                        controlId="floatingBonus"
                        label="Bonus"
                        className="bonus-input"
                    >
                        <Form.Control
                            type="text"
                            value={`[${bonus}]`}
                            onChange={(event) => setBonus(event.target.value)}
                        />
                    </FloatingLabel>
                    <button
                        className="btn btn-danger btn-action "
                        onClick={handleBtnClear}
                    >
                        Clear
                    </button>
                </div>
                <div className="form-content">
                    <Form.Select
                        className="select-content"
                        defaultValue={type}
                        onChange={(event) => {
                            setType(event.target.value);
                            setNumber(1);
                        }}
                    >
                        <option value="Gold">Gold</option>
                        <option value="Gem">Gem</option>
                        <option value="ItemEquipment">Item Equipment</option>
                        <option value="UserExp">User Exp</option>
                        <option value="Hero">Hero</option>
                        <option value="Item">Item</option>
                        <option value="Weapon">Weapon</option>
                        <option value="Avatar">Avatar</option>
                        <option value="VipExp">Vip Exp</option>
                        <option value="Pet">Pet</option>
                        <option value="Skin">Skin</option>
                    </Form.Select>
                    <div className="render-bonus">
                        {RenderBonusType(
                            type,
                            number,
                            setNumber, expire, setExpire,
                            handleBtnAddClick,
                            key,
                            setKey,
                            listEquip,
                            listItem,
                            listWeapon
                        )}
                    </div>
                </div>
                <div className="bot-content">
                    <button
                        className="btn btn-dark btn-send"
                        onClick={handleBtnSendClick}
                    >
                        Gửi thư
                    </button>
                </div>
            </div>
        }
        {index === 3 && <div className="panel-3-content">
            <div className="title-3-content">
                <Form.Select
                    className={+nightMode === 0 ? "select-date" : "select-date form-dark"}
                    defaultValue={dateSelect}
                    onChange={(event) => handleSetDateSelect(event.target.value)}
                >
                    {arrDate && arrDate.length > 0 && arrDate.map((item, index) => {
                        return <option eventKey={`format-date-${index}`} value={item} >{item}</option>
                    })}
                </Form.Select>
                <div className={+nightMode === 0 ? "input-search search-content" : "input-search search-content input-search-night"}>
                    <Form.Control
                        className="input-key"
                        type="text"
                        value={keySearch}
                        autoFocus
                        onChange={(event) => { setKeySearch(event.target.value); }}
                    />
                    <BiSearchAlt className="search-icon" />
                </div>
            </div>

            {(logs && logs.length > 0) ? <div className="table"><Table bordered>
                <thead>
                    <tr>
                        <th>Ngày</th>
                        <th>Hành động</th>
                        <th>Sự kiện</th>
                        <th>Nội dung</th>
                    </tr>
                </thead>
                <tbody>
                    {logs && logs.length > 0 &&
                        logs.filter((item) => {
                            return keySearch.toLowerCase() === "" ? item : item.action.toLowerCase().includes(keySearch) || item.detail.toLowerCase().includes(keySearch) || item.data.toLowerCase().includes(keySearch);
                        }).map((item, index) => {
                            return (
                                <tr key={`log-${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.timestamp}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.action}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.detail}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.data}</td>
                                </tr>
                            )
                        })}
                </tbody>

            </Table> </div> : <div className="title-nodata ">Không có dữ liệu</div>
            }
        </div>}

        {index === 4 && (
            <div className="panel-block">
                <div className="content-cell">
                    <div className="title">Block Login</div>
                    <Form.Select
                        className="select-content"
                        defaultValue={lockLogin}
                        onChange={(event) => {
                            setLockLogin(event.target.value);
                        }}
                    >
                        <option value="0">Không</option>
                        <option value="1">Khóa</option>
                    </Form.Select>
                    <button className="btn btn-warning btn-action"
                        onClick={handleBlockClick}
                    >Lưu</button>
                </div>
                <div className="content-cell">
                    <div className="title">Block Chat</div>
                    <Form.Control
                        className="select-content"
                        type="date"
                        value={lockChat ? formatDate(new Date(lockChat)) : ""}
                        onChange={(event) => setLockChat(event.target.value)}
                    />
                    <button className="btn btn-warning btn-action"
                        onClick={handleBlockChatClick}
                    >Lưu</button>
                </div>
            </div>)
        }
    </div>)
}
export default UserInfo;