import { Badge, Form, Table } from "react-bootstrap";
import "./Top.scss";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useState } from "react";
import { ToastNoSuccess, formatNumberDot } from "../../../utils/Utils";
import { getTopItem } from "../../../services/topService";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const TopItem = () => {
    const [sv, setSv] = useState(-1);
    const [num, setNum] = useState(10);
    const [id, setId] = useState(1);
    const [items, setItems] = useState([]);
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        handleGetData();
    }, [])

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            handleGetData();
        }
    };

    const handleGetData = async () => {
        if (num < 0 || sv < -3 || id < 0) {
            toast.error("Dữ liệu không hợp lệ!");
            return;
        }
        if (num > 200) {
            toast.error("Num không được lớn hơn 200");
            return;
        }
        let data = await getTopItem(id, sv, num);
        ToastNoSuccess(data);
        if (data && data.EC === 0) {
            setItems(data.DT);
        } else setItems([]);
    }

    return (<div className="top-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Top Vật Phẩm ID {id}</div>
        <div className="note-content">
            <Badge bg="danger">Lưu ý: </Badge>
            <div className={+nightMode === 1 ? "text-dark-aord title-note" : "text-light-aord title-note"}>Server ID bé hơn 0 là sv test</div>
        </div>
        <div className="input-content">
            <FloatingLabel className={+nightMode === 1 ? "text-dark-aord select-content" : "text-light-aord select-content"} label="Id" >
                <Form.Control
                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                    type="number"
                    min={1}
                    value={id}
                    onChange={(event) => setId(+event.target.value)}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
            </FloatingLabel>
            <FloatingLabel className={+nightMode === 1 ? "text-dark-aord select-content" : "text-light-aord select-content"} label="Server">
                <Form.Control
                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                    type="number"
                    min={-3}
                    value={sv}
                    onChange={(event) => setSv(+event.target.value)}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
            </FloatingLabel>
            <FloatingLabel className={+nightMode === 1 ? "text-dark-aord select-content" : "text-light-aord select-content"} label="Number">
                <Form.Control
                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                    type="number"
                    max={200}
                    value={num}
                    onChange={(event) => setNum(+event.target.value)}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
            </FloatingLabel>
            <button
                className="btn btn-success btn-submit"
                onClick={handleGetData}
            >Get</button>
        </div>
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th style={{ width: "5%" }}>TOP</th>
                        <th style={{ width: "10%" }}>User Id</th>
                        <th>Tên</th>
                        <th>Cấp độ</th>
                        <th>Số lượng</th>
                        <th>Ngày nhận</th>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length > 0 && items.map((c, index) => {
                        return (<tr key={`top-user-${index}`}>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}><b className="notice">{index + 1}</b></td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{c.user_id}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{c.name}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{c.level}</td>
                            <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}><b className="notice">{formatNumberDot(c.number)}</b></td>
                            <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(c.date_created).toLocaleDateString()}</td>
                        </tr>)

                    })}
                </tbody>
            </Table>
        </div>
    </div >)
}

export default TopItem;