import axios from "../utils/axiosCustomize";

const adminCreateUser = async (email, username, password, role) => {
  const form = new FormData();
  form.append("username", username);
  form.append("password", password);
  form.append("email", email);
  form.append("role", role);
  return await axios.post("admin/admin-create-user", form);
};

const deleteUser = async (userId) => {
  return await axios.delete("admin/delete-user", { data: { id: userId } });
};

const putUpdateUser = async (username, id, role, active) => {
  const form = new FormData();
  form.append("username", username);
  form.append("id", id);
  form.append("role", role);
  form.append("active", active);
  return await axios.put("admin/update-user", form);
};

const getUserWithPaginate = async (page, limit) => {
  return await axios.get(`admin/participant?page=${page}&limit=${limit}`);
};
const getFindUserLog = async (action) => {
  return await axios.get(`admin/log-find-user?action=${action}`);
};
const getFindUserId = async (userId) => {
  return await axios.get(`admin/log-user-id?userId=${userId}`);
};
const getDashBoard = async () => {
  return await axios.get(`admin/dashboard`);
};
const getLogUser = async () => {
  return await axios.get(`admin/log-user`);
};

export {
  adminCreateUser,
  putUpdateUser,
  deleteUser,
  getUserWithPaginate,
  getDashBoard,
  getLogUser,
  getFindUserLog,
  getFindUserId,

};
