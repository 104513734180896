import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import "./Version.scss"
import { BiSave } from "react-icons/bi";
import { GiBackwardTime } from "react-icons/gi";
import { useState } from "react";
import { useEffect } from "react";
import { getVersionClient, postEditVersion } from "../../../../services/apiCmsService";
import { ToastNoSuccess, ToastResponse } from "../../../../utils/Utils";
import _ from "lodash";
import { useSelector } from "react-redux";


const Version = () => {
    const FORCE_UPDATE = "version_force_update";
    //cp TEST
    const listOs = [{ os: "ANDROID" }, { os: "IOS" }, { os: "OSX" }, { os: "PC" }]
    const listCp = { TEST: "test", AORD: "aord" }
    const nightMode = useSelector((state) => state?.night?.mode);
    const [data, setData] = useState([]);
    const [cache, setCache] = useState([]);

    useEffect(() => {
        getVersion();
    }, [])

    const getKeyBuild = (cp, os) => {
        return cp + "|" + os + ":version_latest";
    }

    const getKeyShow = (cp, os) => {
        return cp + "|" + os + ":version_show_update";
    }

    const getKeyURL = (cp, os) => {
        return cp + "|" + os + ":version_latest_url";
    }
    const getVersion = async () => {
        let result = await getVersionClient();
        if (result && +result.EC === 0) {
            setData(result.DT);
            setCache(result.DT);
        }
        ToastNoSuccess(result);
    }

    const getVersionByKey = key => {
        let ret = data.filter(obj => { return obj.k === key });
        return ret.length > 0 ? ret[0].v : "";
    }
    const getVersionCacheKey = key => {
        let ret = cache.filter(obj => { return obj.k === key });
        return ret.length > 0 ? ret[0].v : "";
    }

    const saveData = async (key) => {
        let ret = data.filter(obj => { return obj.k === key });
        if (ret.length > 0) {
            let data = await postEditVersion(key, ret[0].v);
            ToastResponse(data);
        }
        getVersion();
    }

    const revert = (key) => {
        let clone = _.cloneDeep(data);
        let ret = clone.filter(obj => { return obj.k === key });
        let goc = cache.filter(obj => { return obj.k === key });
        if (ret.length > 0 && goc.length > 0) {
            ret[0].v = goc[0].v;
        }
        setData(clone);
    }

    const editVersionKey = (event, key) => {
        let clone = _.cloneDeep(data);
        let ret = clone.filter(obj => { return obj.k === key });
        if (ret.length > 0) {
            ret[0].v = event.target.value;
        }
        setData(clone);
    }

    return (
        <div className="version-container">
            <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Chỉnh sửa phiên bản theo hệ điều hành</div>
            <div className={+nightMode === 0 ? "day-body-content" : "night-body-content"}>
                <div className="version-force">
                    <OverlayTrigger placement="top" overlay={<Tooltip>
                        Các version bé hơn hoặc bằng phiên bản này bắt buộc phải update
                    </Tooltip>}>
                        <div className="title-cp">Version bắt buộc update <strong className="bow">*</strong> </div>
                    </OverlayTrigger>
                    <input type="text"
                        className="input-version"
                        value={getVersionByKey(FORCE_UPDATE)}
                        disabled={getVersionByKey(FORCE_UPDATE) === ""}
                        onChange={(e) => editVersionKey(e, FORCE_UPDATE)}
                    />
                    {
                        getVersionByKey(FORCE_UPDATE) !== getVersionCacheKey(FORCE_UPDATE) ?
                            <div>
                                <button className="btn btn-success"
                                    onClick={() => saveData(FORCE_UPDATE)}
                                >{<BiSave className="icon" />} </button>
                                <button className="btn btn-dark"
                                    onClick={() => revert(FORCE_UPDATE)}
                                >{<GiBackwardTime className="icon" />} </button>
                            </div>
                            : <></>
                    }

                </div>
                <div className="test-version">
                    <div className="title-cp">CP: [test]</div>
                    <div className="table">
                        <Table>
                            <thead>
                                <tr>
                                    <th>OS</th>
                                    <OverlayTrigger placement="top" overlay={<Tooltip >
                                        Version sau khi build ra ở client
                                    </Tooltip>}>
                                        <th style={{ width: '30%' }}>VERSION BUILD<b className="bow">*</b></th>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                        Version sẽ xuất hiện khi tải pack trên thiết bị
                                    </Tooltip>}>
                                        <th style={{ width: '30%' }}>VERSION SHOW<b className="bow">*</b></th>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip >
                                        Khi có bản build mới sẽ trỏ đến địa chỉ này để tải phiên bản mới
                                    </Tooltip>}>
                                        <th style={{ width: '30%' }}>URL<b className="bow">*</b></th>
                                    </OverlayTrigger>

                                </tr>
                            </thead>
                            <tbody>
                                {listOs && listOs.length > 0 &&
                                    listOs.map((item, index) => {
                                        let keyBuild = getKeyBuild(listCp.TEST, item.os);
                                        let keyShow = getKeyShow(listCp.TEST, item.os);
                                        let keyURL = getKeyURL(listCp.TEST, item.os);
                                        let versionBuild = getVersionByKey(keyBuild);
                                        let versionShow = getVersionByKey(keyShow);
                                        let versionURL = getVersionByKey(keyURL);
                                        return (<tr key={`test-version-${index}`}>
                                            <td className="un-select-aord">{item.os}</td>
                                            <td>
                                                <input
                                                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                                                    value={versionBuild}
                                                    disabled={versionBuild === ""}
                                                    onChange={(e) => editVersionKey(e, keyBuild)}
                                                />
                                                {versionBuild !== getVersionCacheKey(keyBuild) ?
                                                    <>
                                                        <button className="btn btn-success"
                                                            onClick={() => saveData(keyBuild)}
                                                        >{<BiSave className="icon" />} </button>
                                                        <button className="btn btn-dark"
                                                            onClick={() => revert(keyBuild)}
                                                        >{<GiBackwardTime className="icon" />} </button>
                                                    </> : <></>}
                                            </td>
                                            <td>
                                                <input
                                                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                                                    value={versionShow}
                                                    disabled={versionShow === ""}
                                                    onChange={(e) => editVersionKey(e, keyShow)}
                                                />
                                                {versionShow !== getVersionCacheKey(keyShow) ?
                                                    <>
                                                        <button className="btn btn-success"
                                                            onClick={() => saveData(keyShow)}
                                                        >{<BiSave className="icon" />} </button>
                                                        <button className="btn btn-dark"
                                                            onClick={() => revert(keyShow)}
                                                        >{<GiBackwardTime className="icon" />} </button>
                                                    </> : <></>}
                                            </td>
                                            <td>
                                                <input
                                                    className={+nightMode === 1 ? "input-url form-input-dark" : "input-url form-input-light"}
                                                    value={versionURL}
                                                    onChange={(e) => editVersionKey(e, keyURL)}
                                                    disabled={versionURL === ""}
                                                />
                                                {versionURL !== getVersionCacheKey(keyURL) ?
                                                    <>
                                                        <button className="btn btn-success"
                                                            onClick={() => saveData(keyURL)}
                                                        >{<BiSave className="icon" />} </button>
                                                        <button className="btn btn-dark"
                                                            onClick={() => revert(keyURL)}
                                                        >{<GiBackwardTime className="icon" />} </button>
                                                    </> : <></>}
                                            </td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="aord-version">
                    <div className="title-cp">CP: [aord]</div>
                    <div className="table">
                        <Table>
                            <thead>
                                <tr>
                                    <th>OS</th>
                                    <OverlayTrigger placement="top" overlay={<Tooltip >
                                        Version sau khi build ra ở client
                                    </Tooltip>}>
                                        <th style={{ width: '30%' }}>VERSION BUILD<b className="bow">*</b></th>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                        Version sẽ xuất hiện khi tải pack trên thiết bị
                                    </Tooltip>}>
                                        <th style={{ width: '30%' }}>VERSION SHOW<b className="bow">*</b></th>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip >
                                        Khi có bản build mới sẽ trỏ đến địa chỉ này để tải phiên bản mới
                                    </Tooltip>}>
                                        <th style={{ width: '30%' }}>URL<b className="bow">*</b></th>
                                    </OverlayTrigger>
                                </tr>
                            </thead>
                            <tbody>
                                {listOs && listOs.length > 0 &&
                                    listOs.map((item, index) => {
                                        let keyBuild = getKeyBuild(listCp.AORD, item.os);
                                        let keyShow = getKeyShow(listCp.AORD, item.os);
                                        let keyURL = getKeyURL(listCp.AORD, item.os);
                                        let versionBuild = getVersionByKey(keyBuild);
                                        let versionShow = getVersionByKey(keyShow);
                                        let versionURL = getVersionByKey(keyURL);
                                        return (<tr key={`aord-version-${index}`}>
                                            <td>{item.os}</td>
                                            <td>
                                                <input
                                                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                                                    value={versionBuild}
                                                    disabled={versionBuild === ""}
                                                    onChange={(e) => editVersionKey(e, keyBuild)}
                                                />
                                                {versionBuild !== getVersionCacheKey(keyBuild) ?
                                                    <>
                                                        <button className="btn btn-success"
                                                            onClick={() => saveData(keyBuild)}
                                                        >{<BiSave className="icon" />} </button>
                                                        <button className="btn btn-dark"
                                                            onClick={() => revert(keyBuild)}
                                                        >{<GiBackwardTime className="icon" />} </button>
                                                    </> : <></>}
                                            </td>
                                            <td>
                                                <input
                                                    className={+nightMode === 1 ? "form-input-dark" : "form-input-light"}
                                                    value={versionShow}
                                                    disabled={versionShow === ""}
                                                    onChange={(e) => editVersionKey(e, keyShow)}
                                                />
                                                {versionShow !== getVersionCacheKey(keyShow) ?
                                                    <>
                                                        <button className="btn btn-success"
                                                            onClick={() => saveData(keyShow)}
                                                        >{<BiSave className="icon" />} </button>
                                                        <button className="btn btn-dark"
                                                            onClick={() => revert(keyShow)}
                                                        >{<GiBackwardTime className="icon" />} </button>
                                                    </> : <></>}
                                            </td>
                                            <td>
                                                <input
                                                    className={+nightMode === 1 ? "input-url form-input-dark" : "input-url form-input-light"}
                                                    value={versionURL}
                                                    onChange={(e) => editVersionKey(e, keyURL)}
                                                    disabled={versionURL === ""}
                                                />
                                                {versionURL !== getVersionCacheKey(keyURL) ?
                                                    <>
                                                        <button className="btn btn-success"
                                                            onClick={() => saveData(keyURL)}
                                                        >{<BiSave className="icon" />} </button>
                                                        <button className="btn btn-dark"
                                                            onClick={() => revert(keyURL)}
                                                        >{<GiBackwardTime className="icon" />} </button>
                                                    </> : <></>}
                                            </td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div >)
}
export default Version;