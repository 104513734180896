import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import "./DBText.scss"
import axios from "axios";
import { useSelector } from "react-redux";

const DBText = () => {
    const [listDb, setListDb] = useState();
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getDbText();
    }, [])

    const getDbText = async () => {
        let ret = await axios.get('https://app.aordgame.com:7102/db-list');
        let data = ret.data;
        if (data && +data.ec === 0) {
            console.log(JSON.parse(data.dt))
            setListDb(JSON.parse(data.dt));
            return;
        }
        if (!data) toast.error(data.em);
    }

    const handleDownClick = (item) => {
        let alink = document.createElement('a');
        alink.href = item.link;
        alink.download = item.name;
        alink.click();
    }

    return (<div className="dbtext-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"} >Database Text</div>
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {listDb && listDb.length > 0 &&
                        listDb.map((item, index) => {
                            return (
                                <tr eventKey={`dbtext${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.name}</td>
                                    <td>
                                        <button
                                            onClick={() => { handleDownClick(item) }}
                                            className={+nightMode === 0 ? "btn btn-success" : "btn btn-info"}
                                        >
                                            Download
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}

                </tbody>
            </Table>
        </div>
    </div>)
}

export default DBText;