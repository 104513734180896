export const BonusType = (name) => {
  const item = aBonus.find(e => e.name === name);
  if (item) return item.id;
  return 0;
};

export const Bonus = (id) => {
  return aBonus.find(e => e.id === id);
}

export const GOLD = 1;
export const GEM = 2;
export const ITEM_EQUIP = 3;
export const USER_EXP = 4;
export const HERO = 5;
export const ITEM = 6;
export const WEAPON = 7;
export const AVATAR = 8;
export const VIP_EXP = 9;
export const PET = 10;
export const SKIN = 11;
export const ITEM_FARM = 12;
export const PIECE = 14;
export const RUBY = 15;


// img rank
export const GetImgBorder = (rank) => {
  return "/DB/border/" + rank + ".png";
}
export const GetImgBgr = (rank) => {
  return "/DB/bgr/" + rank + ".png";
}

export const aBonus = [
  { id: GOLD, name: "Gold", size: 2, src: "/DB/item/9.png", idItem: 9 },
  { id: GEM, name: "Gem", size: 2, src: "/DB/item/10.png", idItem: 10 },
  { id: ITEM_EQUIP, name: "ItemEquipment", size: 4, src: "/DB/item_equip/id.png", idItem: 0 },
  { id: USER_EXP, name: "UserExp", size: 2, src: "/DB/item/11.png", idItem: 11 },
  { id: HERO, name: "Hero", size: 2, src: "/DB/hero/id.png" },
  { id: ITEM, name: "Item", size: 3, src: "/DB/item/id.png" },
  { id: WEAPON, name: "Weapon", size: 2, src: "/DB/weapon/id.png" },
  { id: AVATAR, name: "Avatar", size: 4, src: "/DB/avatar/id.png" },
  { id: VIP_EXP, name: "VipExp", size: 2, img: true, src: "/DB/item/0.png", idItem: 0 },
  { id: PET, name: "Pet", size: 3, src: "/DB/pet/id.png" },
  { id: SKIN, name: "Skin", size: 3, src: "/DB/skin/id.png" },
  { id: ITEM_FARM, name: "ItemFarm", size: 4, src: "/DB/item_farm/type/id.png" },
  // { id: ITEM_POINT, name: "ItemPoint", size: 3, src: "/DB/item_point/id.png" },
  { id: PIECE, name: "Piece", size: 4, src: "/DB/piece/id.png" },
  { id: RUBY, name: "Ruby", size: 2, src: "/DB/item/88.png", idItem: 88 },
];