import "./ServerStatus.scss";
import Table from "react-bootstrap/Table";
import { useState, useReducer } from "react";
import ModalReset from "../../Config/Modal/ModalReset";
import { useSelector } from "react-redux";

const ServerStatus = () => {
  const [showModalReset, setShowModalReset] = useState(false);
  const [port, setPort] = useState(0);
  const nightMode = useSelector((state) => state?.night?.mode);

  const handlerBtnReset = (port) => {
    setPort(port);
    setShowModalReset(true);
  };

  return (
    <div className="server-container">
      <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Server Status</div>
      <div className="table">
        <Table bordered >
          <thead>
            <tr>
              <th>Server Name</th>
              <th>Port</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>SV Dev</td>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>6996</td>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>Good</td>
              <td>
                <button
                  onClick={() => handlerBtnReset(6996)}
                  className="btn btn-danger"
                >
                  Reset
                </button>
                {/* <button
                  onClick={() => toast.warning("Tính năng đang phát triển !")}
                  className="btn btn-warning btn-reload"
                >
                  Reload
                </button> */}
              </td>
            </tr>
            <tr>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>SV GD</td>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>6997</td>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>Good</td>
              <td>
                <button
                  onClick={() => handlerBtnReset(6997)}
                  className="btn btn-danger"
                >
                  Reset
                </button>
                {/* <button
                  onClick={() => toast.warning("Tính năng đang phát triển !")}
                  className="btn btn-warning btn-reload"
                >
                  Reload
                </button> */}
              </td>
            </tr>
            <tr>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>SV Backend</td>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>7002</td>
              <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>Good</td>
              <td>
                <button
                  onClick={() => handlerBtnReset(7002)}
                  className="btn btn-danger"
                >
                  Reset
                </button>
                {/* <button
                  onClick={() => toast.warning("Tính năng đang phát triển !")}
                  className="btn btn-warning btn-reload"
                >
                  Reload
                </button> */}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <ModalReset
        show={showModalReset}
        setShow={setShowModalReset}
        serverName={"Ozudo"}
        port={port}
      />
    </div>
  );
};

export default ServerStatus;
