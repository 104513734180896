import "./Config.scss"
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useState, useEffect } from "react";
import { getAllConfigDev, postSaveConfigDev } from "../../../services/apiCmsService"
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';
import { BiSearchAlt } from "react-icons/bi";
import _ from 'lodash';
import { IsJsonString } from "../../../utils/Validate"
import { coppyText, openUrl, ToastNoSuccess } from "../../../utils/Utils";
import { useSelector } from "react-redux";


const ConfigDev = () => {
    const [search, setSearch] = useState("");
    const [config, setConfig] = useState([]);
    const [disabledEdit, setDisableEdit] = useState(true);
    const [showSave, setShowSave] = useState(false);
    const [curIndex, setCurIndex] = useState(0);
    const [cacheConfig, setCacheConfig] = useState([]);
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        requestConfig();
    }, []);

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            handleFindClick();
        }
    }

    const handleFindClick = async () => {
        if (search === "") {
            setConfig(cacheConfig);
            return
        }
        setCurIndex(0);
    }

    const handleEditConfig = (event, index) => {
        let oldData = config[index].v;
        if (event.target.value !== oldData) {
            setShowSave(true)
        }
        let cfgClone = _.cloneDeep(config);
        cfgClone[index].v = event.target.value;
        setConfig(cfgClone);
    }

    const handleBtnSaveConfig = async () => {
        let v = config[curIndex].v;
        let checkJson = IsJsonString(v);
        if (!checkJson) {
            toast.error("JSON không hợp lệ!")
            return;
        }
        let data = await postSaveConfigDev(config[curIndex].k, config[curIndex].v);
        if (data && data.EC === 0) {
            toast.success(data.EM);
            return requestConfig();
        }
        if (data && data.EC !== 0) {
            return toast.error(data.EM);
        }
        requestConfig();
    }

    const handleViewClick = async (data) => {
        await coppyText(data);
        openUrl("https://jsoneditoronline.org/#");
    }

    const handleSelectSave = async (index) => {
        setCurIndex(index);
        await coppyText(cacheConfig[index].k);
    }

    const handleBtnReset = () => {
        setDisableEdit(true);
        setShowSave(false);
        setConfig(cacheConfig);
    }

    const requestConfig = async () => {
        let data = await getAllConfigDev();
        if (data && data.EC === 0) {
            setCacheConfig(data.DT);
            setConfig(data.DT);
            setDisableEdit(true);
            setShowSave(false);
        }
        ToastNoSuccess(data);
    }

    return (<div className="config-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Config Server Test</div>
        <div className={+nightMode === 0 ? "input-search" : "input-search input-search-night"}>
            <Form.Control
                className="input-key"
                type="text"
                value={search}
                autoFocus
                onChange={(event) => {
                    setSearch(event.target.value);
                }}
                onKeyDown={(event) => handleKeyDown(event)}
            />
            <BiSearchAlt className="search-icon" onClick={() => handleFindClick()} />
        </div>

        <div className={+nightMode === 0 ? "body-content" : "body-content-night"}>
            <Tab.Container defaultActiveKey={`config-dev-index-${curIndex}`}
                onSelect={() => {
                    setDisableEdit(true);
                    setShowSave(false);
                    setConfig(cacheConfig);
                }}
            >

                <div className="left-content">
                    <Nav className="flex-column">
                        {config && config.length > 0 && config.filter((item) => {
                            return search.toLowerCase() === "" ? item : item.k.toString().toLowerCase().includes(search);
                        }).map((cfg, index) => {
                            return (
                                <Nav.Item>
                                    <Nav.Link eventKey={`config-dev-index-${index}`} onClick={() => handleSelectSave(index)}>{cfg.k}</Nav.Link>
                                </Nav.Item>)
                        })
                        }
                    </Nav>
                </div>
                <div className="right-content">
                    <Tab.Content>
                        {config && config.length > 0 && config.filter((item) => {
                            return search.toLowerCase() === "" ? item : item.k.toString().toLowerCase().includes(search);
                        }).map((cfg, index) => {
                            return (
                                <Tab.Pane eventKey={`config-dev-index-${index}`}>
                                    <Form.Control
                                        className="input-config"
                                        as="textarea"
                                        rows={18}
                                        value={cfg.v}
                                        onChange={(event) => handleEditConfig(event, index)}
                                        disabled={disabledEdit}
                                    />
                                    {disabledEdit ? <div className="disable-edit">
                                    </div> : <></>}
                                </Tab.Pane>
                            )
                        })
                        }

                    </Tab.Content>
                </div>

            </Tab.Container>
        </div>
        <div className="bot-content">
            <button className="btn btn-warning btn-action" onClick={() => setDisableEdit(false)}>Edit</button>
            <button className="btn btn-info btn-action" onClick={() => handleViewClick(config[curIndex].v)}>View</button>
            {showSave &&
                <button className="btn btn-success btn-action" onClick={handleBtnSaveConfig}>Save</button>
            }
            {showSave &&
                <button className="btn btn-primary btn-action" onClick={handleBtnReset}>Reset</button>
            }
        </div>
    </div>)
}

export default ConfigDev;