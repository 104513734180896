import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { postCreateConfigHelp } from '../../../services/apiCmsService';
import { ToastResponse } from '../../../utils/Utils';


const ModalCreateConfigHelp = (props) => {
    const { show, setShow } = props;
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");

    const handleClose = () => {
        setKey("");
        setValue("");
        setShow(false);
    }

    const handleCreateConfig = async () => {
        let data = await postCreateConfigHelp(key, value);
        ToastResponse(data);
        if (data && data.EC === 0) {
            await props.requestConfig();
        }
        handleClose();
    }

    return (<>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            className="modal-create-config-help"
        >
            <Modal.Header>
                <Modal.Title>Thêm config help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="ControlInput">
                        <OverlayTrigger
                            placement={"right"}
                            overlay={
                                <Tooltip id={`tooltip-${"right"}`}>
                                    <p>Key help nên bắt đầu bằng HELP và viết in.</p>
                                    <p>exp: HELP_DEMO</p>
                                </Tooltip>
                            }
                        >
                            <Form.Label >Key help *</Form.Label>
                        </OverlayTrigger>
                        <Form.Control
                            type="text"
                            value={key}
                            autoFocus
                            onChange={(event) => setKey(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label>Nội dung</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={handleCreateConfig}>
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModalCreateConfigHelp;