import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { getResWeaponName, getUserWeapon } from "../../../../services/userService";
import "./UserWeapon.scss";
import _ from "lodash";
import { WeaponName } from "../../../../constant";
import { useSelector } from "react-redux";


const UserWeapon = () => {
    const [weapons, setWeapon] = useState([]);
    const userId = localStorage.getItem('UserIdInfo');
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getInfo()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getInfo = async () => {
        // check đã có name chưa, chưa thì gọi lên lấy
        if (_.isEmpty(WeaponName)) {
            console.log("Chay vao day khong");
            let weapons = await getResWeaponName();
            if (weapons && +weapons.EC === 0) {
                for (var i = 0; i < weapons.DT.length; i++) {
                    WeaponName[weapons.DT[i].id] = weapons.DT[i].name;
                }
            }
        }
        let data = await getUserWeapon(userId);
        if (data && +data.EC === 0) {
            setWeapon(data.DT);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");

    }
    return (<div className="user-weapon-container">
        <div className="table">
            <Table bordered>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>ID</th>
                        <th>Tên</th>
                        <th>Cấp độ</th>
                        <th>Ngày nhận</th>
                    </tr>
                </thead>
                <tbody>
                    {weapons && weapons.length > 0 &&
                        weapons.map((item, index) => {
                            return (
                                <tr key={`weapon-${index}`}>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}><img width={60} height={60} src={`/DB/weapon/${item.weapon_id}.png`} alt={item.weapon_id}></img></td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.weapon_id}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{WeaponName[item.weapon_id]}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.level}</td>
                                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(item.date_created).toLocaleDateString()}</td>
                                </tr>)
                        })}
                </tbody>
            </Table>
            {(!weapons || weapons.length === 0) &&
                <div className="no-data">Không có dữ liệu</div>
            }
        </div>
    </div >)
}
export default UserWeapon;