import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { HeroName } from "../../../../constant";
import { getHeroName, getUserHero } from "../../../../services/userService";
import "./UserHero.scss";
import { useSelector } from "react-redux";

const UserHero = () => {
    const [heroes, setHeroes] = useState({});
    let userId = localStorage.getItem('UserIdInfo');
    const nightMode = useSelector((state) => state?.night?.mode);

    useEffect(() => {
        getInfo();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getInfo = async () => {
        if (_.isEmpty(HeroName)) {
            let hero = await getHeroName();
            if (hero && hero.EC === 0) {
                for (var i = 0; i < hero.DT.length; i++) {
                    HeroName[hero.DT[i].hero_id] = hero.DT[i].name;
                }
            }
        }
        let data = await getUserHero(userId);
        if (data && data.DT && +data.EC === 0) {
            setHeroes(data.DT);
            return;
        }
        if (!data) toast.error("Không nhận được dữ liệu!");
    }
    return (<div className="user-hero-container">
        <div className="table">
            <Table bordered >
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>ID</th>
                        <th>Tên</th>
                        <th>Skin ID</th>
                        <th>Ngày nhận</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        heroes && heroes.length > 0 &&
                        heroes.map((hero, index) => {
                            return (<tr key={`hero-${index}`}>
                                <td><img width={60} height={60} src={`/DB/hero/${hero.hero_id}.png`} alt={hero.hero_id}></img></td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{hero.hero_id}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{HeroName[hero.hero_id]}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{hero.skin}</td>
                                <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(hero.date_created).toLocaleString()}</td>
                            </tr>)
                        })
                    }
                </tbody>
            </Table>
            {(!heroes || heroes.length === 0) &&
                <div className="no-data">Không có dữ liệu</div>
            }
        </div>


    </div >)
}
export default UserHero;