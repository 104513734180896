import { useEffect, useState } from "react";
import "./Stats.scss";
import { ToastNoSuccess } from "../../../utils/Utils";
import { Bar, BarChart, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { getCCU, getCCUMax, getCCUSV, getCCUSvDay } from "../../../services/apiCmsService";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { formatDate } from "../../../utils/DateUtil";
import { C_TOTAL } from "../../../constant";
import { useSelector } from "react-redux";


const CCU = (props) => {
    const nightMode = useSelector((state) => state?.night?.mode);
    const [CCU, setCCU] = useState([]);
    const [curCCU, setCurCCU] = useState(0);
    const [ccuDay, setCCUDay] = useState([]);
    const [df, setDf] = useState();
    const [dt, setDt] = useState();
    const [ccuSV, setCcuSV] = useState();
    const [dfSV, setDfSV] = useState();
    const [dtSV, setDtSV] = useState();
    const [sv, setSV] = useState(-1);
    const [svDay, setSVDay] = useState(-1);
    const [ccu4, setCcu4] = useState([]);

    useEffect(() => {
        const getFistInfo = async () => {
            let date = new Date();
            date.setDate(new Date().getDate() - 7);
            setDf(formatDate(date));
            let df = formatDate(date);
            let dt = formatDate(new Date());
            setDt(dt);
            setDfSV(formatDate(date));
            setDtSV(dt);

            getCurCCU();
            getMaxCCU(df, dt)
            getCcuSVMaxDay(df, dt);
            getCcuSVDay();
        };
        getFistInfo()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    const getCurCCU = async () => {
        let lstCCU = await getCCU();
        if (lstCCU && lstCCU.EC === 0) {
            setCCU(lstCCU.DT.data);
            setCurCCU(lstCCU.DT.max);
        }
        ToastNoSuccess(lstCCU);
    }

    const getMaxCCU = async (df, dt) => {
        let lstCCU = await getCCUMax(df, dt);
        if (lstCCU && lstCCU.EC === 0) {
            setCCUDay(lstCCU.DT);
        }
        ToastNoSuccess(lstCCU);
    }

    const getCcuSVMaxDay = async (dfSV, dtSV) => {
        let ccu = await getCCUSV(sv, dfSV, dtSV);
        if (ccu && ccu.EC === 0) {
            setCcuSV(ccu.DT);
        }
        ToastNoSuccess(ccu);
    }

    const getCcuSVDay = async () => {
        let data = await getCCUSvDay(svDay);
        if (data && data.EC === 0) {
            setCcu4(data.DT);
        }
        ToastNoSuccess(data);
    }

    const handleKeyDown = (event) => {
        if (event && event.key === "Enter") {
            getCcuSVMaxDay(dfSV, dtSV);
        }
    };
    const handleKeyDown4 = (event) => {
        if (event && event.key === "Enter") {
            getCcuSVDay();
        }
    };

    return (<div className="stats-container">
        <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>CCU</div>
        <Container>
            <Row>
                <Col>
                    <div className="input-content">
                        {` CCU ${curCCU}`}
                    </div>
                    <div>
                        {(CCU && CCU.length > 0) ? <div>
                            <BarChart width={500} height={300} data={CCU} barSize={20}>
                                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#8884d8" background={{ fill: '#eee' }} />
                            </BarChart>
                        </div> : <div className="no-data">Không có dữ liệu</div>}
                    </div>
                </Col>
                <Col>
                    <div className="input-content">
                        <Form.Control
                            className="input-date"
                            type="date"
                            value={df ? df : ""}
                            onChange={(event) => setDf(event.target.value)}
                        />
                        <Form.Control
                            className="input-date"
                            type="date"
                            value={dt ? dt : ""}
                            onChange={(event) => setDt(event.target.value)}
                        />
                        <button className="btn btn-success" onClick={() => getCcuSVMaxDay(df, dt)} >Kiểm tra</button>
                    </div>
                    <div>
                        {(ccuDay && ccuDay.length > 0) ? <div>
                            <BarChart width={500} height={300} data={ccuDay} barSize={20}>
                                <XAxis dataKey="date" scale="point" padding={{ left: 10, right: 10 }} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#8884d8" background={{ fill: '#eee' }} />
                            </BarChart>
                        </div> : <div className="no-data">Không có dữ liệu</div>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="input-content">
                        <FloatingLabel controlId="floatingBonus" label="Server" className="select-content">
                            <Form.Control
                                type="number"
                                min={-3}
                                value={sv}
                                onChange={(event) => setSV(+event.target.value)}
                                onKeyDown={(event) => handleKeyDown(event)}
                            />
                        </FloatingLabel>
                        <Form.Control
                            className="input-date"
                            type="date"
                            value={dfSV ? dfSV : ""}
                            onChange={(event) => setDf(event.target.value)}
                        />
                        <Form.Control
                            className="input-date"
                            type="date"
                            value={dtSV ? dtSV : ""}
                            onChange={(event) => setDt(event.target.value)}
                        />
                        <button className="btn btn-success" onClick={() => getMaxCCU(dfSV, dtSV)} >Kiểm tra</button>
                    </div>
                    {(ccuSV && ccuSV.length > 0) ? <div>
                        <BarChart width={500} height={300} data={ccuSV} barSize={20}>
                            <XAxis dataKey="date_created" scale="point" padding={{ left: 10, right: 10 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="number" fill="#8884d8" background={{ fill: '#eee' }} />
                        </BarChart>
                    </div> : <div className="no-data">Không có dữ liệu</div>}
                </Col>

                <Col>
                    <div className="input-content">
                        <FloatingLabel controlId="floatingBonus" label="Server" className="select-content">
                            <Form.Control
                                type="number"
                                min={-3}
                                value={svDay}
                                onChange={(event) => setSVDay(+event.target.value)}
                                onKeyDown={(event) => handleKeyDown4(event)}
                            />
                        </FloatingLabel>
                        <button className="btn btn-success" onClick={getCcuSVDay} >Kiểm tra</button>
                    </div>

                    {(ccu4 && ccu4.length > 0) ? <div>
                        <LineChart width={500} height={300} data={ccu4}>
                            <XAxis dataKey="time" padding={{ left: 15, right: 15 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="ONLINE" stroke={C_TOTAL} strokeWidth={2} />
                        </LineChart>
                    </div> : <div className="no-data">Không có dữ liệu</div>}
                </Col>
            </Row>
        </Container>
    </div >)
}

export default CCU;