import axios from "axios";
import NProgress from "nprogress";
import { USER } from "../constant";
import { toast } from "react-toastify";
import { Toast } from "react-bootstrap";
import { useCallback } from "react";
// import { store } from "../redux/store";
// import axiosRetry from "axios-retry";

NProgress.configure({
  showSpinner: false,
  // easing: 'ease',
  // speed: 500,
  // trickleRate: 0.5,
  // speed: 200,
  // trickle: true,
  // trickleRate: 0.02,
  trickleSpeed: 30,
});

const instance = axios.create({
  baseURL: "https://backend.aordgame.com/",
  // withCredentials: true,
  timeout: 100000,//100s
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    NProgress.start();
    // let access_token = store?.getState()?.user?.access_token;

    const user = JSON.parse(localStorage.getItem(USER));
    let access_token = user?.access_token;
    config.headers["authorization"] = "Bearer " + access_token;
    // Do something before request is sent
    return config;
  },
  function (error) {
    NProgress.done();
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    NProgress.done();

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response && response.data ? response.data : response;
  },
  async function (error) {
    NProgress.done();
    // token expired: EC=== -999

    if (error.response?.data && error.response?.data?.EC === -999) {
      window.location.href = "/login";
      alert(error.response?.data?.EM);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return error && error.response && error.response.data
      ? error.response.data
      : Promise.reject(error);
  }
);
export default instance;
