import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { BiSearchAlt } from "react-icons/bi";
import "./Clan.scss";
import { getClanList, getFindClan } from "../../../../services/apiCmsService"
import Form from 'react-bootstrap/Form';
import { ToastNoSuccess, formatNumberDot } from "../../../../utils/Utils";
import { useSelector } from "react-redux";

const Clan = () => {
  const [key, setKey] = useState("");
  const [clans, setClan] = useState([]);
  const [cache, setCache] = useState([]);
  const nightMode = useSelector((state) => state?.night?.mode);

  useEffect(() => {
    getListClan();
  }, [])

  const getListClan = async () => {
    let data = await getClanList();
    ToastNoSuccess(data);
    if (data && +data.EC === 0) {
      setClan(data.DT);
      setCache(data.DT);
    }
  }

  const handleFindClick = async () => {
    if (key === "") {
      setClan(cache);
      return
    }
    let data = await getFindClan(key);
    ToastNoSuccess(data);
    if (data && +data.EC === 0) {
      setClan(data.DT);
    } else setClan([]);
  }

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      handleFindClick();
    }
  }

  return (
    <div className="find-clan-container" >
      <div className={+nightMode === 0 ? "day-title-mode" : "night-title-mode"}>Find Clan</div>
      <div className={+nightMode === 0 ? "input-search" : "input-search input-search-night"}>
        <Form.Control
          className="input-key"
          type="text"
          value={key}
          autoFocus
          onChange={(event) => {
            setKey(event.target.value);
          }}
          onKeyDown={(event) => handleKeyDown(event)}
        />
        <BiSearchAlt className="search-icon" onClick={() => handleFindClick()} />
      </div>
      <div className="table">
        <Table bordered >
          <thead>
            <tr>
              <th>Id</th>
              <th>Server</th>
              <th>Name</th>
              <th>Member</th>
              <th>Master Id</th>
              <th>Master Name</th>
              <th>Level</th>
              <th>Exp</th>
              <th>Star</th>
              <th>Join Rule</th>
              <th>Rank</th>
              <th>Power</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {clans && clans.length > 0 &&
              clans.map((item, index) => {
                return (
                  <tr tr key={`table-find-clan-${index}`}>
                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}><b>{item.id}</b></td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{+item.server < 1 ? "Test " + Math.abs(+item.server) : item.server}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.name}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.member}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.master_id}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord" : "text-light-aord"}>{item.master}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.level}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.exp}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.star}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.join_rule}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{item.rank}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{formatNumberDot(item.power)}</td>
                    <td className={+nightMode === 1 ? "text-dark-aord un-select-aord" : "text-light-aord un-select-aord"}>{new Date(item.date_created).toLocaleDateString()}</td>
                  </tr>
                );
              })}
            {clans && clans.length === 0 && (
              <tr>
                <td colSpan={"13"}><div className="title-nodata">Không có dữ liệu</div></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Clan;
